import axios, { AxiosResponse } from 'axios' ;
import qs from 'qs' ;
// import { getToken } from "@scripts/tools" ;

/** 初始化Axios拦截器 */
export const CreateAxios = () => {
    // axios.interceptors.request.use ( async (config) => {
    //     // const token = await getToken () ;
    //     // console.log(token);
    //     // config.headers.common['user_token'] = token ;
    //     if ( config.method === `post` && !config.headers.hasOwnProperty ( `Content-Type` ) ) {
    //         config.headers[`Content-Type`] = `application/x-www-form-urlencoded;charset=UTF-8` ;
    //         config.data = config.data ? qs.stringify ( config.data ) : config.data ;
    //     }
    //     return config ;
    // }, error => {
    //     console.log ( error ) ;
    // } ) ;
    // axios.interceptors.response.use ( config => {
    //     return config ;
    // }, error => {
    //     console.log ( error ) ;
    // } ) ;
} ;
/**
 *
 * @param options
 * @param config
 * @returns {Promise<unknown>}
 * @constructor
 */
export const $axios = async ( options, config = { isEncryption: false, isDecrypt: false } ):Promise<AxiosResponse> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise ( async ( resolve, reject ) => {
        try {
            // 判断header 中的Content-Type 值是否含有 application/x-www-form-urlencoded
            // if ( !options.hasOwnProperty('Content-Type') ){
            //     options.params.user_token = Cookie.get('jhk-personal') || ''
            // }

            // 请求参数是否需要加密
            if ( config.isEncryption ) {
                // 加密
            }

            const axiosRes = await axios ( options ) ;

            // 响应值是否需要解密
            if ( config.isDecrypt && axiosRes.status === 200 ) {
                // 对axiosRes.data 解密
            }

            resolve ( axiosRes ) ;
        } catch ( e ) {
            reject ( e ) ;
        }
    } ) ;
} ;
