import { $axios } from "@scripts/Axios" ;
import { ServerBoardServer, ServerDesigner, ServerPService, ServerBoardServer2, ServerBoardServerI } from "@constants/servers" ;
import { getToken } from "@scripts/./tools" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import { ResultItem } from "@scripts/resultItem" ;
import { getCopyrightInfo, getLargePage, SearchProxy } from '@scripts/Proxy' ;
import { convertParamsToFormData, getBase64Image, toRelativeProtocol } from "@scripts/tools" ;
import axios from "axios" ;
import qs from 'qs' ;
// const MessageBox = require ( `element-ui/lib/message-box` ).default ;

export class BoardManager {
  /**  图片集查询是否走推荐接口 */
  public isRecommendBoard: Boolean = false;
  /** 我的所有图片集 */
  public boardList: Array<any> = [];
  /** 图片集分类 */
  public boardClassify: Array<any> = [];
  /** 每页显示长度 */
  public rows: Number = 22;
  /** 距离底部多少距离开始加载 */
  public loadMoreDist: number = 80;
  /** 展示的页码 */
  public page = 1;
  /** 是否正在请求中 */
  public requesting: boolean = false;
  /** 搜索结果已经为空了 */
  public noResult: boolean = false;
  /** 切换为协作接口 */
  public switchCollaborate = true;
  /**
   * @description 分页获取首页推荐图片集接口，页数+1
  */
  loadMoreRecommendBoards ( h, p, options ) {
      if ( !options ) options = {} ;
      if ( p ) {
          if ( p === 1 ) {
              // 初始化
              this.boardList = [] ;
              this.page = 1 ;
              this.requesting = false ;
              this.noResult = false ;
          }
      }
      return new Promise ( ( resolve, reject ) => {
          if ( this.noResult || this.requesting ) return resolve ( this.boardList ) ;
          this.getRecommendBoardsList ( h, p, options ).then ( function ( data ) {
              return resolve ( data ) ;
          }, ( e ) => {
              return reject ( e ) ;
          } ) ;
      } ) ;
  }

  loadMoreBoards ( uid, isPublic, reset ) {
      if ( reset ) {
          if ( reset == 1 ) {
              // 初始化
              this.boardList = [] ;
              this.page = 1 ;
              this.requesting = false ;
              this.noResult = false ;
          }
      }
      return new Promise ( ( resolve, reject ) => {
          if ( this.noResult || this.requesting ) return resolve ( this.boardList ) ;
          this.getBoardsList ( uid, isPublic ).then ( function ( data ) {
              return resolve ( data ) ;
          }, ( e ) => {
              return reject ( e ) ;
          } ) ;
      } ) ;
  }

  /**
   * @description 获取首页推荐图片集接口
   * @param userId 用户id
   * @param h 行业 （可以不传参）
   * @param isFresh 是否刷新
   */
  async getRecommendBoardsList ( userId:number, h?:String, isFresh:boolean = false ) {
      const _self = this ;
      const token = await getToken () ;
      return new Promise ( ( resolve, reject ) => {
          $axios ( {
              url: _self.isRecommendBoard ? ServerBoardServer + `/board/r` : ServerBoardServer + `/board/r2`,
              method: `GET`,
              params: {
                  is_fresh: _self.isRecommendBoard ? `` : isFresh,
                  h: h,
                  p: _self.page,
                  r: _self.rows,
                  u: userId,
                  token: token
              }
          } ).then ( res => {
              if ( res.status === 200 ) {
                  const data = res.data ;
                  _self.requesting = false ; // 请求结束
                  if ( data.status === 200 ) {
                      if ( data.data.length === 0 && _self.page == 1 ) {
                          _self.noResult = true ;
                          console.log ( `没有图片集` ) ;
                          _self.boardList = [] ;
                          return resolve ( _self.boardList ) ;
                      } else if ( data.data.length === 0 && _self.page != 1 ) {
                          _self.noResult = true ;
                          console.log ( `没有更多图片集` ) ;
                          return resolve ( _self.boardList ) ;
                      } else {
                          _self.page += 1 ;
                          _self.boardList = [..._self.boardList, ...data.data] ;
                          return resolve ( _self.boardList ) ;
                      }
                  } else {
                      _hmt.push ( [`_trackEvent`, `r2报500`, `h=${h}/p=${_self.page}/r=${_self.rows}/u=${userId}`] ) ;
                      reject ( new Error ( `Error: ` + data.code ) ) ;
                  }
              } else {
                  reject ( new Error ( JSON.stringify ( res ) ) ) ;
              }
          } ).catch ( e => {
              reject ( e ) ;
          } ) ;
      } ) ;
  }

  /**
   * @description 获取推荐图片集接口
   * @param uid 他人id
   * @param isPublic -1 查找所有的图片集 0 查找私有的图片集 1 查找公开的图片集
   */
  async getBoardsList ( uid, isPublic ) {
      const _self = this ;
      const token = await getToken () ;
      _self.requesting = true ;
      return new Promise ( ( resolve, reject ) => {
          $axios ( {
              url: ServerBoardServer + ( _self.switchCollaborate ? `/userFollowBoard/listBoardNewMini` : `/userFollowBoard/listBoardNew` ),
              method: `GET`,
              params: {
                  user_token: token,
                  uid: uid,
                  is_public: isPublic,
                  page: _self.page,
                  rows: _self.rows
              }
          } ).then ( res => {
              if ( res.status == 200 ) {
                  const data = res.data ;
                  _self.requesting = false ; // 请求结束
                  if ( data.code === 200 ) {
                      if ( data.list.length === 0 && _self.page == 1 ) {
                          _self.noResult = true ;
                          console.log ( `没有图片集` ) ;
                          _self.boardList = [] ;
                          return resolve ( _self.boardList ) ;
                      } else if ( data.list.length === 0 && _self.page != 1 ) {
                          _self.noResult = true ;
                          console.log ( `没有更多图片集` ) ;
                          return resolve ( _self.boardList ) ;
                      } else {
                          _self.page += 1 ;
                          _self.boardList = [..._self.boardList, ...data.list] ;
                          return resolve ( _self.boardList ) ;
                      }
                  } else {
                      return reject ( new Error ( data.code + data.meg ) ) ;
                  }
              } else {
                  reject ( new Error ( JSON.stringify ( res ) ) ) ;
              }
          } ).catch ( e => {
              reject ( e ) ;
          } ) ;
      } ) ;
  }

  /**
     * @description 获取我的所有图片集接口 (旧的 以后可删)
     * @param uid 他人id
     * @param isPublic -1 查找所有的图片集 0 查找私有的图片集 1 查找公开的图片集
     */
  async getAllMyBoards ( uid, isPublic ) {
      const token = await getToken () ;
      return $axios ( {
          url: ServerBoardServer + ( this.switchCollaborate ? `/userFollowBoard/listBoardNewMini` : `/userFollowBoard/listBoardNew` ),
          params: {
              user_token: token,
              uid: uid,
              is_public: isPublic
          }
      } ).then ( res => {
          if ( res.status === 200 ) {
              this.boardList = res.data ;
              return res.data.list ;
          } else {
              throw new Error ( JSON.stringify ( res ) ) ;
          }
      } ).catch ( e => {
          throw e ;
      } ) ;
  }
}

/** 新获取图片集分类 */
export const BoardGetNewBoardsClassify = async () => {
    const token = getToken () ;
    const _self = this ;
    return $axios ( {
        url: ServerPService + `/industry/industrylist.htm`,
        method: `POST`,
        data: {
            user_token: token
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            const data = res.data ;
            console.log ( data ) ;
            // @ts-ignore
            _self.boardClassify = data.industrylist ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;
/**
 * @description 创建图片集
 * @param title
 * @param categyIds
 * @param isPublic
 * @param info
 * @param enterpriseId
 */
export const BoardNewCreateBoards = async ( title, categyIds, isPublic, info, enterpriseId?:0 ) => {
    const token = await getToken () ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer + `/board/createBoardNew`,
        params: {
            user_token: token,
            title: title,
            categyIds: categyIds,
            isPublic: isPublic,
            info: info,
            enterprise_id: enterpriseId
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            return res.data ;
        } else {
            throw new Error ( res.data.msg || `创建图片集失败` ) ;
        }
    } ) ;
} ;

/** 编辑图片集 */
// export const BoardNewEditMyBoard = async ( title, boardId, categyIds, info, isPublic ) => {
export const BoardNewEditMyBoard = async ( options ) => {
    const token = await getToken () ;
    const data = new FormData () ;
    data.append ( `user_token`, token ) ;
    data.append ( `title`, options.title ) ;
    data.append ( `categyIds`, options.categyIds ) ;
    data.append ( `board_id`, options.boardId || `` ) ;
    data.append ( `info`, options.info ) ;
    data.append ( `is_public`, options.isPublic ) ;
    data.append ( `uuid`, options.uuid ) ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer + `/board/remarkBoardNew`,
        data
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            throw new Error ( res.data.msg ) ;
        }
    } ) ;
} ;

/** 删除图片集 */
export const BoardNewDeleteBoard = async ( options ) => {
    const token = await getToken () ;
    const data = new FormData () ;
    data.append ( `user_token`, token ) ;
    data.append ( `board_id`, options.boardId || `` ) ;
    data.append ( `uuid`, options.uuid || `` ) ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer + `/board/deleteBoardNew`,
        data
    } ).then ( res => {
        if ( res.status === 200 ) {
            return Promise.resolve ( true ) ;
        } else {
            throw new Error ( res.data.msg ) ;
        }
    } ) ;
} ;
/** 根据图片集id获取board详情 */
export const NewBoardInfo = async ( ops: {boardId: number, uuid: string} ) => {
    const token = await getToken () ;
    return $axios ( {
        url: ServerBoardServer + `/boardNew/info`,
        params: {
            user_token: token,
            board_id: ops.boardId,
            uuid: ops.uuid
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            return Promise.resolve ( res.data ) ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;

/** 根据图片集id获取图片集详情 */
export const BoardNewQueryBoard = async ( uid, boardId, page:number = 1, rows = 22, minID:string = `` ) => {
    const token = await getToken () ;
    return $axios ( {
        url: ServerBoardServer + `/img/listBoardImgNew`,
        params: {
            user_token: token,
            uid: uid,
            board_id: boardId,
            page,
            rows,
            min_id: minID
        }
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return Promise.resolve ( res.data ) ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;

/** 最新根据图片集id获取图片集详情 */
export const BoardNewImgList = async ( ops:{uid: number, boardId?:number, page: number, minID: string, uuid?: string} ) => {
    // uid, boardId, page:number = 1, minID:string = ``
    const token = await getToken () ;
    return $axios ( {
        url: ServerBoardServer + `/boardNew/imgList`,
        params: {
            user_token: token,
            uid: ops.uid,
            uuid: ops.uuid,
            board_id: ops.boardId,
            page: ops.page,
            min_id: ops.minID
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            return Promise.resolve ( res.data ) ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;

/** 为图片集添加分类 */
export const BoardForBoardAddClassify = async ( boardId, industryId ) => {
    const token = await getToken () ;
    return $axios ( {
        url: ServerBoardServer + `/board/addCategory`,
        params: {
            user_token: token,
            boardId: boardId,
            industryId: industryId
        }
    } ).then ( res => {
        if ( res.status === 200 && res.data.statusCode === 200 ) {
            return Promise.resolve ( true ) ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;

/** 整合多种方法，上传收藏 */
export const BoardUploadToFav = async ( boardid, type, content, lableIds ) => {
    const token = await getToken () ;
    if ( type === `formdata` ) {
        const formData = new FormData () ;
        formData.append ( `file`, content ) ;
        formData.append ( `user_token`, token ) ;
        formData.append ( `lableIds`, lableIds ) ;
        formData.append ( `boardId`, boardid ) ;
        return $axios ( {
            method: `POST`,
            url: ServerBoardServer + `/collectuserlook/uploadImg2Board`,
            data: formData,
            headers: {
                'Cache-Control': `no-cache`
            }
        } ).then ( res => {
            if ( res.status === 200 && res.data.state === 200 ) {
                return res.data ;
            } else {
                throw new Error ( JSON.stringify ( res ) ) ;
            }
        } ) ;
    } else if ( type === `ossurl` ) {
        return $axios ( {
            method: `POST`,
            url: ServerBoardServer + `/collectuserlook/uploadImg2Board`,
            data: {
                url: content.oss_url,
                signature: content.signature,
                user_token: token,
                lableIds: lableIds,
                boardId: boardid
            }
        } ).then ( res => {
            if ( res.status === 200 && res.data.state === 200 ) {
                return res.data ;
            } else {
                throw new Error ( JSON.stringify ( res ) ) ;
            }
        } ) ;
    } else if ( type === `urlorbase64` ) {
        return $axios ( {
            method: `POST`,
            url: ServerBoardServer + `/collectuserlook/uploadImg2Board`,
            data: {
                user_token: token,
                lableIds: lableIds,
                boardId: boardid,
                url: content
            }
        } ).then ( res => {
            if ( res.status === 200 && res.data.state === 200 ) {
                return res.data ;
            } else {
                throw new Error ( JSON.stringify ( res ) ) ;
            }
        } ) ;
    }
} ;

/**
 * @description 上传图片 以图搜图
 * @param type 图片格式
 * @param file formData图片，base64 url 都可以
 */
export const BoardUploadFile = async ( type, file ) => {
    console.log ( `走接口` ) ;
    console.log ( file ) ;
    const token = await getToken () ;
    return new Promise ( ( resolve, reject ) => {
        if ( type === `formdata` ) {
            const formData = new FormData () ;
            formData.append ( `file`, file ) ;
            formData.append ( `user_token`, token ) ;
            return $axios ( {
                method: `POST`,
                url: ServerDesigner + `/img/uploadFile`,
                data: formData,
                headers: {
                    'Cache-Control': `no-cache`
                }
            } ).then ( res => {
                if ( res.status === 200 ) {
                    resolve ( res.data ) ;
                } else {
                    reject ( new Error ( JSON.stringify ( res ) ) ) ;
                }
            } ) ;
        } else {
            reject ( new TypeError ( `type只允许传入\`formdata\`` ) ) ;
        }
    } ) ;
} ;
/** 给FormData图片鉴黄鉴暴 */
const _greenImageScanFormdata = async ( file ) => {
    const token = await getToken () ;
    const formData = new FormData () ;
    formData.append ( `file`, file ) ;
    formData.append ( `user_token`, token ) ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServerI + `/imageReview/identifyPornAndTerror`,
        data: formData,
        headers: {
            'Cache-Control': `no-cache`
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            return res.data ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;
/** 给base64图片鉴黄鉴暴 */
const _greenImageScanBase64 = async ( file ) => {
    const token = await getToken () ;
    const imgformData = new FormData () ;
    imgformData.append ( `base64`, file ) ;
    imgformData.append ( `user_token`, token ) ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServerI + `/imageReview/identifyPornAndTerror`,
        data: imgformData,
        headers: {
            'Cache-Control': `no-cache`
        }
    } ).then ( res => {
        console.log ( `鉴黄信息res`, res ) ;
        if ( res.status === 200 && res.data.data.code === 200 ) {
            return res.data ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;
/**
 * @description 给图片鉴黄鉴暴
 * @param type 图片格式
 * @param file formData图片，base64 url 都可以
 */
export const BoardGreenImageScan = ( type:`base64`|`formdata`, file ) => {
    return new Promise ( ( resolve, reject ) => {
        switch ( type ) {
        case `formdata`:
            _greenImageScanFormdata ( file )
                .then ( res => resolve ( res ) )
                .catch ( e => {
                    reject ( e ) ;
                } ) ;
            break ;
        case `base64`:
            _greenImageScanBase64 ( file )
                .then ( res => resolve ( res ) )
                .catch ( e => {
                    reject ( e ) ;
                } ) ;
            break ;
        default: reject ( new TypeError ( `type 只能为'formdata'或者'base64'` ) ) ;
        }
    } ) ;
} ;

/**
 * @description 给图片打标签
 * @param smallBase64 base64图片
 * @param imgUrl 原网站地址
 */
export const BoardForImageLabel = async ( smallBase64, imgUrl = ``, imgId = ``, referer = `www.bigbigwork.com` ) => {
    if ( smallBase64 ) {
        smallBase64 = smallBase64.slice ( smallBase64.indexOf ( `,` ) + 1 ) ;
    }
    const token = await getToken () ;
    const formData = new FormData () ;
    formData.append ( `imageBase64`, smallBase64 || `` ) ;
    formData.append ( `user_token`, token ) ;
    formData.append ( `imgUrl`, imgUrl || `` ) ;
    formData.append ( `referer`, referer ) ;
    formData.append ( `imgId`, imgId || `` ) ;
    return axios ( {
        url: ServerBoardServer2 + `/collectuserlook/identifyLabelByUrl2`,
        method: `POST`,
        data: formData
    } ).then ( res => {
        if ( res.status === 200 && res.data.code === 200 ) {
            const e = res.data ;
            const Tag = function ( item, index ) {
                this.show_name = item ;
                this.index = index ;
                this.choose = false ;
            } ;
            const tags = [] ;
            e.data.forEach ( ( item, index ) => {
                tags[index] = new Tag ( item, index ) ;
            } ) ;
            return tags ;
        } else {
            return [] ;
        }
    } ) ;
} ;

/**
 * @description mataSearch的搜索结果转化为搜索引擎结果(加上版权信息) 20190917
 * @param item
 * @param boardId
 * @param type
 * @see http://doc.bigbigwork.com/docs/develop/439
 */
export const BoardMetaCopyrightToSearch = async ( item:ResultItem, boardId, type ) => {
    await getCopyrightInfo ( item ) ;
    item = await getLargePage ( item ) ;
    console.log ( item ) ;
    // @ts-ignore
    const param = await ResultItem.prototype.toBoard.call ( item, boardId, type ) ;
    const extendInfo:any = {} ;
    extendInfo.show_img_url = param.col_groupImages ;
    // @ts-ignore
    extendInfo.img_download = item.copyrightInfo.detailsUrl ;
    extendInfo.img_source = param.img_source ;
    extendInfo.board_id = param.board_id ;
    extendInfo.domain = item.siteInfo.domain ;
    const isDatu = document.location.href.indexOf ( `freecopyright` ) > -1 ;
    extendInfo.collection_type = isDatu ? 16 : 14 ; // 14 FLICKR收藏 16大兔
    extendInfo.is_continue = 1 ; // 先写死1 不校验重复收藏
    extendInfo.img_name = param.img_name ;
    extendInfo.img_info = param.img_info ;
    extendInfo.img_width = param.img_width ;
    extendInfo.img_height = param.img_height ;
    extendInfo.owner_name = param.owner_name ;
    extendInfo.lable = param.lable ;
    extendInfo.user_token = param.user_token ;
    extendInfo.copyright = JSON.stringify ( item.copyrightInfo ) ;
    if ( !extendInfo.img_download ) {
        extendInfo.img_download = `http://@proxy@/download/` + item.siteInfo.servletUrl + `?action=LARGE&page=` + encodeURIComponent ( item.pageUrl ) + `&url=` + encodeURIComponent ( item.url ) + `&w=` + item.w + `&h=` + item.h ;
    }
    console.log ( extendInfo ) ;
    return $axios ( {
        url: ServerBoardServer + `/copyright/collectionImg`,
        params: extendInfo
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            console.error ( `收藏失败` ) ;
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        console.error ( `网络错误，收藏失败` ) ;
        throw e ;
    } ) ;
} ;
/**
 * @description mataSearch的搜索结果转化为搜索引擎结果
 * @param item
 * @param boardId
 * @param type
 * @param lableIds
 * @param originType 收藏来源（默认没有）
 * @see http://doc.bigbigwork.com/docs/develop/439
 */
export const BoardMataToSearch = async ( item:ResultItem, boardId, type, lableIds, originType:number = null ) => {
    try {
        item = await getLargePage ( item ) ;
    } catch ( e ) {
        throw e ;
    }
    const param = await ResultItem.prototype.toBoard.call ( item, boardId, type, lableIds ) ;
    Object.assign ( param, {
        lableIds
    } ) ;
    if ( originType !== null ) {
        param.origin_type = originType ;
    }
    const data = convertParamsToFormData ( param ) ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer2 + `/collectuserlook/insertImg`,
        data
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            console.error ( `收藏失败` ) ;
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        console.error ( `网络错误，收藏失败` ) ;
        throw e ;
    } ) ;
} ;

/**
 * @description mataSearch的搜索结果转化为搜索引擎结果 新的鉴黄收藏二合一接口
 * @param item
 * @param boardId
 * @param type
 * @param lableIds
 * @param originType 收藏来源（默认没有）
 * @see http://doc.bigbigwork.com/docs/develop/439
 */
export const BoardMataToSearchNew = async ( item:ResultItem, boardId, type, lableIds, originType:number = null ) => {
    try {
        item = await getLargePage ( item ) ;
    } catch ( e ) {
        throw e ;
    }
    const param = await ResultItem.prototype.toBoard.call ( item, boardId, type, lableIds ) ;
    Object.assign ( param, {
        lableIds
    } ) ;
    if ( originType !== null ) {
        param.origin_type = originType ;
    }
    param.col_groupImages = decodeURIComponent ( param.col_groupImages ) ;
    const data = convertParamsToFormData ( param ) ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer + `/collectuserlook/pinNewQuickCollect `,
        data
    } ).then ( res => {
        if ( res.status === 200 && res.data.code === 200 ) {
            return res ;
        } else {
            console.error ( `收藏失败` ) ;
            return res ;
        }
    } ).catch ( e => {
        console.error ( `网络错误，收藏失败` ) ;
        throw e ;
    } ) ;
} ;
/**
 * 收藏图片
 * @param item ResultItem
 * @param boardId
 * @param remark
 * @param labels
 * @param isFreeCopyright 是否是免费版权
 * @param originType 收藏类型
 * @param green 是否需要鉴黄
 * @constructor
 */
export const BoardFavPicture = async ( item, boardId:number, remark, labels, isFreeCopyright, originType:number = null, green:boolean = false ) => {
    console.log ( `BoardFavPicture`, item, { isFreeCopyright, green, type: item.type } ) ;
    let err:Error ;
    item.board_id = boardId ;
    // 如果需要鉴黄，则先走鉴黄流程       item.pinUrl为了解决聚合收藏鉴黄跨域问题
    if ( green ) {
        let url ;
        if( item.isPinlite ) {
            url = item.pinUrl;
        } else if ( item.meta ) {
            url = SearchProxy.getImgUrl ( (item.pinUrl || item.url), `thumbAuto`, item ) ;
        } else {
            url = toRelativeProtocol ( ( item.pinUrl || item.url ) ) ;
        }
        const base64 = await getBase64Image ( url ) ;
        try {
            await BoardGreenImageScan ( `base64`, base64 ) ;
        } catch ( e ) {
            return { status: 403, message: `图片涉恐涉暴!` } ;
        }
        // await BoardGreenImageScan ( `base64`, base64 ).catch ( e => {
        //     MessageBox.alert ( `您搜索的关键词可能涉及不良信息，请更换关键词重新搜索`, `提示`, {
        //         confirmButtonText: `知道了`
        //     } ) ;
        //     err = e ;
        // } ) ;
        if ( err ) return ;
    }
    // 如果是聚合的图片，则需要转换为board图片
    if ( item.type === `meta` ) {
        // @ts-ignore
        item.img_info && ( item.img_info = item.img_info.substr ( 0, 100 ) ) ;
        if ( item.siteInfo?.id === 446 || isFreeCopyright ) {
            return BoardMetaCopyrightToSearch ( item, boardId, 1 ) ;
        } else {
            return BoardMataToSearch ( item, boardId, 1, labels, originType ) ;
        }
    }
    const token = await getToken () ;
    // console.log('send-req', JSON.stringify(item))
    return $axios ( {
        url: ServerBoardServer + `/img/collectImg`,
        method: `POST`,
        headers: {
            'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
        },
        data: qs.stringify ( {
            board_id: boardId,
            img_id: item.id,
            user_token: token,
            rresemark_img: remark,
            lableIds: ``,
            label: item.label || ``,
            img_info: item.img_info,
            is_continue: item.is_continue
        } )
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;
/**
 * 快速收藏图片新接口
 * @param item ResultItem
 * @param boardId
 * @param remark
 * @param labels
 * @param isFreeCopyright 是否是免费版权
 * @param originType 收藏类型
 * @param green 是否需要鉴黄
 * @constructor
 */
export const BoardFavPictureFast = async ( item, boardId:number, remark, labels, isFreeCopyright, originType:number = null, green:boolean = false ) => {
    console.log ( `BoardFavPictureFast`, item, { isFreeCopyright, green, type: item.type } ) ;
    let err:Error ;
    item.board_id = boardId ;
    // 如果是聚合的图片，则需要转换为board图片   聚合走鉴黄收藏二合一接口
    if ( item.type === `meta` ) {
        // @ts-ignore
        item.img_info && ( item.img_info = item.img_info.substr ( 0, 100 ) ) ;
        if ( item.siteInfo?.id === 446 || isFreeCopyright ) {
            return BoardMetaCopyrightToSearch ( item, boardId, 1 ) ;
        } else {
            return BoardMataToSearchNew ( item, boardId, 1, labels, originType ) ;
        }
    } else if ( item.type !== `meta` && green ) {
        // 如果需要鉴黄，则先走鉴黄流程       item.pinUrl为了解决聚合收藏鉴黄跨域问题
        let url ;
        if ( item.isPinlite ) {
            url = item.pinUrl ;
        } else if ( item.meta ) {
            url = SearchProxy.getImgUrl ( ( item.pinUrl || item.url ), `thumbAuto`, item ) ;
        } else {
            url = toRelativeProtocol ( ( item.pinUrl || item.url ) ) ;
        }
        const base64 = await getBase64Image ( url ) ;
        try {
            await BoardGreenImageScan ( `base64`, base64 ) ;
        } catch ( e ) {
            return { status: 403, message: `图片涉恐涉暴!` } ;
        }
        // await BoardGreenImageScan ( `base64`, base64 ).catch ( e => {
        //     MessageBox.alert ( `您搜索的关键词可能涉及不良信息，请更换关键词重新搜索`, `提示`, {
        //         confirmButtonText: `知道了`
        //     } ) ;
        //     err = e ;
        // } ) ;
        if ( err ) return ;
    }
    const token = await getToken () ;
    // console.log('send-req', JSON.stringify(item))
    return $axios ( {
        url: ServerBoardServer + `/img/collectImg`,
        method: `POST`,
        headers: {
            'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
        },
        data: qs.stringify ( {
            board_id: boardId,
            img_id: item.id,
            user_token: token,
            rresemark_img: remark,
            lableIds: ``,
            label: item.label || ``,
            img_info: item.img_info,
            is_continue: item.is_continue
        } )
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ) ;
} ;

/** 修改图片信息 */
export const BoardEditImageInfo = async ( imgId, lableIds, info, boardId ) => {
    const token = await getToken () ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer + `/img/editImg`,
        data: {
            user_token: token,
            imgId,
            lableIds,
            info,
            boardId
        }
    } ).then ( res => {
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            const errStr = res.data.errorCode || `修改失败` ;
            // @ts-ignore
            this.$alert ( errStr, `提示`, {
                confirmButtonText: `确定`,
                callback: action => {}
            } ) ;
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        const errStr = `修改失败` ;
        // @ts-ignore
        this.$alert ( errStr, `提示`, {
            confirmButtonText: `确定`,
            callback: action => {}
        } ) ;
        throw new Error ( e ) ;
    } ) ;
} ;

/** 删除图片信息 */
export const BoardDeleteMyPicture = async ( id, imgId, boardId ) => {
    const token = await getToken () ;
    return $axios ( {
        method: `POST`,
        url: ServerBoardServer + `/img/deleteImgNew`,
        data: {
            id,
            imgId,
            board_id: boardId,
            user_token: token
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            return res.data ;
        } else {
            const errStr = res.data.errorCode || `删除图片失败` ;
            // @ts-ignore
            this.$alert ( errStr, `提示`, {
                confirmButtonText: `确定`,
                callback: action => {}
            } ) ;
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        const errStr = `删除图片失败` ;
        // @ts-ignore
        this.$alert ( errStr, `提示`, {
            confirmButtonText: `确定`,
            callback: action => {}
        } ) ;
        throw new Error ( e ) ;
    } ) ;
} ;

/**
 * @description 新删除图片(协作版本开始)
 * @param imgId
 * @param isShare
 */
export const BoardDeleteSingleImage = async ( imgId, isShare ) => {
    const token = await getToken () ;
    return $axios ( {
        url: ServerBoardServer + `/img/batchDeleteImg`,
        params: {
            user_token: token,
            imgIds: imgId,
            is_share: isShare ? 1 : 0
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            return res.data ;
        } else {
            const errStr = res.data.errorCode || `删除图片失败` ;
            // @ts-ignore
            this.$alert ( errStr, `提示`, {
                confirmButtonText: `确定`,
                callback: action => {}
            } ) ;
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        const errStr = `删除图片失败` ;
        // @ts-ignore
        this.$alert ( errStr, `提示`, {
            confirmButtonText: `确定`,
            callback: action => {}
        } ) ;
        throw new Error ( e ) ;
    } ) ;
} ;
/**
 * @description 新删除图片(团队图片集删除图片)
 * @param imgId
 * @param isShare
 * @param bid  父图片集id
 * @param cuuid 子图片集uuid
 * @param cid  子图片集boardid
 */
export const teamDeleteSingleImage = async ( imgId, isShare, bid, cuuid, cid ) => {
    const token = await getToken () ;
    return $axios ( {
        url: ServerBoardServer + `/recycleBin/add`,
        params: {
            token: token,
            imgIds: imgId,
            bid: bid,
            child_board_uuid: cuuid,
            child_board_id: cid,
            is_share: isShare ? 1 : 0
        },
        method: `POST`
    } ).then ( res => {
        if ( res.status === 200 ) {
            return res.data ;
        } else {
            const errStr = res.data.errorCode || `删除图片失败` ;
            // @ts-ignore
            this.$alert ( errStr, `提示`, {
                confirmButtonText: `确定`,
                callback: action => {}
            } ) ;
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        const errStr = `删除图片失败` ;
        // @ts-ignore
        this.$alert ( errStr, `提示`, {
            confirmButtonText: `确定`,
            callback: action => {}
        } ) ;
        throw new Error ( e ) ;
    } ) ;
} ;
/** 举报board */
export const BoardReport = async ( board, options ) => {
    const token = await getToken () ;
    const params = {
        user_token: token,
        board_id: board.id,
        board_owner_id: board.owner.id
    } ;
    axios ( {
        url: ServerBoardServer + `/report/report`,
        params: {
            ...params,
            ...options
        }
    } ) ;
} ;
