import type { Guide } from "./Guide" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import some from "lodash-es/some";
import find from "lodash-es/find";
import remove from "lodash-es/remove";
// import {some, find, remove} from 'lodash-es';
import { isDev } from "@scripts/utils";

export interface GuideEventOptions {
    name: string
}

export class GuideEvent {
    name: string;
    guide: Guide[];
    current: number;
    isProcess: boolean; // 是否正在执行中
    constructor ( options:GuideEventOptions ) {
        if ( !options.name ) throw new Error ( `向导事件必须有名字` ) ;
        this.name = options.name ;
        this.guide = [] ;
        this.current = 0 ;
        this.isProcess = false ;
        this.init () ;
    }

    /**
     * @description 初始化向导事件
     */
    init () {
        BigBigWork.addListener ( this.name, () => {
            if ( this.isProcess ) return;
            // 每次触发事件时，从向导事件中的第一个开始执行
            this.current = 0 ;
            this.guide[0].action () ;
            this.isProcess = true ;
        } ) ;
    }

    /**
     * 按名称查找是否存在对应向导
     * @param name 向导名
     */
    hasGuide ( name:string ) {
        return some ( this.guide, { name: name } ) ;
    }

    /**
     * 在向导事件中添加向导，根据order排序
     * @param guide
     */
    add ( guide:Guide ) {
        if ( this.hasGuide ( guide.name ) ) {
            isDev() && console.log ( `已存在向导：` + guide.name ) ;
            return ;
        }
        this.guide.push ( guide ) ;
        this.guide.sort ( function ( a, b ) {
            return a.order - b.order ;
        } ) ;
    }

    /** 删除向导
     * @param name 向导名
     */
    remove ( name:string ) {
        this.guide = remove ( this.guide, ( guide ) => {
            return guide.name === name ;
        } ) ;
    }

    /**
     * 据名字获取向导
     * @param name 向导名
     */
    getGuideByName ( name:string ) {
        return find ( this.guide, function ( guide ) {
            return guide.name === name ;
        } ) ;
    }

    /**
     * 执行下一个向导
     */
    next () {
        if ( this.current < this.guide.length - 1 ) {
            this.current += 1 ;
            this.guide[this.current].action () ;
        }else{
            this.isProcess = false;
        }
    }
}
