<template>
  <!--        v-show="isIE && pageTop===0"-->
  <div :class="$style['browser-tips']" @click="downloadGoogle">
    <div :class="$style.browserBackground"></div>
    <!--            <div class="content">-->
<!--    <img width=34 height=34 :src="`${process.env.CDN_XCX_PATH}/p/images/browser-google.png`" alt="">
    <img width=36 height=36 :src="`${process.env.CDN_XCX_PATH}/p/images/browser-360.png`" alt="">
    <img width=31 height=34 :src="`${process.env.CDN_XCX_PATH}/p/images/browser-360-1.png`" alt="">
    <span>请使用Chrome、QQ或360浏览器“极速模式”获得最佳体验！</span>-->
    <!--            </div>-->
  </div>
</template>
<script lang="ts">
  export default {
    name: "BannerBrowser",
    methods: {
      downloadGoogle() {
        window.open('https://www.google.cn/chrome/', '_blank')
      },
    }
  }
</script>
<style lang="scss" module>
.browser-tips {
  overflow: hidden;
  cursor: pointer;
  height: 100px;
  background: #ED654E;
  font-size: 16px;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:all .3s;
  color:#fff;
  img {
    margin-right: 16px;
    vertical-align: bottom;
  }
  .browserBackground{
    width: 100%;
    height: 100px;
    background: url('#{$cdn_dz_path}/bbw/ie_banner.jpg') no-repeat 50% #fff;
  }
}
</style>