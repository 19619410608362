import { Store } from "vuex" ;
import { isDev } from "@scripts/utils";

export interface GuideOptions {
    name: string,
    order?: number,
    conditionEvent?: string,
    triggerEvent?: string,
    triggerDom?: string,
    enable?: boolean,
    once?: boolean,
    whiteList?: Array<string>,
    store?:Store<any>,
    onAction?: ( guide?:Guide, store?:Store<any> )=>void
}

/** 向导类 */
export class Guide {
    public name:string
    public order: number
    conditionEvent: string
    triggerEvent: string
    triggerDom: string
    enable: boolean
    once: boolean
    whiteList: Array<string>
    onAction: ( guide?:Guide, store?:Store<any> )=>void
    store: Store<any>
    constructor ( options:GuideOptions ) {
        if ( !options || !options.name ) throw new Error ( `Guide必须有名称` ) ;
        Object.assign ( this, Guide.defaultOptions, options ) ;
    }

    /** 默认选项 */
    public static defaultOptions:Required<GuideOptions> = {
        name: `未命名向导`,
        order: 0,
        conditionEvent: ``,
        triggerEvent: ``,
        triggerDom: null,
        enable: true,
        once: true,
        whiteList: null,
        store: null,
        onAction: ( guide, store ) => {}
    }

    init () {
        if ( this.triggerDom ) {
            // 目标元素集合
            const targetElements = document.querySelectorAll ( this.triggerDom ) ;

            for ( let i = 0 ; i < targetElements.length ; i++ ) {
                targetElements[i].addEventListener ( this.triggerEvent, () => {
                    this.action () ;
                } ) ;
            }
        }
    }

    action () {
        // 事件可用或者在白名单时才触发
        //if ( !this.enable || !this.isInWhiteList () ) return ;
        isDev() && console.log ( `向导事件触发：` + this.conditionEvent + `;向导触发：` + this.name ) ;
        this.onAction ( this, this.store ) ;
        if ( this.once ) this.enable = false ;
    }

    isInWhiteList () {
        var list = this.whiteList ;
        if ( list === null ) return true ;
        for ( let i = 0 ; i < list.length ; i++ ) {
            if ( window.location.href.match ( list[i] ) ) {
                return true ;
            }
        }
        return false ;
    }
}
