import {AiEntryConfig, fetchAiEntry} from "@scripts/ai-entry";
import {Module} from "vuex";
import {getToken} from "@scripts/tools";

export type AiEntryState = {
    list: AiEntryConfig[]
}

export const AiEntry: Module<AiEntryState, any> = {
    namespaced: true,
    state: {
        list: []
    },
    actions: {
        async FETCH_AI_ENTRY ( context, payload: {userId?:string, token?:string } = {} ) {
            try {
                const token = payload.token ?? getToken () ;
                const uid = payload.userId ?? context.rootState.UserInfo.id ;
                if ( !token ) return ;
                const [err, data] = await fetchAiEntry ( uid, token ) ;
                if ( err ) throw err ;
                context.state.list = [...data] ;
                return [null, data] ;
            } catch ( e ) {
                return [e, null] ;
            }
        }
    }
}