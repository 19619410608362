import axios from "axios" ;
import { ServerBoardServer } from "@constants/servers" ;
import { NetError } from "@scripts/tools" ;
import { LocalStorageRead, LocalStorageWrite } from "@scripts/LocalStorage" ;
import {BigBigWork} from "@scripts/BigBigWork";

export type CopyrightType = {
    id:number,
    // eslint-disable-next-line camelcase
    en_abbr: string
}

/** 新版copyright(2020-10)，适用于带copyright_id的resultItem */
export class CopyrightV2 {
    copyrightText:string;
    copyrightID:number;
    static copyrightList: Array<CopyrightType> = [];
    constructor ( id ) {
        if ( !id ) {
            this.copyrightID = 23 ;
            this.copyrightText = `未知版权` ;
        }
        this.copyrightID = id ;
        this.copyrightText = this.getCopyRightText () || `未知版权` ;
    }

    public static isFetching = false;

    /** 获取copyright信息 */
    getCopyRightText () {
        return CopyrightV2.copyrightList.find ( val => {
            return val.id === this.copyrightID ;
            // eslint-disable-next-line camelcase
        } )?.en_abbr ;
    }

    /** 静态方法，从服务器获取copyright信息 */
    public static async fetchCopyright () {
        if( this.isFetching ) {
            return new Promise ( ( resolve, reject ) => {
                BigBigWork.once ( `CopyrightSuccess`, ( data ) => {
                    if ( this.copyrightList ) {
                        resolve ( true ) ;
                    } else {
                        reject ( new Error ( `copyright error` ) ) ;
                    }
                })
            })
        }
        // 先查看缓存，如果有记录则从缓存获取
        const copyrightCache = LocalStorageRead<Array<CopyrightType>> ( `copyrightList`, ``, false ) ;
        if ( copyrightCache ) {
            this.copyrightList = copyrightCache ;
            return ;
        }
        this.isFetching = true ;
        // 否则从缓存获取
        return axios ( {
            url: ServerBoardServer + `/copyright/getList`
        } ).then ( res => {
            if ( res.status !== 200 ) {
                throw new NetError ( res.statusText ) ;
            }
            const data = res.data ;
            if ( data.status !== 200 ) {
                throw new Error ( `获取copyright报错` ) ;
            }
            const result = data.data ;
            // 把结果存入localStorage
            LocalStorageWrite ( `copyrightList`, ``, result, 1, false ) ;
            this.copyrightList = result ;
            this.isFetching = false ;
            BigBigWork.emit ( `CopyrightSuccess` ) ;
        } ).catch ( e => {
            BigBigWork.emit ( `CopyrightSuccess` ) ;
            throw e ;
        } ) ;
    }
}
