import { checkIsIncognito, cookieGet, cookieSet } from "@scripts/CrossDomain" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import Cookies from "js-cookie" ;
import { getDomain } from "@scripts/tools" ;
/** 检查是否设置红包cookie */
export const checkRedEnvelopeCookie = async ():Promise<boolean> => {
    try {
        // 是否需要显示红包（无cookie，或第二次谈红包后需要显示）
        let returnFlag:boolean = true ;
        const redEnvelope = await getRedEnvelopeCookie () ;
        if ( redEnvelope ) {
            const redEnvelopeReg = /(\d)\|(\d+)/ ;
            const redEnvelopeRegArr = redEnvelopeReg.exec ( redEnvelope as string ) ;
            if ( redEnvelopeRegArr ) {
                const cookieShowTimes = Number ( redEnvelopeRegArr[1] ) ;
                const cookieSetTimes = Number ( redEnvelopeRegArr[2] ) ;
                const intervalTimes = ( new Date ().getTime () - cookieSetTimes ) / 1000 / 60 / 60 ; // 小时
                // 间隔1小时下次弹框 企业微信二维码从cookie中获取时间
                // const SecondCodeTime = Number ( Cookies.get ( `SecondCodeTime` ) ) ? Number ( Cookies.get ( `SecondCodeTime` ) ) / 60 /60 : 0 ;
                // 小时
                const timeFlag = __DEV__ ? 1 / 20 : 0.5 ;
                if ( cookieShowTimes === 1 && intervalTimes > timeFlag ) { // 第一次 关闭计时
                    // 二次是红包
                    returnFlag = true ;
                    BigBigWork.emit ( `Analysis`, { type: `RedEnvelope`, data: `第二次弹框` } ) ;
                } else if( intervalTimes <= timeFlag) { // 间隔时间太短也不弹
                    returnFlag = false ;
                }else {
                    returnFlag = false ;
                }
            } else {
                returnFlag = true ;
            }
        } else {
            BigBigWork.emit ( `Analysis`, { type: `RedEnvelope`, data: `第一次弹框` } ) ;
            returnFlag = true ;
        }
        return returnFlag ;
    } catch ( e ) {
        throw e ;
    }
} ;
/** 获取红包cookie */
export const getRedEnvelopeCookie = async ( ) => {
    const [err, redEnvelope] = await cookieGet ( `RedEnvelope` ) ;
    console.log(` %c 大红包 ${redEnvelope}`, 'font-size:20px; color:blue');
    return redEnvelope ;
} ;
/** 设置红包cookie */
export const setRedEnvelopeCookie = async ( val, options ) => {
    await cookieSet ( `RedEnvelope`, val, { ...options, ...{ sameSite: `none`, secure: true } } ) ;
} ;
/** 获取跨域cookie */
export const getCrossCookie = async ( name:string ) => {
    const [err, redEnvelope] = await cookieGet ( name ) ;
    return redEnvelope;
} ;
/** 设置跨域cookie */
export const setCrossCookie = async ( name:string, val, options:Cookies.CookieAttributes ) => {
    await cookieSet ( name, val, { ...options, ...{ sameSite: `none`, secure: true } } ) ;
} ;
