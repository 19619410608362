import ColorThief from '../third-party/color-thief' ;
import colorConvert from 'color-convert' ;
/**
 * 获取色卡
 * @param image 图片url或dom
 * @param count 颜色数量
 */
export const ColorGetPalette = async ( image:string, count:number = 6 ):Promise<Array<number[]>> => {
    return await ColorThief.getPalette ( image, count ) ;
} ;

export const ColorRGBToHex = ( rgb:Uint8ClampedArray ) => {
    // @ts-ignore
    return colorConvert.rgb.hex ( ...rgb ) ;
} ;

/** 将RGB色值转换为数组[R,G,B] */
export const ColorNumberToColorArray = ( color:number ):Array<number> => {
    const R = ( color & 0xff0000 ) >> ( 2 ** 2 * 4 ) ;
    const G = ( color & 0xff00 ) >> ( 2 ** 2 * 2 ) ;
    const B = color & 0xff ;
    return [R, G, B] ;
} ;

/** 将HEX色值转换为位置百分比 */
export const ColorHexToPosition = ( hex:string ): number[] => {
    try {
        const hsl = colorConvert.hex.hsl ( hex ) ;
        const x = hsl[0] / 360 * 100 ;
        const y = hsl[2] ;
        return [x, y] ;
    } catch ( e ) {
        throw e ;
    }
} ;

/** 检测字符串是否HEX色值（不含#） */
export const ColorIsHEX = ( str: string ):boolean => {
    const PATTERN_HEX = /^[0-9a-fA-F]{6}$/ ;
    return !!str.match ( PATTERN_HEX ) ;
} ;
/** 将位置百分比转换为HEX色值 */
export const ColorPositionToHex = ( position: number[] ) => {
    const deg = position[0] / 100 * 360 ;
    const lightness = position[2] ;
    const hsl = [deg, 100, lightness] as [number, number, number] ;
    return colorConvert.hsl.hex ( hsl ) ;
} ;
/** 生成色环数组 */
export const ColorCreateColorRing = ( width:number, saturationRadio: number = 50 ):ImageData => {
    const palette = [] ;
    const step = 360 / width ;
    const height = 100 + saturationRadio ;
    for ( let j = height - 1 ; j >= 0 ; j-- ) {
        let lightness ;
        if ( j < ( height - saturationRadio ) / 2 ) {
            lightness = j / 50 + 50 ;
        } else if ( j > ( height - saturationRadio ) / 2 ) {
            lightness = ( j - saturationRadio - ( 100 - saturationRadio ) / 2 ) / 50 ;
        } else {
            lightness = 50 ;
        }
        for ( let i = 0 ; i < width ; i++ ) {
            const deg = step * i ;
            const hue = deg ;
            const color = [hue, 100, lightness] as [number, number, number] ;
            const rgb = colorConvert.hsl.rgb ( color ) ;
            palette.push ( ...[...rgb, 255] ) ;
        }
    }
    const data = Uint8ClampedArray.from ( palette ) ;
    return new ImageData ( data, data.length / height / 4, height ) ;
} ;
/** 在canvas上画色环 */
export const ColorCreateRingCanvas = async ( canvas:HTMLCanvasElement ) => {
    return new Promise ( ( resolve, reject ) => {
        const ctx = canvas.getContext ( `2d` ) ;
        const ring = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANIAAAABCAIAAAAkUWeUAAAAxElEQVR4AXWRB64rQQjAzNZ//5u+9EzjAwpS6siyvH3RiO68rwFLssKWNnbCyb9nZ7z7iXxJvjZYkhkmOjSkQY0oEeZE7vCEuJXbkPvg3t3eHlJG2HuyrmadqoXObUxNjbkzwSI5bbIlbzNn5OGzk/WJmEoX2kybHq5BMYu7CHcL3N4e7sKTk2pIGBrakC42wzJYh3szCN636BdPuyQ5hjzGWJQlvGpZ9CZ6ZVzQC+MUHNGj2zm49ZDnzee484rekIXX9R83Jo4RLxbUQgAAAABJRU5ErkJggg==` ;
        const img = document.createElement ( `img` ) ;
        img.src = ring ;
        img.onload = () => {
            ctx.drawImage ( img, 0, 0, canvas.width, canvas.height ) ;
            const gradient1 = ctx.createLinearGradient ( 0, 0, 0, canvas.height * 0.5 ) ;
            gradient1.addColorStop ( 0, `hsla(0,0%,100%,1)` ) ;
            gradient1.addColorStop ( 0.08, `hsla(0,0%,100%,1)` ) ;
            gradient1.addColorStop ( 0.2, `hsla(0,0%,100%,0.89)` ) ;
            gradient1.addColorStop ( 0.38, `hsla(0,0%,100%,0.69)` ) ;
            gradient1.addColorStop ( 0.63, `hsla(0,0%,100%,0.35)` ) ;
            gradient1.addColorStop ( 0.78, `hsla(0,0%,100%,0.18)` ) ;
            gradient1.addColorStop ( 0.91, `hsla(0,0%,100%,0.06)` ) ;
            gradient1.addColorStop ( 1, `hsla(0,0%,100%,0)` ) ;
            ctx.fillStyle = gradient1 ;
            ctx.fillRect ( 0, 0, canvas.width, canvas.height * 0.5 ) ;

            const gradient2 = ctx.createLinearGradient ( 0, canvas.height * 0.5, 0, canvas.height ) ;
            gradient2.addColorStop ( 0, `rgba(0, 0, 0, 0.000)` ) ;
            gradient2.addColorStop ( 0.088, `rgba(0, 0, 0, 0.012)` ) ;
            gradient2.addColorStop ( 0.184, `rgba(0, 0, 0, 0.039)` ) ;
            gradient2.addColorStop ( 0.28, `rgba(0, 0, 0, 0.102)` ) ;
            gradient2.addColorStop ( 0.405, `rgba(0, 0, 0, 0.239)` ) ;
            gradient2.addColorStop ( 0.683, `rgba(0, 0, 0, 0.690)` ) ;
            gradient2.addColorStop ( 0.897, `rgba(0, 0, 0, 1.000)` ) ;
            ctx.fillStyle = gradient2 ;
            ctx.fillRect ( 0, canvas.height * 0.5, canvas.width, canvas.height ) ;

            resolve ( canvas ) ;
        } ;
    } ) ;
} ;
