/* eslint-disable */
import { isDev,  } from "@scripts/utils";
import {$axios} from "@scripts/Axios";
import trim from "lodash-es/trim";
import Copyright from "@scripts/copyright";
import type Site from "@scripts/site";
import { getDownloadImageUrl, SearchProxy } from "@scripts/Proxy";
import {getDomain, getToken, toRelativeProtocol} from "@scripts/tools";
import { ServerBoardServer } from "@constants/servers";
import {CopyrightV2} from "@scripts/copyright_v2";
import qs from 'qs'

/** cdn信息 */
export interface CdnInfo {
    bcdnurl: string;
    scdnurl: string;
}
/** 图片评论 */
export interface Remarks {
    id: number;
    content: string;
}

export class ResultItem{
    public from: number
    /** 图片id */
    public id:number;
    /** 图片uuid */
    public uuid:number;
    /** 图片在board中的id */
    public img_id:number;
    /** 缩放高度 */
    public scaleHeight:number;
    /** 初始化时的选项 */
    public options: any;
    /** 是否选中 */
    public choose: boolean;
    /** cdn信息 */
    public cdnInfo: CdnInfo;
    /** 是否有cdn信息 */
    public isCdn: boolean;
    /** 图片标签信息 */
    public labels: string;
    /** 图片存放在哪个图片集 */
    public board_id: number;
    /** 图片上传者信息 */
    public owner: object;
    /** 大图页相关图片来源 */
    public img_origin: string;
    /** 大图页相关推荐图片算法版本 */
    public ver:string;
    /** 所属搜索结果管理器 */
    public manager: object;
    /** 作者 */
    public author:string;
    /** 作者主页 */
    public authorUrl:string;
    /** 大图 */
    public bUrl:string;
    /** 版权信息(旧的，全球聚合还在用) */
    public copyright: Copyright;
    /** 版权信息(新的，给部份网站跟大兔使用) */
    public copyrightInfo: {
        cn: string
        des_url: string
        en: string
        en_abbr: string
        id: number
        orignal_des_url: string
        show_icon: string
        show_name: string } | null;
    /** 新版copyright, 列表由服务器提供，根据options.copyright_id查询 */
    public copyrightV2: CopyrightV2;
    /** 选择的收藏组图索引 */
    public thumbListIndex: Array<string>;
    /** 图片标题 */
    public title: string;
    /** 图片标题（翻译）*/
    public title_cn: string;
    /** 图片描述 */
    public description: string;
    /** 图片描述（翻译）*/
    public description_cn: string;
    /** 原图片下载地址 */
    public detailsUrl: string;
    /** 下载地址（可直接下载的链接）*/
    public downloadUrl: string;
    /** 搜索结果高度 */
    public h: number;
    /** 搜索结果宽度 */
    public w: number;
    /** 所属行业 */
    public industry: string;
    /** 瀑布流显示宽度 */
    public dw: number;
    /** 瀑布流显示高度 */
    public dh: number;
    /** 来源网页 */
    public pageUrl: string;
    /** 搜索结果列表页图片 */
    public url: string;
    /** 搜索结果站点信息 */
    public siteInfo: Site;
    /** 图片评论 */
    public remarks: Array<Remarks>;
    /** 搜索结果站点domain */
    public domain: string;
    /** 搜索结果站点id */
    public siteId: number;
    /** 是否重要图片 */
    public like:boolean;
    /** 根据网站优先值计算出的排序 */
    public order: number;
    /** 显示的顺序 */
    public displayOrder:number;
    /** 组图原图列表 */
    public groupImages: Array<string>;
    /** 组图缩略图列表 */
    public thumbImages: Array<string>;
    /** 组图下载列表 */
    public downloadImages:Array<string>;
    /** 图片是否已加载 */
    public imgloaded: boolean;
    /** 区分是否有相似图片集 */
    public is_board: number;
    /** 相似board集合 */
    public related_board: Array<any>;
    /** 图片加速服务器 */
    public proxy: string;
    /** 国内加速服务器 */
    public domesticServer:string;
    /** 是否是扩大图 */
    public expandimage:boolean;
    /** 是否从MetaSearch迁移过来的数据(即将弃用) */
    public isMetaSearch: ()=>boolean;
    /** 是否智能聚合数据(即将弃用) */
    public meta:boolean;
    /** 是否已翻译(搜索引擎默认为已翻译) */
    public translated: boolean;
    /** 搜索引擎中记录的metasearch中的url */
    public other_url:string;
    /** 图片类型（0-用户上传 , 1-P网 , 2-爬虫 ,3-huaban , 4-ins ,5-behance, 13-采集器 , 14-聚合收藏 , 15-大作收藏, 50-pinlite） */
    public origin_type:number;
    /** 搜索结果类型"search","meta","metaToSearch","pinterest"） */
    public type: string;
    /** 搜索类型 一个字符串 测试环境使用 */
    public tag:string;
    /** 名称 */
    public name:string;
    /** 背景色 */
    public color:string;
    /** 是否是中文标题 */
    public isChinese:boolean;
    /** 标题描述是否相同 */
    public isSameTitleDesc: boolean;

    public pin_industry: string

    public collaborateMemeberInfo: { is_member: number }

    public img_format: number

    public collaborateImgVotePojo: {
        comment_count: number
        dislike_count: number
        id: number
        like_count: number
        think_count: number
        vote_status: number
    }
    public child_board_uuid: string;
    public child_board_id: number;
    public enterprise_id: number;
    public encrypt_data: string;

    /** 图片类型
     * 1. 大作大图；2.素材大图
     */
    public get imgType ():number {
        if ([53,111,112,121,122].includes(this.origin_type)){
            return 2
        }else{
            return 1
        }
    }
    /**
     * 是否是素材
     */
    public get isMaterial ():boolean {
        return [53,111,112,121,122].includes(this.origin_type)
    }
    /**
     * 是否是透明背景图
     */
    public get isTransparent ():boolean {
        return !![112,122].includes(this.origin_type)
    }
    /**
     * 放大倍数
     * */
    public get scale ():number {
        if(!this.isTransparent){
            return 2
        }else {
            return 4
        }
    }

    constructor (options:any, manager?:any) {
        this.from = (typeof options.from === 'number')? options.from : 0
        this.scaleHeight = options.scaleWidth && options.scaleWidth * options.height / options.width || 0 ;
        this.options = options || {} ;
        this.choose = options.choose ;
        /**
         * @description 是否有cdn地址
         */
        this.cdnInfo = options.cdnInfo || {} ;
        /**
         * @description 是否使用cdn流程
         */
        this.isCdn = options.cdnInfo ? options.cdnInfo.bcdnurl : `` ;
        /**
         * @description 图片标签信息
         * @type {*|Array|NodeListOf<HTMLLabelElement>}
         */
        this.labels = options.labels || `` ;
        /**
         * @description图片存放在哪个图片集
         * @type {*|null}
         */
        this.board_id = options.board_id || null ;

        if ( options && options.owner ) {
            options.owner.avatar = options.owner.avatar ;
        }
        /**
         * @description图片上传者信息
         * @type {*|null}
         */
        this.owner = options.owner || options.imgOwner || null ;
        /**
         * @description 图片ID（搜索引擎或Metasearch的ID）
         * @type {null}
         */
        this.id = options.id || null ;
        /**
         * @description 图片uuID（跳转AI大作）
         * @type {null}
         */
        this.uuid = options.uuid || null ;
        /**
         * @description 图片在图片集中的ID
         */
        this.img_id = options.img_id || null ;
        /**
         * @description 大图页相关图片来源
         * @type {string}
         */
        this.img_origin = options.img_origin || `` ;
        /**
         * @description 大图页相关推荐图片算法版本
         * @type {string}
         */
        this.ver = options.ver || `` ;
        /**
         * @description 所属搜索结果管理器
         * @type {Object}
         */
        this.manager = manager ;
        /**
         * @description 作者
         * @type {string}
         */
        this.author = options.author || `` ;
        /**
         * @description 作者主页
         */
        this.authorUrl = options.authorUrl || `` ;
        /**
         * @description 大图
         * @type {*|string}
         */
        this.bUrl = options.bUrl ? options.bUrl : `` ;
        /**
         * @description 版权信息(旧的，全球聚合还在用)
         * @type {Copyright|null}
         */
        this.copyright = new Copyright ( options.copyright ) ;
        /**
         * @description 版权信息(新的，给部份网站跟大兔使用)
         * @type {Copyright|null}
         */
        this.copyrightInfo = options.copyright_info ;
        /** 新版copyright,给有copyright_id的对象使用 */
        this.copyrightV2 = new CopyrightV2 ( options.copyright_id ) ;
        /**
         * @description 选择的收藏组图索引
         * @type {*|string}
         */
        this.thumbListIndex = [] ;
        /**
         * @description 图片标题
         */
        this.title = trim ( options.title || options.name || `` ) ;
        /**
         * @description 图片标题（翻译）
         */
        this.title_cn = trim ( options.title2 || options.title_cn || options.img_name2 || `` ) ;
        /**
         * @description 图片描述
         */
        this.description = trim ( options.desc || options.descripton || `` ) ;
        /**
         * @description 图片描述（翻译）
         * @type {string}
         */
        this.description_cn = trim ( options.desc2 || options.descripton2 || options.descripton_cn || options.desc_cn || options.img_info2 || `` ) ;

        /**
         * @description 原图片下载地址
         */
        this.detailsUrl = options.detailsUrl || `` ;
        /**
         * @description 下载地址（可直接下载的链接）
         * @type {*|null}
         */
        this.downloadUrl = `` ;
        /**
         * @description 搜索结果高度
         * @type {number}
         */
        this.h = options.h || options.height || 0 ;

        /**
         * @description 所属行业
         * @type {number}
         */
        this.industry = options.industry || `` ;

        /**
         * @description 搜索结果宽度
         * @type {*|number}
         */
        this.w = options.w || options.width || 0 ;
        /**
         * @description 显示宽度
         * @type {*|number}
         */
        this.dw = this.w || 0 ;
        /**
         * @description 显示高度
         * @type {*|number}
         */
        this.dh = this.h || 0 ;
        /**
         * @description 来源网页
         * @type {*|string}
         */
        this.pageUrl = options.pageUrl || options.page || `` ;
        /**
         * @description 搜索结果列表页图片
         * @type {*|string}
         */
        this.url = options.url || options.other_url || `` ;
        /**
         * @description 搜索结果站点信息
         * @type {{}| Site}
         */
        this.siteInfo = {} as Site ;
        /**
         * @description 图片评论
         * @type {*|null}
         */
        this.remarks = options.remarks || null ;
        /**
         * @description 搜索结果站点domain
         * @type {string|null}
         */
        this.domain = options.domain ;
        /**
         * @description 搜索结果站点id
         * @type {number|null}
         */
        this.siteId = options.siteId || options.site_id ;
        /**
         * @description 是否重要图片
         * @type {*|like|Board.like|number}
         */
        this.like = options.like || 0 ;
        /**
         * 根据网站优先值计算出的排序
         * @type {number}
         */
        this.order = options.order || 0 ;
        /**
         * 显示的顺序
         * @type {number}
         */
        this.displayOrder = null ;
        /**
         * @description 组图原图列表
         * @type {Array}
         */
        this.groupImages = [] ;
        /**
         * @description 组图缩略图列表
         * @type {Array}
         */
        this.thumbImages = [] ;
        /**
         * @description 组图下载列表
         * @type {Array}
         */
        this.downloadImages = [] ;
        /**
         * @description 图片是否已加载
         * @type {boolean}
         */
        this.imgloaded = false ;
        // 区分是否有相似图片集
        this.is_board = typeof options.is_board === 'number' ? options.is_board : 0 ;
        // 相似board集合
        this.related_board = options.related_board || [] ;
        /**
         * @description 图片加速服务器
         * @type {string}
         */
        this.proxy = options.proxy;
        /**
         * @description 国内图片加速服务器
         * @type {string}
         */
        this.domesticServer = options.domesticServer;
        /**
         * @description 是否是扩大图
         * @type {boolean}
         */
        this.expandimage = options.expandimage || false;
        /**
         * @description 是否从MetaSearch迁移过来的数据(即将弃用)
         * @return {*|string}
         */
        this.isMetaSearch = function () {
            return this.meta || this.options.other_url || this.options.siteId ;
        } ;
        /**
         * @description 是否智能聚合数据(即将弃用)
         * @type {boolean}
         */
        this.meta = typeof options.meta === "boolean" ? options.meta : false ;
        /**
         * @description 是否已翻译(搜索引擎默认为已翻译)
         * @type {boolean}
         */
        this.translated = !this.meta ? ( !!( this.description_cn || this.title_cn ) ) : false ;
        /**
         * @description 搜索引擎中记录的metasearch中的url
         * @type {*|string}
         */
        this.other_url = options.other_url || `` ;
        /**
         * @description 图片类型（0-用户上传 , 1-P网 , 2-爬虫 ,3-huaban , 4-ins ,5-behance, 13-采集器 , 14-聚合收藏 , 15-大作收藏, 50-pinlite ）
         */
        this.origin_type = options.origin_type === null ? options.origin_type : options.origin_type ;
        /**
         * @description 搜索结果类型（"search","meta","metaToSearch","pinterest"）
         */
        // this.type = options.type || (options.meta && "meta") || ((this.options.other_url || this.options.siteId) && "metaToSearch") || "search";
        this.type = options.type || ( options.meta && `meta` ) || ( options.origin_type == 14 && `metaToSearch` ) || `search` ;
        /**
         * @description 搜索类型 一个字符串 测试环境使用
         * @type {string}
         */
        this.tag = isDev() ? options.tag : `` ;
        /** 名称 */
        this.name = options.name||"";
        /** 背景颜色 */
        this.color = options.dominantColor||"";
        /**
         * @description 是否是中文标题
         * @type {Boolean}
         */
        this.isChinese = !!( this.title.match ( /([\u4e00-\u9fa5])+/ ) || this.description.match ( /([\u4e00-\u9fa5])+/ ) ) ;

        this.isSameTitleDesc = this.isSame () ;

        this.collaborateMemeberInfo = options.collaborateMemeberInfo || {
            is_member: 0
        }

        this.collaborateImgVotePojo =
            options.collaborateImgVotePojo ||
            {
                comment_count: 0,
                dislike_count: 0,
                id: -1,
                like_count: 0,
                think_count: 0,
                vote_status: 0,
            };

        this.pin_industry = options.pin_industry || '';
        this.child_board_uuid = options.child_board_uuid || '';
        this.child_board_id = options.child_board_id || '';
        this.enterprise_id = options.enterprise_id || '';
        /**
         * @description 图片格式（4是gif）
         */
        this.img_format = options.img_format || -1;
        // AI大作参考图加密地址
        this.encrypt_data = options.encrypt_data;
    }
    /** 前100个字符相同视为相同 **/
    isSame() {
        let temp1, temp2 ;
        if ( this.translated ) {
            temp1 = this.title_cn.substring ( 0, 100 ) ;
            temp2 = this.description_cn.substring ( 0, 100 ) ;
        } else {
            temp1 = this.title.substring ( 0, 100 ) ;
            temp2 = this.description.substring ( 0, 100 ) ;
        }
        return temp1 === temp2 ;
    }

    /**
     * @description 转换为下载对象
     */
    toDownloadData( month_download_board: string ) {
        let _result = {} as any  ;
        _result.pageUrl = this.pageUrl ;
        _result.siteId = this.siteInfo.id ;
        _result.siteName = this.siteInfo.domain ;
        _result.descripton = this.description ;
        _result.width = this.w ;
        _result.height = this.h ;
        _result.handlerState = 1 ;
        _result.single = this.siteInfo.isMultiImg ;
        _result.fromLib = 1 ;
        _result.name = this.name ;
        _result.state = 0 ;
        _result.bUrl = this.bUrl ;
        _result.detailsUrl = this.detailsUrl ;
        _result.author = this.author ;
        _result.authorUrl = this.authorUrl ;
        _result.boardId = month_download_board ;
        _result.firstLevClass = null ;
        _result.secondaryLevClass = null ;
        _result.thirdLevClass = null ;
        _result.copyright = this.copyright.copyrightText ;
        _result.url = this.url ;
        return _result ;
    }
    /**
     * @description 举报图片
     */
    async reportImageNew( options: {reportType: string,user_id: number} ) {
        console.log(options.user_id)
        return $axios ( {
            url: `${ServerBoardServer}/report/report`,
            method: `POST`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            data: qs.stringify({
                img_id: this.id,
                user_token: await getToken(),
                reportType: options.reportType || '',
                user_id:options.user_id || -1
            })
        } ).catch( e => { throw e; }) ;
    }
    /**
     * @description 转换为搜索引擎收藏下载
     * @see http://doc.bigbigwork.com/docs/develop/439
     * @param {number} [boardId] 画集ID(如果是0,表示是下载)
     * @param {number} [type] 类型(1收藏 2 下载)
     */
    async toBoard(boardId, type) {
        const _self = this;
        const _result:any = {};
        if (!this.downloadImages.length && !this.downloadUrl) {
            return getDownloadImageUrl(this, this.siteInfo.isMultiImg ? 0 : null).then( ()=>{
                return onload();
            });
        } else {
            return onload()
        }

        async function onload():Promise<any> {
            _result.user_token = await getToken();
            let bUrl = "";
            let groupImages = ''
            if (_self.bUrl.split(",").length > 1) {
                bUrl = _self.url;
            } else {
                bUrl = _self.bUrl;
                // pinlite 使用下载的大图
                // @ts-ignore
                if( _self.isPinlite && _self.detailsUrl ){
                    bUrl = _self.detailsUrl;
                }
            }

            if (_self.thumbListIndex.length === 0) {
                groupImages = await SearchProxy.getImgUrl((bUrl || _self.url), null, _self);
            } else if (_self.thumbListIndex.length > 0) {
                // _result.index = _self.thumbListIndex[0]
                //@ts-ignore
                _result.index = typeof(_self.multiImagesArrIndex)==='number'?_self.multiImagesArrIndex:-1
                _self.thumbListIndex.forEach( (e)=> {
                    groupImages += SearchProxy.getImgUrl(_self.thumbImages[e], null, _self) + ','
                })
                groupImages = groupImages.substring(0, groupImages.length - 1)
            }
            _result.other_url = SearchProxy.getImgUrl((bUrl || _self.url), null, _self);
            _result.type = type || 1;
            _result.col_groupImages = groupImages
            _result.img_name = _self.title;
            // @ts-ignore
            _result.img_info = _self.img_info;
            _result.img_source = _self.pageUrl;
            _result.img_width = _self.w;
            _result.img_height = _self.h;
            _result.owner_name = _self.author;
            _result.zutu = _self.siteInfo.isMultiImg ? _self.thumbImages.join(",") : null;
            //@ts-ignore
            _result.label = _self.labels?_self.labels.join('^&'):"";
            _result.site_id = _self.siteInfo.id || "";
            _result.board_id = (type === 2 || !boardId) ? 0 : boardId;
            if (_self.siteInfo.isMultiImg) {
                // 假如是组图，把选中的INDEX图片源地址，写入img_sourceurl，如果是封面，取得第一张
                _result.img_sourceurl = _self.groupImages[_self.thumbListIndex[0]];
                _result.download_url = _self.groupImages.join(",");
                return Promise.resolve(_result);
            } else {
                if(_self.detailsUrl){
                    _result.img_sourceurl = _self.detailsUrl;
                }else{
                    _result.img_sourceurl = "//@proxy@/download/" + _self.siteInfo.servletUrl + "?action=LARGE&page=" + encodeURIComponent(_self.pageUrl) + "&url=" + encodeURIComponent(_self.url) + "&w=" + _self.w + "&h=" + _self.h
                }
                _result.download_url = _self.detailsUrl || _self.bUrl || _self.url;
                return Promise.resolve(_result);
            }
        }
    }
    getCopyRight( type ):string {
        switch ( type ) {
            case "title" : return (this.title || this.options.origin_id || this.id) + "";
            case "author": return this.author || (this.pageUrl && new URL(this.pageUrl).hostname ) || "";
            case "copyright": return this.copyrightInfo ? this.copyrightInfo.show_name : "";
            case "des_url": return this.copyrightInfo ? this.copyrightInfo.des_url : '';
            case "show_name": return this.copyrightInfo ? this.copyrightInfo.show_name : '';
            case "show_icon": return this.copyrightInfo ? this.copyrightInfo.show_icon : '';
            case "authorUrl": return this.authorUrl ?? "";
            case "pageUrl": return this.pageUrl ?? "";
            default: return "";
        }
    }
    /** 获取版权文字 */
    getCopyrightText():string {
        // if ( !this.copyrightInfo ) return "";
        let title = this.getCopyRight("title");
        let author = this.getCopyRight("author");
        let copyright = this.getCopyRight("copyright");
        let info = `版权引用：“${title}” 由 ${author} 授权许可 ${copyright}`;
        return info;
    }
    /** 获取版权html */
    getCopyrightHtml(){
        // if ( !this.copyrightInfo ) return "";
        let title = this.getCopyRight("title");
        let author = this.getCopyRight("author");
        let copyright = this.getCopyRight("copyright");
        let des_url = this.getCopyRight("des_url");
        let show_icon = this.getCopyRight("show_icon");
        let authorUrl = this.getCopyRight("authorUrl");
        let pageUrl = this.getCopyRight("pageUrl");
        let html = `<p style="font-size: 12px;color:#aaa;">版权引用：<a href="${pageUrl}"><span style="font-size: 12px;color:black;">“${title}”</span> </a>由 <a href="${authorUrl}"><span style="font-size: 12px;color:black;">${author}</span></a> 授权许可 <span><a href="${des_url}" target="_blank" style="font-size: 12px;color:black;">${copyright}<img src="${toRelativeProtocol(show_icon)}" style="display: inline-block;height: 16px;width: auto;"></a></span></p>`
        return html;
    }
}

