/**
 * @description 版权
 * @param copyright
 * @constructor
 */
const Copyright = function ( copyright ) {
    this.copyrightText = `` ;
    this.copyright = copyright ;
    this.copyrightText = this.getCopyright () ;
    this.copyrightID = this.copyright ? parseInt ( this.copyright.split ( `,` )[0] ) : 23 ;
} ;
Copyright.prototype = {
    /**
     * @description 版权信息数组
     */
    copyrightInfo: [{ id: 10, text: `RM` }, { id: 20, text: `RF` }, { id: 21, text: `RM&RF` }, { id: 11, text: `No CC license` }, { id: 12, text: `CC BY-NC` }, { id: 13, text: `CC BY-NC-ND` }, { id: 14, text: `CC BY-NC-SA` }, { id: 15, text: `CC BY` }, { id: 16, text: `CC BY-ND` }, { id: 17, text: `CC BY-SA` }, { id: 18, text: `CC PD` }, { id: 19, text: `CC0` }, { id: 2, text: `从页面获取` }, { id: 3, text: `未知` }, { id: 23, text: `图片可能受版权保护` }],
    /**
     * @description 根据copyright取得版权文字
     * @returns {string}
     */
    getCopyright: function ( copyright ) {
        const _copyright = copyright || this.copyright ;
        if ( !_copyright ) return `图片可能受版权保护` ;
        // 是id的情况
        if ( _copyright.match ( /^(\d+,)+/ ) ) {
            const id = parseInt ( this.copyright.split ( `,` )[0] ) ;
            for ( var i in this.copyrightInfo ) {
                if ( id === this.copyrightInfo[i].id ) {
                    return this.copyrightInfo[i].text ;
                }
            }
            return `图片可能受版权保护` ;
        } else {
            // 已经是文字
            return _copyright ;
        }
    }
} ;
/** 定义 **/
interface Copyright {
    copyrightText: string,
    copyright: any,
    copyrightID: number,
    copyrightInfo: Array<{ id: number, text: string }>,
    getCopyright: ( copyright: string | null ) => string
}
export default Copyright ;
