import { Module, Store } from "vuex" ;
import axios from "axios" ;
import {ServerBoardServer, ServerPService} from "@constants/servers" ;
import { convertParamsToFormData, getToken } from "@scripts/tools" ;
import { BigBigWork } from "@scripts/BigBigWork" ;

interface MessageState {
    /** 是否在加载中 */
    loading: boolean,
    id:number,
    /** banner广告 */
    content:string,
    loginDays:string,
    openUrl:string,
    reserve4:string,
    /** vip购买提示 */
    vipTips:string,
    /** 跟屏vip提示 **/
    reserve1:string,
    zhuCeDays:string,
    bottomBannerHidden:boolean,
    /* banner是否在顶部 */
    isPosition:string,
    init: boolean,
    todoFn: Array<any>
}

export const Message:Module<MessageState, any> = {
    namespaced: true,
    state: {
        loading: false,
        id: -1,
        content: ``,
        loginDays: ``,
        openUrl: ``,
        reserve4: ``,
        vipTips: ``,
        reserve1: ``,
        zhuCeDays: ``,
        // content: `<span style="color:#FFFFFF;">88狂欢返场！限时立减</span><span style="color:#fcff00;font-size:24px;">300</span><span style="color:#FFFFFF;">元，VIP低至</span><span style="color:#fcff00;font-size:24px;">138</span><span style="color:#FFFFFF;">元！限时抢购！</span><span style="color:#FFFFFF;"><countdown></countdown></span>`,
        // id: 28,
        // loginDays: `5-7`,
        // openUrl: ``,
        // reserve4: `https://xcx.bigbigwork.com/p/images/banner-v-2.png`,
        // // userType: 5,
        // vipTips: `加送2个月`,
        // zhuCeDays: `1-$`,
        bottomBannerHidden: false,
        isPosition: 'top',
        todoFn: [],
        init: false
    },
    mutations: {
        setMessage: ( state, val ) => {
            state.id = val.id ;
            state.content = val.content || '' ;
            state.loginDays = val.loginDays ;
            state.openUrl = val.openUrl ;
            state.reserve4 = val.reserve4 ;
            state.vipTips = val.vip_tips ;
            state.reserve1 = val.reserve1 ;
            state.zhuCeDays = val.zhuCeDays ;
        },
        setLoading: ( state: MessageState, val:boolean ) => {
            state.loading = val ;
        },
        setInit: (state: MessageState, val: boolean) => {
          state.init = val
        },
        seIisPosition: ( state: MessageState, val:string ) => {
            // @ts-ignore
            state.isPosition = val ;
        },
        setBottomHidden: ( state: MessageState, val:boolean ) => {
            state.bottomBannerHidden = val ;
        },
        updateTodoFn: ( state, payload: {add?: Boolean, del?: Boolean, event?: object} ) => {
            if ( payload.add ) {
                state.todoFn.push(payload.event)
            }else {
                state.todoFn = []
            }
        }
    },
    actions: {
        /** 获取系统消息 */
        FETCH_MESSAGE: async ( context ):Promise<void> => {
            if ( context.state.init ) {
                return Promise.resolve()
            }
            if ( !context.state.init && context.state.loading ) {
                return new Promise(resolve => {
                    context.commit('updateTodoFn', { add: true, event: resolve })
                })
            }
            context.commit ( `setLoading`, true ) ;
            const token = await getToken () ;
            const data = convertParamsToFormData ( {
                user_token: token
            } ) ;
            return axios ( {
                method: `POST`,
                url: ServerPService + `/notifi/initNew.htm`,
                data
            } ).then ( res => {
                console.log(66666666666666666666666666)
                if ( res.status === 200 ) {
                    context.commit ( `setMessage`, res.data ) ;
                }
            } ).finally ( () => {
                context.commit('setInit', true)
                context.commit ( `setLoading`, false ) ;
                if (context.state.todoFn.length) {
                    context.state.todoFn.forEach(fn => fn())
                    context.commit('updateTodoFn', { del: true })
                }
                BigBigWork.emit( `onChangeRightSidePos`, { ready: 1 } ) ;
            } ) ;
        }
    },
    getters: {
        show ( state ) {
            return state.id > -1 ;
        },
        bottomHeight ( state ) {
            if ( state.id > -1 && state.content.length && !state.bottomBannerHidden ) {
                return 80 ;
            } else {
                return 0 ;
            }
        },
        hasMassage ( state) {
            return !!state.content
        }
    }
} ;

export const CreateMessageStore = ( store:Store<unknown> ):void => {
    if ( store.hasModule ( `Message` ) ) return ;
    /** 在store上注册模块 */
    store.registerModule ( `Message`, Message ) ;
} ;
