import { Module, Store } from "vuex" ;
import { getAllBoard } from "@scripts/Board/BoardActions" ;
import { loadData, saveData } from "@scripts/LocalStorage" ;
import { ResultItem } from "@scripts/resultItem" ;

interface FavBoardState {
    /** 当前图片 */
    currentItem: ResultItem,
    /** 当前hover图片 */
    hoverItem: ResultItem,
    /** 正在收藏的图片 */
    favItem: ResultItem,
    /** 所有图片集列表 */
    boardList: Array<any>,
    /** 最近图片集列表 */
    resentBoardList: Array<any>,
    /** 最近收藏的图片集 */
    resentBoard: any,
    /** 最近收藏的图片 */
    collectionStationList: Array<ResultItem>,
    /** 最近收藏的图片集列表最大长度 */
    maxResentBoardLength: number,
    /** 是否是在加载中 */
    isLoading: boolean,
    /** 页面是否加载过所有board */
    boardReady: boolean
}

export const FavBoard: Module<FavBoardState, any> = {
    namespaced: true,
    state: {
        /** 当前图片 */
        currentItem: null,
        /** 当前hover图片 */
        hoverItem: null,
        /** 正在收藏的图片 */
        favItem: null,
        /** 所有图片集列表 */
        boardList: [],
        /** 最近图片集列表 */
        resentBoardList: [],
        /** 最近收藏的图片集 */
        resentBoard: null,
        /** 最近收藏的图片 */
        collectionStationList: [],
        /** 最近收藏的图片集列表最大长度 */
        maxResentBoardLength: 100,
        /** 是否是在加载中 */
        isLoading: false,
        /** 页面是否加载过所有board */
        boardReady: false
    },
    mutations: {
        /** 设置当前图片 */
        setCurrentItem ( state, payload:ResultItem ) {
            state.currentItem = payload ;
        },
        /** 设置当前hover图片 */
        setHoverItem ( state, payload:ResultItem ) {
            state.hoverItem = payload ;
        },
        /** 设置正在收藏的图片 */
        setFavItem ( state, payload:ResultItem ) {
            state.favItem = payload ;
        },
        /** 设置所有图片集列表 */
        setBoardList ( state, payload ) {
            state.boardList = payload ;
        },
        /** 设置最近图片集列表 */
        setResentBoardList ( state, payload ) {
            state.resentBoardList = payload ;
        },
        /** 设置最近图片集列表 */
        setResentBoard ( state, payload ) {
            if( payload ){
                state.resentBoard = payload ;
            }else{
                state.resentBoard = null ;
            }
        },
        /** 设置是否加载中 */
        setIsLoading ( state, payload:boolean ) {
            state.isLoading = payload ;
        },
        /** 设置页面是否加载过所有board */
        setBoardReady ( state, payload:boolean ) {
            state.boardReady = payload ;
        },
        /** 添加收藏的图片记录 */
        addFavItem ( state, payload:ResultItem ) {
            const max = 50 ;
            if ( state.collectionStationList.length > 50 ) {
                state.collectionStationList = state.collectionStationList.slice ( 0, max ) ;
            }
            state.collectionStationList.unshift ( payload ) ;
        }
    },
    actions: {
        /** 获取图片集列表
         * @param context Store上下文
         * @param opt { force:boolean, size:number}
         */
        async fetchBoardList ( context, opt?:{ force:boolean, size:number } ) {
            if ( !context.rootGetters[`UserInfo/userReady`] ) return ;
            const { force = false, size= 0 } = opt ?? {} ;
            let boardList ;
            if ( !force ) {
                boardList = loadData ( `boardList`, context.rootState.UserInfo.id ) ;
            }
            if ( !boardList ) {
                boardList = await getAllBoard (opt) ;
                saveData ( `boardList`, context.rootState.UserInfo.id, boardList, { expires: 10, unit: `minute` } ) ;
                context.commit ( `setBoardReady`, true ) ;
            }
            context.commit ( `setBoardList`, boardList ) ;
        },
        /** 获取图片集列表
         * @param context Store上下文
         * @param opt { force:boolean, size:number}
         */
        async fetchResentBoardList ( context, opt?:{ force:boolean, size:number } ) {
            if ( !context.rootGetters[`UserInfo/userReady`] ) return ;
            const { force = false, size= 10 } = opt ?? {} ;
            let boardList, resentBoardList ;
            if ( !force ) {
                // boardList = loadData ( `boardList`, context.rootState.UserInfo.id ) ;
                resentBoardList = loadData ( `resentBoardList`, context.rootState.UserInfo.id ) ;
            }
            if ( !resentBoardList ) {
                resentBoardList = await getAllBoard ( { force, size } ) ;
                saveData ( `resentBoardList`, context.rootState.UserInfo.id, resentBoardList, { expires: 10, unit: `minute` } ) ;
            }
            context.commit ( `setResentBoardList`, resentBoardList ) ;
            await context.dispatch (`saveResentBoard`, resentBoardList[0] ?? null ) ;
        },
        /** 保存最近收藏的board */
        saveResentBoard ( context, board ) {
            if ( !context.rootGetters[`UserInfo/userReady`] || !board ) return ;
            context.commit ( `setResentBoard`, board ) ;
            saveData ( `resentBoard`, context.rootState.UserInfo.id, board ) ;
            // 读取最近收藏的board列表
            let resentBoardList = loadData ( `resentBoardList`, context.rootState.UserInfo.id ) ;
            if ( !resentBoardList ) {
                resentBoardList = [] ;
            }
            // 并删除重复的board
            const index = resentBoardList.findIndex ( val => val.id === board.id ) ;
            if ( index > -1 ) {
                resentBoardList.splice ( index, 1 ) ;
            }
            // 在头部插入最近的board
            resentBoardList.unshift ( board ) ;
            if ( resentBoardList.length > context.state.maxResentBoardLength ) {
                resentBoardList = resentBoardList.slice ( 0, context.state.maxResentBoardLength ) ;
            }
            // 保存最近收藏的board列表
            context.commit ( `setResentBoardList`, resentBoardList ) ;
            saveData ( `resentBoardList`, context.rootState.UserInfo.id, resentBoardList ) ;
        },
        /** 获取最近收藏的board */
        loadResentBoard ( context ) {
            if ( !context.rootGetters[`UserInfo/userReady`] ) return ;
            const board = loadData ( `resentBoard`, context.rootState.UserInfo.id ) ;
            if ( board ) {
                context.commit ( `setResentBoard`, board ) ;
            } else {
                context.commit ( `setResentBoard`, null ) ;
            }
        }
    },
    getters: {
        needShowFastFav ( state ) {
            return !!state.resentBoard ;
        }
    }
} ;
export const CreateFavBoard = ( store ) => {
    if ( store.hasModule ( `FavBoard` ) ) return ;
    /** 在store上注册模块 */
    store.registerModule ( `FavBoard`, FavBoard ) ;
} ;
