/* eslint-disable  camelcase */
import { Module } from "vuex" ;
import Cookies from "js-cookie" ;
import { $axios } from "@scripts/Axios" ;
import { ServerPService } from "@constants/servers" ;
import { cookieGet, cookieSet } from "@scripts/CrossDomain" ;
import { getDomain, getToken } from "@scripts/tools" ;
import { loadData, saveData } from "@scripts/LocalStorage" ;

interface UserDownloadState {
        /** 每日可下载图片数 */
        downloadPerDay: number,
        /** 今日下载数 */
        todayDownload:number,
        /** 今日是否限制下载他人非福利图片 */
        canDownload:boolean,
        /** 今日是否限制下载我的图片或福利图片 */
        canDownloadMine:boolean
}
export const UserDownload:Module<UserDownloadState, any> = {
    namespaced: true,
    state: {
        downloadPerDay: 1,
        todayDownload: -1,
        canDownload: false,
        canDownloadMine: false
    },
    mutations: {
        setDownloadInfo: ( state, val:any ) => {
            state.downloadPerDay = val.download_per_day ;
            state.todayDownload = val.today_can_download ;
        },
        setDownload: ( state, val ) => {
            state.todayDownload = val ;
        }
    },
    actions: {
        /** 获取今日可下载图片数 */
        FETCH_CAN_DOWNLOAD: async ( context ):Promise<number> => {
            // vip 用户不用获取下载数
            if ( context.rootGetters[`UserInfo/isVip`] ) return Promise.resolve ( 0 ) ;
            // 首先从cookie中获取今日可下载数
            const cookieCanDownload = Cookies.get ( `TodayDownload` ) ;
            // cookie中没有则从接口获取
            if ( !cookieCanDownload ) {
                const token:string = await getToken () ;
                // 刷新可下载图片数量
                return $axios ( {
                    method: `GET`,
                    url: ServerPService + `/user/download`,
                    params: {
                        token
                    }
                } ).then ( res => {
                    context.commit ( `setDownload`, res.data ) ;
                    // 距零点相差的时间戳
                    const todyTime = new Date () ; // 当前时间戳
                    const hours = todyTime.getHours () + ( todyTime.getMinutes () ) / 60 ;
                    const useTime = ( 24 - hours ) / 24 ;
                    Cookies.set ( `TodayDownload`, res.data, { expires: useTime, domain: getDomain ( window.location.hostname ) } ) ;
                    return res.data ;
                } ).catch ( e => {
                    throw e ;
                } ) ;
            } else {
                context.commit ( `setDownload`, parseInt ( cookieCanDownload ) ) ;
            }
        },
        /** 新接口 获取今日可下载图片数 */
        FETCH_CAN_NEW_DOWNLOAD: async ( context, domain ) => {
            // vip 用户不用获取下载数
            if ( context.rootGetters[`UserInfo/isVip`] ) return Promise.resolve ( 0 ) ;
            // 从localStorage 中获取是否可下载
            // 判断是否加入了企业微信
            const qiyewx = context.rootState.UserInfo.wx.exclusive_service_id;
            const limitDownload = loadData ( `limitDownload`, context.rootState.UserInfo.id, false ) ;
            const limitDownloadMine = loadData ( `limitDownloadMine`, context.rootState.UserInfo.id, false ) ;
            // 是否是收藏的图片
            const isOwner1 = Number ( domain.owner?.id ) === context.rootState.UserInfo.id ;
            const isOwner2 = Number ( domain.authorId ) === context.rootState.UserInfo.id ;
            // 是否是福利图片
            const isOwnerPublic = Number ( domain.owner?.is_public ) === 2 ;
            const isPublic = Number ( context.rootState.BoardDetailData?.isPublic ) === 2 ;
            // 是否算在50张福利图片
            const isFree = isOwner1 || isOwner2 || isOwnerPublic || isPublic ;
            if( limitDownload && !qiyewx ) {
                return Promise.resolve ( [false, 1] ) ;
            }else if(limitDownload && qiyewx) {
                return Promise.resolve ( [true, 10] ) ;
            }else{
                return Promise.resolve ( [true, 1] ) ;
            }
            /* if ( ( !limitDownload && !isFree ) || ( !limitDownloadMine && isFree ) ) {
                // 可以
                if ( isFree ) {
                    return Promise.resolve ( [true, 50] ) ;
                } else {
                    return Promise.resolve ( [true, 1] ) ;
                }
            } else {
                // 不可以
                if ( isFree ) {
                    return Promise.resolve ( [false, 50] ) ;
                } else {
                    return Promise.resolve ( [false, 1] ) ;
                }
            } */
        },
        /** 添加一次下载数 */
        FETCH_ADD_TODAY_DOWNLOAD: async ( context, domain = `.bigbigwork.com` ) => {
            const token:string = await getToken () ;
            const todyTime = new Date () ; // 当前时间戳
            const hours = todyTime.getHours () + ( todyTime.getMinutes () ) / 60 ;
            // 距零点相差的时间戳
            const useTime = ( 24 - hours ) / 24 ;
            const downloadNum = context.state.todayDownload + 1 ;
            Cookies.set ( `TodayDownload`, downloadNum.toString (), { expires: useTime, domain: getDomain ( window.location.hostname ) } ) ;
            // 添加下载
            return $axios ( {
                url: ServerPService + `/vip/addTodayDownLoadForES.htm`,
                params: {
                    user_token: token
                }
            } ).then ( res => {
                if ( res.status === 200 ) {
                    return res.data ;
                } else {
                    throw new Error ( JSON.stringify ( res ) ) ;
                }
            } ) ;
        },
    },
    getters: {
        /** 获取剩余下载次数 */
        restDownload: ( state ) => {
            return state.downloadPerDay - state.todayDownload ;
        },
        /** 是否可以下载大作图片 */
        canDownload: ( state, getters, rootState, rootGetters ) => {
            if ( rootState.UserInfo.vipDays > 0 ) return true ;
            return state.todayDownload < state.downloadPerDay ;
        },
        /** 是否可以下载Pinlite图片 */
        canPinDownload: ( state, getters, rootState, rootGetters ) => {
            if ( rootState.UserInfo.vipDays > 0 || rootState.UserInfo.pinliteDays > 0 ) return true ;
            return state.todayDownload < state.downloadPerDay ;
        }
    }
} ;
export const CreateUserDownload = ( store ) => {
    if ( store.hasModule ( `UserDownload` ) ) return ;
    /** 在store上注册模块 */
    store.registerModule ( `UserDownload`, UserDownload ) ;
} ;
