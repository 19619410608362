import { Guide, GuideOptions } from "@scripts/Guide/Guide" ;
import { GuideEvent } from "@scripts/Guide/GuideEvent" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import find from "lodash-es/find";
import { Store } from "vuex" ;
import { isDev } from "@scripts/utils";

/** 向导管理器(单例) */
export class GuideManager {
    constructor () {
        throw new TypeError ( `向导管理器不能被实例化` ) ;
    }

    /** store */
    public static store:Store<any> = null;

    /** 向导配置 */
    public static guideConfig: GuideOptions[] = [];

    /** 向导事件，触发事件后执行相应事件队列 */
    public static event: {[key:string]:GuideEvent} = {};

    public static init ( config: GuideOptions[], store:Store<any> ) {
        this.store = store ;
        this.guideConfig = config ;
        BigBigWork.addListener ( `_GUIDE_RegisterGuide`, ( guide ) => {
            this.registerGuide ( guide ) ;
        } ) ;
        BigBigWork.addListener ( `_GUIDE_RemoveGuide`, ( guideName ) => {
            this.removeGuide ( guideName ) ;
        } ) ;
        BigBigWork.addListener ( `_GUIDE_NextGuide`, ( guideEventName ) => {
            const guideEvent = this.getGuideEventByName ( guideEventName ) ;
            guideEvent && guideEvent.next () ;
        } ) ;
        // 初始化配置
        this.initFromConfig () ;
    }

    /** 使用配置文件初始化事件 */
    public static initFromConfig () {
        for ( const i in this.guideConfig ) {
            const _guide = new Guide ( { ...this.guideConfig[i], store: this.store } ) ;
            this.registerGuide ( _guide ) ;
        }
        BigBigWork.emit ( `GuideReady` ) ;
    }

    /** 注册一个向导 */
    public static registerGuide ( guide: Guide ) {
        if ( guide && guide.conditionEvent ) {
            // 如果向导所需的触发事件没有被初始化过，则注册该事件；
            if ( !this.event[guide.conditionEvent] ) {
                this.event[guide.conditionEvent] = new GuideEvent ( { name: guide.conditionEvent } ) ;
            }
            // 将向导放入向导事件
            this.event[guide.conditionEvent].add ( guide ) ;
        } else {
            if ( guide.triggerDom ) return ;
            isDev() && console.log ( `新建向导错误，缺少触发向导的事件` ) ;
        }
    }

    /** 移除一个已注册的向导
     * @param name 向导名称
     */
    public static removeGuide ( name:string ) {
        for ( const eventIndex in this.event ) {
            const GuideEvent = this.event[eventIndex] ;
            if ( GuideEvent.hasGuide ( name ) ) {
                GuideEvent.remove ( name ) ;
            }
        }
    }

    /** 更新一个已注册的向导
     * @param name
     * @param guide
     */
    public static updateGuide ( name:string, guide:Guide ) {
        let _guide = this.getGuideByName ( name ) ;
        if ( _guide ) {
            _guide = guide ;
            return _guide ;
        } else {
            isDev() && console.log ( `没有找到向导：` + name ) ;
        }
    }

    /** 已注册的向导中，是否有匹配名字的向导
     * @param name 向导名称
     */
    public static getGuideByName ( name ) {
        for ( const eventIndex in this.event ) {
            const _event = this.event[eventIndex] ;
            for ( const guideIndex in _event ) {
                if ( _event[guideIndex].name === name ) {
                    return _event[guideIndex] ;
                }
            }
        }
        return null ;
    }

    /** 根据向导事件名返回向导事件
     * @param name
     */
    public static getGuideEventByName ( name ) {
        const result = find ( this.event, function ( val ) {
            return val.name === name ;
        } ) ;
        return result ;
    }

    /** 静态方法，从LocalStorage中读取向导完成状态 */
    public static readGuideStatusFromCache ( userId:number ):GuideCache|null {
        try {
            const baseName = `guideStatus` ;
            const name = baseName + userId ;
            const data = localStorage.getItem ( name ) ;
            if ( !data ) return null ;
            else {
                const result:GuideCache = JSON.parse ( data ) ;
                return result ;
            }
        } catch ( e ) {
            return null ;
        }
    }

    /** 静态方法，向LocalStorage中写入向导完成状态 */
    public static writeGuideStatusToCache ( userId:number, data:GuideCache ):void{
        try {
            const baseName = `guideStatus` ;
            const name = baseName + userId ;
            const stringifyData = JSON.stringify ( data ) ;
            localStorage.setItem ( name, stringifyData ) ;
        } catch ( e ) {
            throw e ;
        }
    }

    /** 静态方法，根据向导名从本地获取向导状态 */
    public static readeSingleGuideFromCache ( userId:number, guideName:GuideNameType ):boolean {
        const data:GuideCache = GuideManager.readGuideStatusFromCache ( userId ) ;
        if ( !data ) return false ;
        return !!data[guideName] ;
    }

    /** 静态方法，将单个新手引导状态写入本地向导缓存 */
    public static writeSingleGuideToCache ( userId:number, guideName:GuideNameType, val: number ):void{
        // 没有完成的向导不记录
        if ( !val ) { return ; }
        // 从缓存读取后再写入回去
        const data = GuideManager.readGuideStatusFromCache ( userId ) ;
        data[guideName] = val ;
        GuideManager.writeGuideStatusToCache ( userId, data ) ;
    }
}

/** 新手引导名 */
export type GuideNameType =
    `pinterest` | `behance` | `artstation` | `realtime_search`
    | `field` | `search_inspiration` | `success_favourite_img` | `firsttime_favourite_img` | `similar_img_search` | `similar_img_frame` | `switch_board_search` | `subscribe_board` | `active_collaborate`;

/** 新手引导缓存类型 */
export type GuideCache = {
    [ name in GuideNameType ]:number
}
