import Vue, { VNode, VueConstructor } from "vue" ;
import { Route } from "vue-router" ;
import { CacheHistory, CacheHistoryState } from "@scripts/CacheHistory" ;
import qs from 'querystring' ;

type VNodeCache = { [key: string]: VNode };

/**
 * Get the raw type string of a value, e.g., [object Object].
 */
const _toString = Object.prototype.toString ;

export function isRegExp ( v: any ): boolean {
    return _toString.call ( v ) === `[object RegExp]` ;
}

export function isDef ( v: any ): boolean {
    return v !== undefined && v !== null ;
}
export function isAsyncPlaceholder ( node: VNode ): boolean {
    // @ts-ignore
    return node.isComment && node.asyncFactory ;
}
export function getFirstComponentChild ( children: Array<VNode> ): VNode {
    if ( Array.isArray ( children ) ) {
        for ( let i = 0 ; i < children.length ; i++ ) {
            const c = children[i] ;
            if ( isDef ( c ) && ( isDef ( c.componentOptions ) || isAsyncPlaceholder ( c ) ) ) {
                return c ;
            }
        }
    }
}
/**
 * Remove an item from an array.
 */
export function remove ( arr: Array<any>, item: any ): Array<any> | void {
    if ( arr.length ) {
        const index = arr.indexOf ( item ) ;
        if ( index > -1 ) {
            return arr.splice ( index, 1 ) ;
        }
    }
}
function getComponentName ( opts ): string {
    return opts && ( opts.Ctor.options.name || opts.tag ) ;
}

function matches ( pattern: string | RegExp | Array<string>, name: string ): boolean {
    if ( Array.isArray ( pattern ) ) {
        return pattern.indexOf ( name ) > -1 ;
    } else if ( typeof pattern === `string` ) {
        return pattern.split ( `,` ).indexOf ( name ) > -1 ;
    } else if ( isRegExp ( pattern ) ) {
        return pattern.test ( name ) ;
    }
    /* istanbul ignore next */
    return false ;
}

function pruneCache ( keepAliveInstance: any, filter: Function ) {
    const { cache, keys, _vnode } = keepAliveInstance ;
    for ( const key in cache ) {
        const cachedNode: VNode = cache[key] ;
        if ( cachedNode ) {
            const name: string = getComponentName ( cachedNode.componentOptions ) ;
            if ( name && !filter ( name ) ) {
                pruneCacheEntry ( cache, key, keys, _vnode ) ;
            }
        }
    }
}

function removeRouterCache ( node:any ) {
    // console.dir ( node ) ;
    node.$parent._routerViewCache = {} ;
}

function pruneCacheEntry (
    cache: VNodeCache,
    key: string,
    keys: Array<string>,
    current?: VNode
) {
    const cached = cache[key] ;
    if ( cached && ( !current || cached.tag !== current.tag ) ) {
        cached.componentInstance.$destroy () ;
    }
    cache[key] = null ;
    remove ( keys, key ) ;
}
const patternTypes: Array<Function> = [String, RegExp, Array] ;

interface KeepCacheOptions {
    scrollElmId: string
}
export const keepCacheIsSameUrl = ( to:Route, from:Route ) => {
    return ( to.path === from.path ) && ( qs.stringify ( to.query ) === qs.stringify ( from.query ) ) ;
} ;
export const keepCacheInit = ( options: Partial<KeepCacheOptions> = {} ) => {
    let lastKey = `` ;
    if ( process.env.VUE_ENV === `client` ) {
        Vue.mixin ( {
            async beforeRouteUpdate ( to, from, next ) {
                this.$emit ( `beforeRouteUpdate` ) ;
                if ( !lastKey ) {
                    lastKey = window.history.state.key ;
                }
                if ( !keepCacheIsSameUrl ( to, from ) ) {
                    await CacheHistory.saveData ( lastKey, this, from ) ;
                }
                await next () ;
                // 同组件update时，由于已经不是组件级复用，this指向上一个路由组件实例，所以要获取新的上下文vm
                // @ts-ignore
                const vm = to.matched[0].instances.default ;
                lastKey = window.history.state?.key ;
                if ( !keepCacheIsSameUrl ( to, from ) ) {
                    const [err, res] = await CacheHistory.restoreData ( lastKey, vm, to ) ;
                    if ( err ) { throw err ; }
                }
            },
            beforeRouteEnter ( to, from, next ) {
                try {
                    // lastKey = window.history.state.key;
                    next ( vm => {
                        // CacheHistory.push ( to ) ;
                        vm.$emit ( `beforeRouteEnter` ) ;
                        lastKey = window.history.state?.key ;
                        CacheHistory.restoreData ( window.history.state?.key, vm, to ).then ( result => {
                            const [err, res] = result;
                            if ( err ) { throw err };
                        }) ;
                    } ) ;
                } catch ( e ) {
                    throw e;
                }
            },
            async beforeRouteLeave ( to, from, next ) {
                this.$emit ( `beforeRouteLeave` ) ;
                if ( !lastKey ) {
                    lastKey = window.history.state?.key ;
                }
                await CacheHistory.saveData ( lastKey, this, from ) ;
                await next () ;
            }
        } ) ;
    }
} ;

/** keepCache缓存
 * @author xinlu.qiu
 * 大作keepCache组件, 修改了原来的keepAlive组件, 会忽略所有hash变化
 */
export const keepCache = {
    name: `keep-cache`,
    abstract: true,
    props: {
        include: patternTypes,
        exclude: patternTypes,
        max: [String, Number],
        /** 传入组件名称，符合的路由将使用最近的cache而不是去渲染,在没有cache的情况下才会去渲染, 传入的格式和Include、exclude相同 */
        staticNames: patternTypes
    },

    created () {
        this.cache = Object.create ( null ) ;
        this.keys = [] ;
    },

    destroyed () {
        for ( const key in this.cache ) {
            pruneCacheEntry ( this.cache, key, this.keys ) ;
        }
    },

    data () {
        return {
            // 上次的key,如过本次和上次的vnode.key相同，则忽略window.state.history.key
            lastKey: ``
        } ;
    },
    beforeMount () {
        CacheHistory.push ( this.$route ) ;
    },
    mounted () {
        this.$watch ( `include`, val => {
            pruneCache ( this, name => matches ( val, name ) ) ;
        } ) ;
        this.$watch ( `exclude`, val => {
            pruneCache ( this, name => !matches ( val, name ) ) ;
        } ) ;
        this.$watch ( `$route`, ( newVal, oldValue ) => {
            if ( newVal.fullPath !== oldValue.fullPath ) {
                CacheHistory.push ( newVal ) ;
            }
        } ) ;
    },
    render () {
        const slot = this.$slots.default ;
        const vnode: VNode = getFirstComponentChild ( slot ) ;
        const componentOptions = vnode && vnode.componentOptions ;
        if ( componentOptions ) {
            // check pattern
            const name: string = getComponentName ( componentOptions ) ;
            const { include, exclude, staticNames } = this ;
            if (
                // not included
                ( include && ( !name || !matches ( include, name ) ) ) ||
                // excluded
                ( exclude && name && matches ( exclude, name ) )
            ) {
                return vnode ;
            }
            // 设置cache key
            const { cache, keys } = this ;
            let key:string ;
            // 以window.history.state.key作为key, 如过本次和上次的vnode.key相同，则忽略本次的window.state.history.key
            if ( process.env.VUE_ENV === `client` ) {
                const vNodeKey = vnode.key ? ( typeof vnode.key === `string` ? vnode.key : vnode.key?.toString() ) : (this.key || this.$route.fullPath) ;
                if ( window.history.state?.key + vNodeKey !== this.lastKey ) {
                    key = window.history.state?.key + vNodeKey ;
                } else {
                    key = this.lastKey ;
                }
            } else {
                // @ts-ignore
                key = vnode.key == null ? componentOptions.Ctor.cid + ( componentOptions.tag ? `::${componentOptions.tag}` : `` ) : vnode.key + `` ;
            }
            this.lastKey = key ;
            // const key: string = vnode.key == null
            //     // same constructor may get registered as different local components
            //     // so cid alone is not enough (#3269)
            //     // @ts-ignore
            //     ? componentOptions.Ctor.cid + ( componentOptions.tag ? `::${componentOptions.tag}` : `` ) : vnode.key + `` ;
            if ( cache[key] ) {
                if ( keys.length && staticNames && ( name && matches ( staticNames, name ) ) ) {
                    remove ( keys, key ) ;
                    keys.push ( key ) ;
                    return cache[key] ;
                } else {
                    vnode.componentInstance = cache[key].componentInstance ;
                    // make current key freshest
                    remove ( keys, key ) ;
                    keys.push ( key ) ;
                }
            } else {
                // 当前stateKey是否属于刷新前的页面
                const isOld = process.env.VUE_ENV === `client` ? ( CacheHistory.find ( key )?.isOld || false ) : false ;
                // 如果路由到静态地址，则直接返回最新的页面
                if ( !isOld && keys.length && staticNames && ( name && matches ( staticNames, name ) ) ) {
                    const prevKey = keys[keys.length - 1] ;
                    cache[key] = cache[prevKey] ;
                    // make current key freshest
                    remove ( keys, key ) ;
                    keys.push ( key ) ;
                    return cache[key] ;
                } else {
                    cache[key] = vnode ;
                    keys.push ( key ) ;
                }
                // prune oldest entry
                if ( this.max && keys.length > parseInt ( this.max ) ) {
                    pruneCacheEntry ( cache, keys[0], keys, this._vnode ) ;
                }
            }

            vnode.data.keepAlive = true ;
        }
        return vnode || ( slot && slot[0] ) ;
    }
} ;
