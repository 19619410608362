/* eslint-disable  camelcase */
import { ResultItem } from "@scripts/resultItem" ;
export interface PinUser {
    link: string,
    fullName: string,
    domainUrl: string,
    imageMediumUrl: string,
    id: string,
    firstName: string,
    imageSmallUrl:string,
    username: string
}
export interface PinBoard {
    name: string,
    url: string
}
export interface pinImageInfo {
    width: number,
    url: string,
    height: 315
}
export type PinImageStyle = `600x315` | `236x` | `orig` | `736x` | `60x60` | `136x136` | `170x` | `564x` | `474x` ;

export class PinliteItem extends ResultItem {
    link:string;
    domain:string;
    pinner: PinUser;
    board: PinBoard;
    images: Record<PinImageStyle, pinImageInfo>
    page:string;
    isPinlite: boolean
    constructor ( options ) {
        super ( options ) ;
        this.isPinlite = true ;
        this.type = `meta` ;
        this.link = options.link ;
        this.domain = options.domain ;
        this.title = options.gridTitle || options.closeupUnifiedTitle || options.title ;
        this.description = options.desc || options.closeupDescription ;
        this.page = options.page || `` ;
        this.pinner = {
            link: options.pinner?.link,
            domainUrl: options.pinner?.domain_url,
            firstName: options.pinner?.first_name,
            fullName: options.pinner?.full_name,
            id: options.pinner?.id,
            imageMediumUrl: options.pinner?.image_medium_url,
            imageSmallUrl: options.pinner?.image_small_url,
            username: options.pinner?.username
        } ;
        this.board = {
            name: options.board?.name ?? ``,
            url: options.board?.url ?? ``
        } ;
        this.meta = true ;
        this.images = options.images || {} ;
        this.origin_type = 50 ;
        this.bUrl = this.images["736x"]?.url || this.images["474x"]?.url || this.bUrl;
    }
}

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
