import isFunction from "lodash-es/isFunction";
type PathCallback = ( url:string, context:object )=>string ;
export interface PathConfigItem {
    /** 页面正则 */
    exp: RegExp,
    /** 页面是否需要登录才能查看 */
    needAuth: Boolean,
    /** 没有通过登录验证需要跳转的页面 */
    authRedirect?: PathCallback | string,
    /** 是否需要缓存页面 */
    needCache: Boolean,
    /** 需要缓存的页面，由页面地址返回缓存名 */
    cacheName?: PathCallback | string
}
interface PathConfigResult {
    /** 页面正则 */
    exp: RegExp,
    /** 页面是否需要登录才能查看 */
    needAuth: Boolean,
    /** 没有通过登录验证需要跳转的页面 */
    authRedirect: string,
    /** 是否需要缓存页面 */
    needCache: Boolean,
    /** 需要缓存的页面，由页面地址返回缓存名 */
    cacheName: string
}
/** 路径默认设置 */
export interface PathConfigDefaultConfig {
    path404: string,
    path500: string,
    pathNotLogin: string
}
/** 路由设置 */
export class PathConfig {
    public static init = ( config:PathConfigItem[], defaultConfig?: PathConfigDefaultConfig ) => {
        PathConfig.config = config ;
        if ( defaultConfig ) {
            PathConfig.defaultConfig = defaultConfig ;
        }
        return PathConfig;
    }

    public static config:PathConfigItem[] = [];

    public static defaultConfig:PathConfigDefaultConfig = {
        path404: `/404`,
        path500: `/500`,
        pathNotLogin: `/index`
    }

    /** 根据url和上下文返回页面配置 */
    public static findPageConfig = ( url, context:object ):PathConfigResult | null => {
        for ( let i = 0 ; i < PathConfig.config.length ; i++ ) {
            const config = PathConfig.config[i] ;
            if ( url.match ( config.exp ) ) {
                const authRedirect = isFunction ( config.authRedirect ) ? config.authRedirect ( url, context ) : config.authRedirect ;
                const cacheName = isFunction ( config.cacheName ) ? config.cacheName ( url, context ) : config.cacheName ;
                const result:PathConfigResult = {
                    exp: config.exp,
                    needAuth: config.needAuth,
                    authRedirect: authRedirect || PathConfig.defaultConfig.pathNotLogin,
                    needCache: config.needCache,
                    cacheName: cacheName || url
                } ;
                return result ;
            }
        }
        return null ;
    }
}
