import Vuex, {Module, Store, StoreOptions} from 'vuex' ;
import Vue from "vue" ;
import Bowser from "bowser" ;

import ParsedResult = Bowser.Parser.ParsedResult;
import axios from "axios";
import {ServerBoardServer} from "@constants/servers";

Vue.use ( Vuex ) ;
export type systemState = {
    uaInfo: ParsedResult,
    imageSetPrefix: string,
    extVersion: string
}

export const System: Module<systemState, any> = {
    namespaced: true,
    state: {
        uaInfo: null,
        imageSetPrefix: null,
        // 采集器版本号
        extVersion: ''
    },
    mutations: {
        initUAInfo: function ( state, val ) {
            if ( process.env.VUE_ENV === `client` ) {
                state.uaInfo = Bowser.parse ( window.navigator.userAgent ) ;
            } else {
                state.uaInfo = null ;
                console.log ( `uaInfo只能在客户端中获取` ) ;
            }
        },
        checkImageSetPrefix: function ( state ) {
            const el = document.createElement ( `div` ) ;
            const prefixes = [``, `-webkit-`] ;
            for ( let i = 0 ; i < prefixes.length ; i++ ) {
                el.style.backgroundImage = `${prefixes[i]}image-set( url("./test.png") 1x )` ;
                if ( el.style.backgroundImage ) {
                    state.imageSetPrefix = prefixes[i] ;
                    break ;
                }
            }
        }
    },
    actions: {
        /** 获取采集器版本号 */
        FETCH_EXT_VER: async ( context, payload:{ isForce:boolean} = { isForce: false } ) => {
            try {
                const { isForce } = payload ;
                if ( context.state.extVersion && !isForce ) return context.state.extVersion ;
                const res = await axios ( {
                    method: `POST`,
                    url: `${ServerBoardServer}/ver_/getNewExtensionVersion`
                } ) ;
                if ( res.data.status === 200 && res.data ) {
                    context.state.extVersion = res.data.data.version;
                    return [null, res.data.data.version] ;
                } else {
                    return [res.data, null] ;
                }
            } catch ( e ) {
                return [e, null] ;
            }
        }
    }
} ;

export const CreateSystemStore = ( store:Store<unknown> ):void => {
    if ( store.hasModule ( `System` ) ) return ;
    /** 在store上注册模块 */
    store.registerModule ( `System`, System ) ;
} ;
