/* eslint-disable  camelcase */
import { Module, Store } from "vuex" ;
import axios from 'axios' ;
import { finishGuide, getGuide } from "@constants/servlet" ;
import { getToken } from '@scripts/tools' ;
import qs from 'qs' ;
import { GuideNameType, GuideManager, GuideCache } from "@scripts/Guide/GuideManager" ;

/** 用户引导完成情况 */
type UserGuide = GuideCache & { isInit: boolean }

export const UserGuide: Module<UserGuide, any> = {
    namespaced: true,
    state: {
        pinterest: 0, // pinterest来源页面
        behance: 0, // behance来源页面
        artstation: 0, // artstation来源页面
        realtime_search: 0, // 其他免费版权来源页面
        field: 0, // 感兴趣领域
        search_inspiration: 0, // 头部搜灵感
        success_favourite_img: 0, // 我的设计云
        firsttime_favourite_img: 0, // 大图页收藏按钮
        similar_img_search: 0, // 大图页相似图按钮
        similar_img_frame: 0, // 相似图选矿按钮
        switch_board_search: 0, // 图片集搜索
        subscribe_board: 0, // 图片集页面订阅
        active_collaborate: 0, // 图片集协作
        isInit: false
    },
    mutations: {
        updateGuide: ( state: UserGuide, payload: UserGuide ) => {
            state.pinterest = payload.pinterest ?? 0 ;
            state.behance = payload.behance ?? 0 ;
            state.artstation = payload.artstation ?? 0 ;
            state.realtime_search = payload.realtime_search ?? 0 ;
            state.field = payload.field ?? 0 ;
            state.search_inspiration = payload.search_inspiration ?? 0 ;
            state.success_favourite_img = payload.success_favourite_img ?? 0 ;
            state.firsttime_favourite_img = payload.firsttime_favourite_img ?? 0 ;
            state.similar_img_search = payload.similar_img_search ?? 0 ;
            state.similar_img_frame = payload.similar_img_frame ?? 0 ;
            state.switch_board_search = payload.switch_board_search ?? 0 ;
            state.subscribe_board = payload.subscribe_board ?? 0 ;
            state.active_collaborate = payload.active_collaborate ?? 0 ;
            state.isInit = true ;
        },
        updateSingleGuide: ( state: UserGuide, payload: { guideName: GuideNameType } ) => {
            state[payload.guideName] = 1 ;
        }
    },
    actions: {
        /** 获取引导状态 */
        FETCH_GUIDE: async ( context, payload:{forceFetch: Boolean} = { forceFetch: false } ) => {
            try {
                if ( context.state.isInit && !payload.forceFetch ) return context.state ;
                const cachedGuide = GuideManager.readGuideStatusFromCache ( context.rootState.UserInfo.id ) ;
                if ( cachedGuide && !payload.forceFetch ) {
                    context.commit ( `updateGuide`, cachedGuide ) ;
                    return Promise.resolve ( cachedGuide ) ;
                }
                const res = await axios ( {
                    url: getGuide,
                    params: { token: await getToken () }
                } ) ;
                if ( res.status === 200 ) {
                    context.commit ( `updateGuide`, res.data.data ) ;
                    // 缓存到本地
                    GuideManager.writeGuideStatusToCache ( context.rootState.UserInfo.id, res.data.data ) ;
                    return res.data ;
                }
            } catch ( e ) {
                throw e ;
            }
        },
        /** 完成引导 同步服务器 */
        FINISH_GUID: async ( context, payload: { guideName: GuideNameType } ) => {
            let obj = {}
            if( GuideManager.readGuideStatusFromCache ( context.rootState.UserInfo.id ) ) {
                obj = Object.assign(GuideManager.readGuideStatusFromCache ( context.rootState.UserInfo.id ),{token:await getToken ()})
            }else{
                const res = await axios ( {
                    url: getGuide,
                    params: { token: await getToken () }
                } ) ;
                if ( res.status === 200 ) {
                    context.commit ( `updateGuide`, res.data.data ) ;
                    // 缓存到本地
                    GuideManager.writeGuideStatusToCache ( context.rootState.UserInfo.id, res.data.data ) ;
                    obj = Object.assign(GuideManager.readGuideStatusFromCache ( context.rootState.UserInfo.id ), { token: await getToken () })
                }
            }
            obj[payload.guideName] = 1
            return axios ( {
                method: `POST`,
                url: finishGuide,
                headers: {
                    'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
                },
                data: qs.stringify ( obj )
            } ).then ( res => {
                if ( res.status === 200 ) {
                    context.commit ( `updateSingleGuide`, { guideName: payload.guideName } ) ;
                    // 缓存到本地
                    GuideManager.writeSingleGuideToCache ( context.rootState.UserInfo.id, payload.guideName, 1 ) ;
                }
            } ).catch ( e => {
                throw e ;
            } ) ;
        }
    }
} ;

export const CreateUserGuide = function ( store: Store<unknown> ) {
    if ( store.hasModule ( `UserGuide` ) ) return ;
    store.registerModule ( `UserGuide`, UserGuide ) ;
} ;
