import { Module, Store } from "vuex" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import { getTokenCrossDomain } from "@scripts/CrossDomain" ;
import Cookies from "js-cookie" ;
import axios from "axios" ;
import { ServerPService } from "@constants/servers" ;
import { getDomain } from "@scripts/tools" ;

interface SignState{
    /** token 验证签名 */
    sign: string,
    /** token 验证签名过期时间 */
    signExpires: number,
    /** 是否在获取签名 */
    signLoading:boolean,
    /** 上次验证的token */
    lastToken: string,
}
export const Sign:Module<SignState, any> = {
    namespaced: true,
    state: {
        sign: ``,
        lastToken: ``,
        signLoading: false,
        signExpires: -1
    },
    mutations: {
        setSign: ( state, data ) => {
            state.sign = data ;
        },
        setSignExpires: ( state, data ) => {
            state.signExpires = data ;
        },
        setSignLoading: ( state, data ) => {
            state.signLoading = data ;
        },
        setLastToken: ( state, data ) => {
            state.lastToken = data ;
        }
    },
    actions: {

        /** 获取token验证令牌
         * @param context
         * @param data (1)
         * @constructor
         */
        FETCH_SIGN: async ( context, data:number = 1 ):Promise<[Error, string]> => {
            try {
                if ( context.state.signLoading ) {
                    return new Promise ( ( resolve, reject ) => {
                        BigBigWork.once ( `ON_DZ_SIGN_SUCCESS`, ( sign:string ) => {
                            return resolve ( [null as Error, sign as string] ) ;
                        } ) ;
                        BigBigWork.once ( `ON_DZ_SIGN_FAILED`, ( e ) => {
                            return resolve ( [e, null as string] ) ;
                        } ) ;
                    } ) ;
                }
                context.commit ( `setSignLoading`, true ) ;
                const token = await getTokenCrossDomain () ;
                // 没有token的，失败
                if ( !token ) {
                    throw new Error ( `获取令牌失败，Token 不存在` ) ;
                }
                // 从cookie获取签名
                const cachedSign = Cookies.get ( `bbw-sign` )?.split( `||` ) ;
                if ( cachedSign && cachedSign[1] === token ) {
                    context.commit ( `setSign`, cachedSign[0] ) ;
                    context.commit ( `setSignLoading`, false ) ;
                    BigBigWork.emit ( `ON_DZ_SIGN_SUCCESS`, cachedSign[0] ) ;
                    return Promise.resolve ( [null, cachedSign[0]] ) ;
                }
                // cookie获取不到的情况下，发起验证请求
                const res = await axios ( {
                    method: `get`,
                    url: ServerPService + `/user/check`,
                    params: {
                        token: token,
                        type: data,
                        _t: new Date ().getTime ()
                    }
                } ) ;
                // 签名不成功的情况下，返回登录错误
                if ( res.status !== 200 ) {
                    return [new Error ( `LOGIN_ERROR` ), null] ;
                }
                context.commit ( `setLastToken`, token ) ;
                context.commit ( `setSign`, res.data ) ;
                Cookies.set ( `bbw-sign`, `${res.data}||${token}`, { domain: getDomain (), path: `/`, expires: 1 / 24 / 60 * 1 } ) ;
                BigBigWork.emit ( `ON_DZ_SIGN_SUCCESS`, res.data ) ;
                context.commit ( `setSignLoading`, false ) ;
                return [null as Error, res.data as string] ;
            } catch ( e ) {
                BigBigWork.emit ( `ON_DZ_SIGN_FAILED`, e ) ;
                context.commit ( `setSignLoading`, false ) ;
                return [e, null as string] ;
            }
        }
    }
} ;
export const CreateSign = ( store: Store<unknown> ) => {
    if ( store.hasModule ( `Sign` ) ) return ;
    store.registerModule ( `Sign`, Sign ) ;
} ;
