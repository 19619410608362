import LRU from "lru-cache" ;
import Vue from "vue" ;
import { Route, RawLocation } from "vue-router" ;
import isNumber from "lodash-es/isNumber" ;
import { LocalStorageRead, LocalStorageWrite } from "@scripts/LocalStorage" ;
import qs from "querystring" ;

const findScroller = ( node ) => {
    const find = ( vnode ) => {
        if ( vnode.$refs.scrollView ) {
            return vnode.$refs.scrollView ;
        } else {
            if ( vnode.$children.length ) {
                for ( let i = 0 ; i < vnode.$children.length ; i++ ) {
                    const scroller = find ( vnode.$children[i] ) ;
                    if ( scroller ) return scroller ;
                }
            } else {
                return null ;
            }
        }
    } ;
    return find ( node ) ;
} ;

export type CachePageDataType = {
    snapshot:any,
    scrollTop: number,
    [name:string]: any
}
/** 页面实例数据缓存 */
export const CachePageData:LRU<string, CachePageDataType> = new LRU ( {
    max: 20,
    maxAge: 1000 * 60 * 60 * 5
} ) ;

/** 页面历史记录状态 */
export type CacheHistoryState = {
    key:string,
    isOld:boolean,
    location?: string,
    current?:boolean,
    [name:string]: any
}

/**
 * @description
 * 钩子
 * init(to:Route)
 * afterRestoreData(to:Route)
 * beforeSaveData(from:Route)
 */

export class BBWHistory {
    /** 页面堆栈 */
    public list: CacheHistoryState[];
    /** 当前state */
    public currentState: {index:number, state:CacheHistoryState};
    /** 上次的历史记录长度 */
    public lastHistoryLength: number;
    /** 第一个页面的state.key */
    public startKey:string;
    /** 页面数据快照 */
    public pageData: LRU<string, CachePageDataType>;
    /** 静态VNODE */
    public staticVnode = null;
    /** 是否加载中 (从saveData到restoreData之间的时间) */
    public isLoading = false;
    /** 滚动元素 */
    public scrollElmId:string = ``;
    /** 滚动ActivityView */
    public scrollActivity:object;
    /** 版本 */
    public version: string = `1.0.1`;
    /** 是否自动删除缓存 */
    public autoDelCache: boolean = true;

    constructor ( options? ) {
        const historyArr = this.load () ;
        this.list = [] ;
        this.currentState = { index: -1, state: null } ;
        historyArr.forEach ( ( val, index ) => {
            if ( val.current && val.location === window.location.pathname + window.location.search ) {
                this.currentState = {
                    index,
                    state: val
                } ;
                val.isOld = false ;
            } else {
                val.isOld = true ;
            }
            this.list.push ( val ) ;
        } ) ;
        this.lastHistoryLength = window.history.length ;
        this.pageData = new LRU ( {
            max: 20,
            maxAge: Number.MAX_VALUE
        } ) ;
    }

    /** 按照key名查找state */
    find ( key:string ):CacheHistoryState {
        return this.list.find ( ( state ) => {
            return state.key === key ;
        } ) ;
    }

    /** 按照key名查找在页面栈中的的索引 */
    findIndex ( key:string ): number {
        return this.list.findIndex ( ( state ) => {
            return state.key === key ;
        } ) ;
    }

    /** 求取2个页面state.key的距离 */
    getDistance ( sourceStateKey:string, targetStateKey:string ): number {
        const sourceIndex = this.findIndex ( sourceStateKey ) ;
        const targetIndex = this.findIndex ( targetStateKey ) ;
        if ( !isNumber ( sourceIndex ) || !isNumber ( targetIndex ) ) {
            return null ;
        }
        return sourceIndex - targetIndex ;
    }

    /** 储存页面数据
     * @param key 当前state.key
     * @param vm 组件实例
     * @param from 来的路由
     */
    async saveData ( key:string, vm, from?:Route ) {
        try {
            if ( !key || !vm ) {
                console.warn ( `缺少key或者缺少组件实例，无法缓存` ) ;
                return ;
            }
            if ( vm._cacheing ) {
                console.log ( `页面没有初始化完毕，暂不缓存` ) ;
                return ;
            }
            let scrollTop ;
            vm.beforeSaveData && await vm.beforeSaveData ( from ) ;
            // 模拟滚动
            if ( this.scrollActivity ) {
                // @ts-ignore
                this.scrollActivity.freeze = true ;
                // @ts-ignore
                scrollTop = this.scrollActivity?.scroll?.y || 0 ;
            } else {
                const scrollDom = this.scrollElmId ? document.getElementById ( this.scrollElmId ) : window ;
                scrollTop = ( scrollDom as HTMLElement ).scrollTop || ( scrollDom as Window ).scrollY ;
            }
            const data:CachePageDataType = {
                snapshot: Object.assign ( {}, vm._data ),
                scrollTop: scrollTop
            } ;
            // @ts-ignore
            const dataKey = this.getKey ( key, from ) ;
            console.log ( "CacheHistory-saveData", dataKey, data ) ;
            this.pageData.set ( dataKey, data ) ;
        } catch ( e ) {
            console.log ( e ) ;
        }
    }

    /**
     * 获得键名
     * @param key
     * @param route
     */
    getKey ( key, route?: RawLocation ) {
        let location ;
        if ( typeof route === `string` ) {
            location = route ;
        } else {
            const query = Object.keys ( route.query ).length ? `?` + qs.stringify ( route.query ) : `` ;
            location = route ? route.path + query : window.location.pathname + window.location.search ;
        }
        const dataKey = `${key}:${location}` ;
        return dataKey ;
    }

    /**
     * 是否有缓存数据
     * @param key
     * @param to
     */
    hasData ( key, to?: RawLocation ) {
        const dataKey = this.getKey ( key, to ) ;
        console.log ( `CacheHistory-hasData`, dataKey ) ;
        return this.pageData.has ( dataKey ) ;
    }

    /**
     * 删除缓存数据
     * @param key
     * @param route
     */
    delData ( key, route?: RawLocation ) {
        const dataKey = this.getKey ( key, route ) ;
        console.log ( `CacheHistory-delData`, dataKey ) ;
        this.pageData.del ( dataKey ) ;
    }

    /** 恢复页面数据
     * @param key 当前state.key
     * @param vm 组件实例
     * @param to 将要进入的路由
     */
    async restoreData ( key:string, vm, to?:Route ) {
        try {
            let error = null ;
            if ( !key || !vm ) {
                return [new Error ( `缺少key或者缺少组件实例，无法缓存` ), null]  ;
            }
            const dataKey = this.getKey ( key, to ) ;
            const res = this.pageData.get ( dataKey ) ;
            console.log( "cacheHistory-restoreData", dataKey, res );
            if ( res && !res.snapshot._cacheing ) {
                // await this.scrollTop ( 0 ) ;
                console.log ( dataKey, res ) ;
                Object.assign ( vm, res.snapshot ) ;
                vm.afterRestoreData && await vm.afterRestoreData ( to ) ;
                window.setTimeout(async ( ) => {
                    await this.scrollTop ( res.scrollTop ) ;
                    if ( this.scrollActivity ) {
                        // @ts-ignore
                        this.scrollActivity.freeze = false ;
                    }
                    this.refreshMasonry ( vm ) ;
                })
            } else {
                vm._cacheing = true ;
                const handleEnd = () => {
                    vm._cacheError = false ;
                    window.setTimeout ( () => {
                        if ( this.scrollActivity ) {
                            // @ts-ignore
                            this.scrollActivity.freeze = false ;
                        }
                        this.refreshMasonry ( vm ) ;
                    } ) ;
                    vm._cacheing = false ;
                } ;
                if ( vm.init ) {
                    await this.scrollTop ( 0 ) ;
                    try {
                        await vm.init ( to ) ;
                        handleEnd () ;
                        vm._cacheError = false ;
                    } catch ( e ) {
                        vm._cacheError = true ;
                        error = e ;
                    }
                } else {
                    console.warn ( `页面组件需要init()函数初始化` ) ;
                }
                vm._cacheing = false ;
            }
            return [error, res] ;
        } catch ( e ) {
            return [e, null] ;
        }
    }

    refreshMasonry ( vm ) {
        if ( vm.refreshID !== undefined ) {
            window.setTimeout ( () => {
                vm.refreshID = new Date ().getTime () ;
                console.dir ( `refresh` ) ;
            }, 100 ) ;
        }
    }

    /** 滚动到指定高度 */
    scrollTop ( top ) {
        return new Promise ( ( resolve, reject ) => {
            if ( this.scrollActivity ) {
                window.setTimeout ( () => {
                    const scroller = findScroller ( this.scrollActivity ) ;
                    if ( scroller ) {
                        // @ts-ignore
                        scroller.refresh () ;
                        // @ts-ignore
                        scroller.scrollStop () ;
                        // @ts-ignore
                        scroller.scrollTo ( 0, top ) ;
                    }
                }, 100 ) ;
            } else {
                const scrollDom = this.scrollElmId ? document.getElementById ( this.scrollElmId ) : window ;
                if ( scrollDom === window ) {
                    window.scrollTo ( 0, top ) ;
                } else {
                    scrollDom.scrollTo ( 0, top ) ;
                }
            }
            resolve ( true ) ;
        } ) ;
    }

    /** 将页面state压入栈 */
    push ( newRoute:Route, handle?:( state:CacheHistoryState )=>CacheHistoryState) {
        const key = window.history.state.key ;
        const originState = { ...window.history.state } ;
        let state:CacheHistoryState = this.find ( key ) ;
        let isNewState = false;
        if ( !state ) {
            isNewState = true;
            state = {
                key: key,
                current: true,
                isOld: false,
                originState: originState,
                location: window.location.pathname + window.location.search
            } ;

            if ( this.currentState.index === -1 ) {
                this.startKey = key ;
            }
            if ( this.currentState.index !== -1 && this.currentState.index < this.list.length - 1 ) {
                // 如果是新压入栈的页面，并且当前页面位置不在队尾, 则删除当前位置以后的state
                const replaced = this.list.splice ( this.currentState.index + 1 ) ;
                if( this.autoDelCache ) {
                    replaced.forEach ( ( state ) => {
                        const key = state.key ;
                        const dataKey = `${key}:${state.location}` ;
                        this.pageData.del ( dataKey ) ;
                    } ) ;
                }
            }
            const newState = handle ? handle ( state ) : state ;
            this.list.push ( newState ) ;
        } else {
            const newLocation = window.location.pathname + window.location.search ;
            if ( state.location !== newLocation && state.key === key ) {
                // 如果state.key一样，但Location不一样，说明是replace
                console.dir ( `router replace` ) ;
                const replaced = this.list.pop () ;
                const dataKey = `${key}:${replaced.location}` ;
                this.pageData.del ( dataKey ) ;
                // 插入新的state
                state = {
                    key: key,
                    isOld: false,
                    current: true,
                    originState: originState,
                    location: window.location.pathname + window.location.search
                } ;
                const newState = handle ? handle ( state ) : state ;
                this.list.push ( newState ) ;
            }
        }
        state.isOld = false ;
        // 设置当前state
        this.currentState.index = this.findIndex ( key ) ;
        this.currentState.state = state ;
        this.lastHistoryLength = window.history.length ;
        this.list.forEach ( val => {
            if ( val.key === this.currentState.state.key ) {
                val.current = true ;
            } else {
                val.current = false ;
            }
        } ) ;
        this.save () ;
    }

    save ( ) {
        const res = {
            version: this.version,
            data: this.list
        } ;
        sessionStorage.setItem ( `HistoryState`, JSON.stringify ( res ) ) ;
    }

    load ( ) {
        try {
            const res = JSON.parse ( sessionStorage.getItem ( `HistoryState` ) ) ;
            if ( !res ) return [] ;
            if ( res?.version !== this.version ) {
                sessionStorage.removeItem ( `HistoryState` ) ;
                return [] ;
            }
            return res.data ;
        } catch ( e ) {
            return [] ;
        }
    }
}
export const CacheHistory: BBWHistory = process.env.VUE_ENV === `client` ? new BBWHistory () : null ;
