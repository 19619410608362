/* eslint-disable  camelcase */
import { Module, Store } from "vuex" ;
import { isDev } from "@scripts/utils";
import axios from 'axios' ;
import { ServerNoticeServer } from '@constants/servers' ;
import { getUnreadCount } from '@constants/servlet' ;
import Cookie from 'js-cookie' ;
import { getToken } from "@scripts/tools" ;

interface IntUnReadCount {
    cooperation: number,
    follow: number,
    notice: number,
    announcement: number,
    total: number
}

interface IntCommeMsg {
    status: number,
    content: string,
    id: string,
    show: boolean
}

interface IntNoticeMsg {
    avatar: string,
    content: string,
    link: string,
    link_font: string,
    datetime_str: string
}

interface IntNotification {
    isRun: boolean,
    unReadCount: IntUnReadCount,
    coopreationMsg: Array<IntCommeMsg>,
    notificationMsg: Array<IntCommeMsg>,
    noticeMsg: Array<IntNoticeMsg>,
    followMsg: Array<IntNoticeMsg>
}

export const Notification: Module<IntNotification, any> = {
    namespaced: true,
    state: {
        isRun: false,
        unReadCount: {
            cooperation: 0,
            follow: 0,
            notice: 0,
            announcement: 0,
            total: 0
        },
        // 协作
        coopreationMsg: [],
        // 关注
        followMsg: [],
        // 通知
        notificationMsg: [],
        // 公告
        noticeMsg: []
    },
    mutations: {
        updateUnReadCount: ( state: IntNotification, val: any ) => {
            state.unReadCount.cooperation = val.cooperation ;
            state.unReadCount.follow = val.follow ;
            state.unReadCount.notice = val.notice ;
            state.unReadCount.announcement = val.announcement ;
            state.unReadCount.total = val.total ;
        },
        updateIsRun: (state: IntNotification, val: any) => {
            state.isRun = val
        }
    },
    actions: {
        FETCH_UNREAD_COUNT: async ( context, payLoad = { isForce: false } ) => {
            const env = isDev()?'dev':'prod'
            const { isForce } = payLoad ;
            const updateUnreadTime = Cookie.get ( env + `updateUnreadTime` ) ;
            let cookieMsgUnReadCount = Cookie.get ( env + `msgUnReadCount` ) ;
            cookieMsgUnReadCount = cookieMsgUnReadCount && JSON.parse ( cookieMsgUnReadCount ) ;
            // 不强制刷新 拿cookie数据
            if ( !isForce && updateUnreadTime && cookieMsgUnReadCount ) {
                context.commit ( `updateUnReadCount`, cookieMsgUnReadCount ) ;
            }

            if ( !isForce && !!updateUnreadTime && cookieMsgUnReadCount ) {
                // cookie 有效期0.5小时
                return cookieMsgUnReadCount ;
            }
            return axios ( {
                url: getUnreadCount,
                params: {
                    user_token: await getToken ()
                }
            } ).then ( res => {
                const data = res.data ;
                if ( data.code === 200 ) {
                    const unReadCount = data.data ;
                    context.commit ( `updateUnReadCount`, unReadCount ) ;
                    // cookie 有效期0.5小时
                    if ( !isForce ) {
                        Cookie.set ( env + `updateUnreadTime`, ( new Date ().getTime () ).toString (), { expires: new Date ( new Date ().getTime () + 30 * 60 * 1000 ), domain: `.bigbigwork.com` } ) ;
                    }
                    Cookie.set ( env + `msgUnReadCount`, JSON.stringify ( unReadCount ), { expires: 1, domain: `.bigbigwork.com` } ) ;
                    return unReadCount ;
                }
            } ).catch ( r => {
                console.dir ( `检查FETCH_UNREAD_COUNT`, r ) ;
            } ) ;
        },
        RUN_FETCH_UNREAD: async ( context, payload ) => {
            if (context.state.isRun) return
            context.commit('updateIsRun', true)
            const timeInterval = isDev() ? 1000 * 20 : 1000 * 60 * 30 ;
            // 通知代码
            if ( await getToken () ) {
                context.dispatch('FETCH_UNREAD_COUNT')
                setInterval ( () => {
                    context.dispatch('FETCH_UNREAD_COUNT')
                }, timeInterval ) ;
            } else {
                let timer = null ;
                new Promise ( resolve => {
                    timer = setInterval ( async () => {
                        if ( await getToken()) {
                            clearInterval ( timer ) ;
                            resolve ( true ) ;
                        }
                    }, 200 ) ;
                } ).then ( res => {
                    context.dispatch('FETCH_UNREAD_COUNT')
                    setInterval ( () => {
                        context.dispatch('FETCH_UNREAD_COUNT')
                    }, timeInterval ) ;
                } ) ;
            }
        }
    },
    getters: {
        msgTotal: (state):number => {
            return state.unReadCount.total
        }
    }
} ;

export const CreatedNotification = function ( store: Store<unknown> ) {
    if (store.hasModule('Notification')) return
    store.registerModule('Notification', Notification)
}
