import axios from 'axios' ;
import { browserNotification } from '@constants/servlet' ;
import { getToken } from "@scripts/tools";
export default class BrowserNotification {
    private UserInfo: any;
    constructor ( props ) {
        this.UserInfo = props.UserInfo ;
    }

    run () {
        this.getMsg () ;
        setInterval ( () => {
            this.getMsg () ;
        //    5分钟检查一次
        }, 1000 * 60 * 5 ) ;
    }

    init () {
        console.log ( `Notifi-init` ) ;
        if ( `PushManager` in window && ( location.host.includes ( `www-test.bigbigwork.com` ) || location.host.includes ( `www.bigbigwork.com` ) )) {
            Notification.requestPermission ().then ( res => {
                if ( res === `granted` ) {
                    this.run () ;
                } else {

                }
            } ) ;
        } else {
            console.warn ( `Push messaging is not supported` ) ;
        }
    }

    async getMsg () {
        const storageKey = `notificationTime` ;
        const checkedTime = localStorage.getItem ( storageKey ) ;
        const curTime = new Date ().getTime () ;
        // 半小时轮训
        // @ts-ignore
        if ( !checkedTime || ( checkedTime && ( curTime - checkedTime ) / 1000 / 60 / 60 > 0.5 ) ) {
            localStorage.setItem ( storageKey, curTime.toString () ) ;
            axios ( {
                url: browserNotification,
                params: {
                    token: await getToken() || ``
                }
            } ).then ( res => {
                const data = res.data ;
                if ( data.code === 200 ) {
                    const { list } = data.data ;
                    for ( let i = 0 ; i < list.length ; i++ ) {
                        const item = list[i] ;
                        setTimeout ( () => {
                            const notification = new Notification ( item.title || `通知`, {
                                body: item.body || ``,
                                tag: ( new Date ().getTime () ).toString (),
                                icon: item.icon || ``,
                                data: item.data
                            } ) ;
                            notification.onshow = function ( event: any ) {
                                try {
                                    // eslint-disable-next-line no-undef
                                    _hmt.push ( [`_trackEvent`, `浏览器通知`, `显示提醒`] ) ;
                                    // eslint-disable-next-line no-undef
                                    _czc.push ( [`_trackEvent`, `浏览器通知`, `显示提醒`] ) ;
                                    // eslint-disable-next-line no-undef
                                    // _czc.push ( [`_trackEvent`, `浏览器通知`, `显示提醒`, event.target.data.defult_link] ) ;
                                }catch (e) {
                                    console.log(e)
                                }
                            } ;
                            notification.onclick = function ( event: any ) {
                                try {
                                    // eslint-disable-next-line no-undef
                                    _hmt.push ( [`_trackEvent`, `浏览器通知`, `点击提醒`] ) ;
                                    // eslint-disable-next-line no-undef
                                    _czc.push ( [`_trackEvent`, `浏览器通知`, `点击提醒`] ) ;
                                }catch (e) {
                                    console.log(e)
                                }
                                event.preventDefault () ; // prevent the browser from focusing the Notification's tab
                                window.open ( event.target.data.defult_link, `_blank` ) ;
                            } ;
                        }, i * 1000 ) ;
                    }
                }
            } ) ;
        }
    }
}
