/* eslint-disable  camelcase */
import { Module, Store } from "vuex" ;
import { getToken } from "@scripts/tools" ;
import { $axios } from "@scripts/Axios" ;
import { getUserField } from "@constants/servlet" ;

/** 领域 */
interface UserLikeField {
    id: number,
    name: string,
    url: string
}

/** 用户喜爱的领域列表 */
type UserLikeFieldList = Array<UserLikeField>

interface StoreUserLikeField {
    likeFieldList: UserLikeFieldList,
    isRequesting: Boolean,
    todoFn: Array<any>,
    ready: boolean
}

export const UserLikeFieldList: Module<StoreUserLikeField, any> = {
    namespaced: true,
    state: {
        likeFieldList: [],
        isRequesting: false,
        todoFn: [],
        ready: false
    },
    mutations: {
        updateLikeField: ( state: StoreUserLikeField, val: any ) => {
            state.likeFieldList = [...val].slice ( 0, 10 ) ;
        },
        updateIsResquesting: ( state: StoreUserLikeField, payload: Boolean ) => {
            state.isRequesting = payload ;
        },
        updateTodoFn: ( state, payload: {add?: Boolean, del?: Boolean, event?: object} ) => {
            if ( payload.add ) {
                state.todoFn.push(payload.event)
            }else {
                state.todoFn = []
            }
        }
    },
    actions: {
        FETCH_USER_LIKE_FIELD: async ( context, payLoad = { forceUpdate: false } ) => {
            console.log(888888888888888888888888)
            if ( !payLoad.forceUpdate && context.state.ready ) return context.state.likeFieldList ;
            try {
                if (context.state.isRequesting) {
                    return new Promise(resolve => {
                        context.commit('updateTodoFn', { add: true, event: resolve })
                    })
                }
                context.commit('updateIsResquesting', true)
                const token = await getToken () ;
                const res = await $axios ( {
                    url: getUserField,
                    params: {
                        token
                    }
                } ) ;
                const { data } = res as any ;
                context.state.ready = true;
                if ( data.code === 200 ) {
                    context.commit ( `updateLikeField`, data.data ) ;
                    if (context.state.todoFn.length) {
                        context.state.todoFn.forEach(fn => fn(data.data))
                        context.commit('updateTodoFn', { del: true })
                    }
                    context.commit('updateIsResquesting', false)
                    return data.data ;
                } else {
                    console.dir ( `检查失败FETCH_USER_LIKE_FIELD` ) ;
                    return [] ;
                }
            } catch ( err ) {
                console.dir ( `检查失败FETCH_USER_LIKE_FIELD`, err ) ;
                return [] ;
            }
        }
    }
}
          ;
export const CreateUserLikeFieldStore = function ( store: Store<unknown> ) {
    if ( store.hasModule ( `UserLikeFieldList` ) ) return ;
    store.registerModule ( `UserLikeFieldList`, UserLikeFieldList ) ;
} ;
