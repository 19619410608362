import type { ResultItem } from "./resultItem" ;
import type { Store } from "vuex" ;

export type SearchManagerProps<T extends ResultItem = ResultItem> = {
    fetchData: ( pageNumber: number ) => Promise<T[]> ;
    styleName: string ;
}
/** 搜索管理器 */
export class SearchManager <T extends ResultItem = ResultItem > {
    /** 搜索结果列表 **/
    items: T[] = [] ;
    /** 当前页数  **/
    pageNumber: number = 1;
    /** 每次翻页增量 */
    pageStep: number = 1;
    /** 每页条数 **/
    pageSize: number = 20;
    /** 是否在加载中 **/
    isLoading: boolean = true;
    /** 是否全部搜索完毕 **/
    isSearchEnd: boolean = false;
    /** 是否网络发生错误 **/
    isSearchError: boolean = false;
    /** 当前图片 **/
    currentItem: T = null;
    /** 瀑布流设置 **/
    masonryConfig: any = {
        gridWidth: 236,
        gapX: 24,
        gapY: 8,
        masonryWidth: `auto`
    };

    /** 重试次数 */
    retryTimes: number = 0;

    /** 最大重试次数 */
    maxRetryTimes: number = 0;
    /** 统计来源 */
    resource: string = `其他`;
    /** axios取消函数队列 */
    cancelTokens: Set<any> = new Set ();
    /** 是否启用加载处理 */
    isEnableLoadHandle: boolean = false;
    /** 是否显示vip遮罩 */
    noVipMaskBottom: boolean = false;
    /** 未登录用户显示的结果数量 */
    noVipResultNumber: number = Number.MAX_VALUE;
    /** 全局store */
    store:Store<any>;
    /** 类型 */
    styleName: string = `` ;
    /** 获取数据函数 */
    fetchData: ( pageNumber: number, styleName?: string ) => Promise<T[]> ;

    constructor ( store:Store<any>, options: Partial<SearchManagerProps<T>> ) {
        const { fetchData, styleName = `` } = options ;
        this.store = store ;
        this.fetchData = fetchData || ( async () => [] ) ;
        this.styleName = styleName ;
    }

    get searchState () {
        // 加载中
        if ( this.isSearchError && this.items.length === 0 ) return `ERROR` ;
        if ( !this.isSearchEnd || this.cancelTokens.size > 0 ) return `LOADING` ;
        if ( this.isSearchEnd ) {
            // 没有结果
            if ( this.items.length === 0 ) {
                return `END_NORESULT` ;
            } else if ( this.items.length <= 20 ) {
                return `END_LESSRESULT` ;
            } else {
                return `END` ;
            }
        }
    }

    /** 重置搜索 */
    resetSearch () {
        this.items = [] ;
        this.pageNumber = 1 ;
        this.isLoading = false ;
        this.isSearchEnd = false ;
        this.isSearchError = false ;
        this.currentItem = null ;
        this.retryTimes = 0 ;
        this.isEnableLoadHandle = true ;
        this.cancel () ;
    }

    cancel () {
        this.cancelTokens.forEach ( ( cancel ) => {
            cancel () ;
        } ) ;
        this.cancelTokens.clear () ;
    }

    async loadHandle ( force:boolean = false, opts?: {
        callback?: ( items: T[], pageNumber:number ) => Promise<T[]>
    } ) {
        const { callback } = opts || {} ;
        // console.log ( `status`, this.isLoading, this.isSearchEnd, this.isEnableLoadHandle ) ;
        /** 阻止路由跳转时的异常触发 */
        if ( !this.isEnableLoadHandle ) {
            return Promise.resolve ( true ) ;
        }
        const _self = this ;
        if ( this.isSearchEnd ) {
            return Promise.resolve ( true ) ;
        }
        if ( this.isLoading && !force ) {
            return Promise.resolve ( true ) ;
        }
        try {
            this.isLoading = true ;
            const items = [] ;
            let item = await _self.loadMore ( _self.pageNumber ) ;
            items.push ( item ) ;
            if ( callback ) {
                item = await callback ( item, _self.pageNumber ) ;
            }
            _self.items.push ( ...item ) ;
            // console.log ( `【分页】:` + ( _self.pageNumber - 1 ) + `【长度】` + item.length ) ;
            if ( items.some ( ( val ) => { return !val.length ; } ) || item.length < ( this.pageSize || 30 ) ) {
                this.isSearchEnd = true ;
            } else {
                this.pageNumber += this.pageStep ;
                this.retryTimes = 0 ;
            }
            return true ;
        } catch ( e ) {
            if ( e.__CANCEL__ ) {
                console.error ( e ) ;
            } else {
                this.isSearchEnd = true ;
                throw e ;
            }
        } finally {
            this.isLoading = false ;
        }
    }

    /** 加载下一页数据 **/
    async loadMore ( pageNumber, retry:number = 0 ) {
        try {
            if ( !this.store.getters[`UserInfo/isVip`] && this.items.length >= this.noVipResultNumber ) {
                this.noVipMaskBottom = true ;
                return Promise.resolve ( [] ) ;
            }
            if ( !this.fetchData ) {
                throw Error ( `组件内必须提供fetchData函数` ) ;
            }
            // 调用页面fetchData函数获取下一页数据
            const result = await this.fetchData ( pageNumber, this.styleName ) ;
            const items = result ;
            if ( items.length === 0 ) {
                return Promise.resolve ( [] ) ;
            } else {
                const length = this.items.length + items.length ;
                if ( !this.store.getters[`UserInfo/isVip`] && length >= this.noVipResultNumber ) {
                    const diff = length - this.noVipResultNumber ;
                    items.splice ( -diff ) ;
                }
                return Promise.resolve ( items ) ;
            }
        } catch ( e ) {
            if ( retry < this.maxRetryTimes ) {
                return await this.loadMore ( pageNumber, retry + 1 ) ;
            } else {
                throw e ;
            }
        }
    }
}
