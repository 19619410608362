import isArray from "lodash-es/isArray" ;
import AES from "crypto-js/aes" ;
import Enc from "crypto-js/enc-utf8" ;
import md5 from "crypto-js/md5";
// @ts-ignore
import dayjs, {OpUnitType, ManipulateType, UnitType} from 'dayjs' ;

type LocalStorageDataType = {
    expires: string,
    data:any
}
/** 在localstorage中写入记录
 * @param prefix key的前缀
 * @param id key的id
 * @param data 要写入的数据
 * @param encryption 是否要加密key id
 * @param expires 过期时间（天）
 * @param maxLength 若写入数据若是数组，最大的数组长度
 * @constructor
 */
function LocalStorageWriteFN( prefix:string, id:string, data:Array<any>, expires?:number, encryption?:boolean, maxLength?:number ):[Error, boolean];
function LocalStorageWriteFN( prefix:string, id:string, data:any, expires?:number, encryption?:boolean ):[Error, boolean];
function LocalStorageWriteFN( prefix:string, id:string, data:any, expires?:{ expires:number, unit: OpUnitType }, encryption?:boolean ):[Error, boolean];
function LocalStorageWriteFN ( prefix:string, id:any, data:any, expires:any = null, encryption:any = false, maxLength:any = Number.MAX_VALUE ):any {
    try {
        const key = encryption ? prefix + md5 ( id ).toString () : prefix + id ;
        if ( isArray ( data ) ) {
            if ( data.length > maxLength ) {
                data.shift () ;
            }
        }
        let endTime ;
        if ( typeof expires === `object` && expires ) {
            endTime = dayjs ().add ( expires.expires, expires.unit ).format () ;
        } else if ( typeof expires === `number` ) {
            endTime = dayjs ().add ( expires, `day` ).format () ;
        }
        const result:LocalStorageDataType = {
            expires: expires ? endTime : null,
            data: data
        } ;
        localStorage.setItem ( key, JSON.stringify ( result ) ) ;
    } catch ( e ) {
        console.dir ( `写入错误` ) ;
        throw e ;
    }
}
export const LocalStorageWrite = LocalStorageWriteFN ;

/** 读取localstorage中的记录
 * @param prefix key的前缀
 * @param id key的id
 * @param encryption 是否要加密key id
 * @constructor
 */
export const LocalStorageRead = <T, > ( prefix:string, id:string, encryption:boolean = false ):T => {
    try {
        // 得到key
        const key = encryption ? prefix + md5 ( id ) : prefix + id ;
        const cacheData = localStorage.getItem ( key ) ;
        if ( !cacheData ) {
            return null ;
        }
        // 获取缓存数据
        const cache:LocalStorageDataType = JSON.parse ( cacheData ) ;
        const { expires, data } = cache ;
        // 没有数据则返回空
        if ( !data ) {
            return null ;
        }
        // 如果有过期时间，则检查是否过期
        if ( expires ) {
            // 过期则删除cache
            const expiresDay = dayjs ( expires ) ;
            const now = dayjs ( new Date () ) ;
            if ( now.isAfter ( expiresDay ) ) {
                localStorage.removeItem ( key ) ;
                return null ;
            }
        }
        return data ? data as T : null ;
    } catch ( e ) {
        console.dir ( `读取错误` ) ;
        return null ;
    }
} ;

/** 删除过期信息 */
export const removeExpiresData = ( key:string ) => {
    try {
        const data = localStorage.getItem ( key ) ;
        if ( data ) {
            const saveData = JSON.parse ( data ) ;
            const keys = Object.keys ( saveData ) ;
            for ( const i in keys ) {
                const id = keys[i] ;
                const expires = saveData[id] ;
                if ( dayjs ().isAfter ( dayjs ( expires ) ) ) {
                    delete saveData[id] ;
                }
            }
            localStorage.setItem ( key, JSON.stringify ( saveData ) ) ;
        }
    } catch ( e ) {
        console.warn ( `删除过期存储【${key}】失败` ) ;
    }
} ;

/** 加密解密key */
const EncryptionKey = `eGlubHUucWl1` ;

/** 分账号保存信息
 * @param key 保存的键
 * @param id 账号名
 * @param data 内容
 * @param expires 过期时间
 * @param encryption 是否加密
 */
export const saveData = ( key:string, id:any, data:any, expires?:{ expires:number, unit: UnitType }, encryption:any = false ):any => {
    try {
        // 删除所有过期内容
        removeExpiresData ( key ) ;
        const loadData = localStorage.getItem ( key ) ;
        let saveData ;
        if ( loadData ) {
            saveData = JSON.parse ( loadData ) ;
        } else {
            saveData = {} ;
        }
        const userData = {
            data: encryption ? AES.encrypt ( JSON.stringify ( data ), EncryptionKey ).toString () : data,
            expires: expires ? dayjs ().add ( expires.expires, expires.unit ).format () : null
        } ;
        saveData[`${id}`] = userData ;
        localStorage.setItem ( key, JSON.stringify ( saveData ) ) ;
    } catch ( e ) {
        throw e ;
    }
} ;

/** 分账号读取信息
 * @param key 保存的键
 * @param id 账号名
 * @param encryption 是否加密
 */
export const loadData = ( key:string, id:any, encryption:any = false ) => {
    try {
        // 是否有key的数据
        const loadData = localStorage.getItem ( key ) ;
        if ( !loadData ) return null ;
        // 是否有id的数据
        const saveData = JSON.parse ( loadData ) ;
        const val = saveData[id] ;
        if ( !val ) return null ;
        // 是否过期
        const isExpired = dayjs ().isAfter ( dayjs ( val.expires ) ) ;
        if ( isExpired ) return null ;
        // 是否要解密
        const data = val.data ;
        if ( encryption ) {
            const deData = AES.decrypt ( data, EncryptionKey ).toString ( Enc ) ;
            return JSON.parse ( deData ) ;
        }
        return data ;
    } catch ( e ) {
        console.warn ( e.message ) ;
        return null ;
    }
} ;
