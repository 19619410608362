import dayjs from "dayjs" ;
interface IntRecordItem {
    uid: string,
    token: string,
    apiRes: any,
    apiUrl?: string
}

export function recordLogOut ( options: {} ) {
    const recordLogOutList = JSON.parse ( localStorage.getItem ( `recordLogOut` ) ) || [] ;
    if ( recordLogOutList.length > 9 ) {
        recordLogOutList.shift () ;
    }
    const time = dayjs ( new Date () ).format ( `YYYY-MM-DD HH:mm:ss` ) ;
    const record = {
        ...options,
        time: time,
        url: window.location.href
    } ;
    recordLogOutList.push ( record ) ;
    localStorage.setItem ( `recordLogOut`, JSON.stringify ( recordLogOutList ) ) ;
}
