import { Module, Store } from "vuex" ;

interface State {
    firstPageKey: string
}
export const History: Module<State, any> = {
    state: {
        firstPageKey: ''
    },
    mutations: {
        setFirstPageKey: ( state: State, key ) => {
          state.firstPageKey = key
        }
    },
    getters: {
        IsFirstPage: ( state: State ) => {
            if ( typeof window !== "undefined" ) {
                if ( history.state.key ) {
                    return state.firstPageKey === history.state.key;
                } else {
                    return false ;
                }
            } else {
                return true ;
            }
        }
    }
} ;
export const CreateHistory = ( State: Store<unknown> ) => {
    if ( State.hasModule ( `History` ) ) return ;
    State.registerModule ( `History`, History ) ;
} ;
