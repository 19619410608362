import { ServerMetaSearch, ServerPService } from "@constants/servers" ;
import { PageCrossDomain } from "@constants/pages" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import Cookies from "js-cookie" ;
import parseUrl from "url-parse" ;
import { getDomain, getToken } from "@scripts/tools" ;

/** 跨域信息 */
export const CrossDomainData = {
    /** 是否隐私模式 */
    isIncognito: true,
    /** 是否已经检查过隐私模式 */
    ready: true
} ;

/** 获取用户token,跨域优先
 *  目前都改为只取本域名下的token
 */
export const getTokenCrossDomain = async () => {
    return await getToken () ;
    // 非bbw项目，使用Iframe获取token,失败的情况下获取本域名下的token
    // if ( window.location.href && !window.location.href.match ( `bigbigwork.com` ) ) {
    //     // 检查跨域cookie是否可用
    //     const isIncognito = await checkIsIncognito () ;
    //     if ( isIncognito ) {
    //         // 拿不到跨域cookie就返回本域名下的token
    //         return await getToken () ;
    //     } else {
    //         // 否则拿跨域token
    //         const [err, token] = await cookieGet ( `dashisousuo` ) ;
    //         const [err2, token2] = await cookieGet ( `jhk-personal` ) ;
    //         if ( !err || !err2 ) {
    //             const result = token || token2 || await getToken () || `` ;
    //             if ( result ) {
    //                 // 同步token
    //                 Cookies.set ( `jhk-personal`, result, { domain: getDomain (), path: `/`, expires: 7 } ) ;
    //             } else {
    //                 Cookies.remove ( `jhk-personal`, { domain: getDomain (), path: `/` } ) ;
    //             }
    //             return result ;
    //         } else {
    //             return `` ;
    //         }
    //     }
    // } else {
    //     return await getToken () ;
    // }
} ;

/** 删除用户token */
export const removeToken = async () => {
    console.log ( `检查失败removeToken` ) ;
    Cookies.remove ( `jhk-personal`, { domain: getDomain () } ) ;
    Cookies.remove ( `dashisousuo`, { domain: getDomain () } ) ;
} ;
/** 检查是否隐私模式 */
export const checkIsIncognito = async () => {
    return true;
    // if ( CrossDomainData.ready ) return Promise.resolve ( CrossDomainData.isIncognito ) ;
    // if ( window.location.href && window.location.href.match ( `bigbigwork.com` ) ) {
    //     return Promise.resolve ( true ) ;
    // }
    // const iframe = document.getElementById ( `crossDomain` ) ;
    // if ( iframe.onload ) {
    //     // 新版快速测试跨域
    //     return new Promise ( ( resolve ) => {
    //         BigBigWork.addListener ( `CROSS_DOMAIN_READY`, ( ) => {
    //             resolve ( CrossDomainData.isIncognito ) ;
    //         } ) ;
    //         BigBigWork.addListener ( `CROSS_DOMAIN_FAIL`, ( ) => {
    //             resolve ( CrossDomainData.isIncognito ) ;
    //         } ) ;
    //     } ) ;
    // } else {
    //     // 跨域
    //     const [err, isIncognito] = await crossDomainMessage<string> ( `GetCookie`, `crossdomain`, `ReturnGetCookie_crossdomain`, 300 ) ;
    //     console.log ( `isIncognitoisIncognitoisIncognitoisIncognito`, isIncognito ) ;
    //     CrossDomainData.ready = true ;
    //     if ( !err && isIncognito ) {
    //         CrossDomainData.isIncognito = false ;
    //         return false ;
    //     } else {
    //         CrossDomainData.isIncognito = true ;
    //         return true ;
    //     }
    // }
} ;

/**
 * @description 跨域公用方法
 * @param type 发出消息的类型
 * @param data 发出的数据
 * @param returnType 返回的消息类型
 * @param timeout 超时时间（default:1000）
 * @param targetOrigin 跨域iframe允许的域名
 * @return 跨域iframe返回的数据
 */
export const crossDomainMessage = async <T>( type:string, data:unknown, returnType:string, timeout:number = 1000, targetOrigin:string = `https://xcx-img.bigbigwork.com` ):Promise<[Error, T]> => {
    return new Promise ( ( resolve, reject ) => {
        let response:T, timer:number ;

        const iframe = document.getElementById ( `crossDomain` ) as HTMLIFrameElement ;
        if ( !iframe ) reject ( new Error ( `找不到跨域iframe` ) ) ;
        iframe.contentWindow.postMessage ( { type, data }, targetOrigin ) ;
        // console.log ( `跨域发出请求${type}：${JSON.stringify ( data )}` ) ;
        // 接受消息的函数
        const receiveMessage = ( event ) => {
            // console.log ( event, `监听到的信息` ) ;
            const origin = event.origin ;
            // 消息类型和来源域名符合，则继续
            if ( event.data.type === returnType && origin.match ( targetOrigin ) ) {
                window.clearTimeout ( timer ) ;
                // console.log ( `跨域接受到${returnType}：${JSON.stringify ( event.data.data )}` ) ;
                window.removeEventListener ( `message`, receiveMessage ) ;
                // @ts-ignore
                response = event.data?.data as T ;
                // console.log ( response, `responseresponse` ) ;
                resolve ( [null, response] ) ;
            }
        } ;
        if ( returnType ) {
            // 过一定时间后没有返回值，则返回错误结束promise
            timer = window.setTimeout ( () => {
                if ( !response ) {
                    window.removeEventListener ( `message`, receiveMessage ) ;
                    resolve ( [new Error ( `【跨域超时】${type}` ), null as T] ) ;
                }
            }, timeout ) ;
            window.addEventListener ( `message`, receiveMessage, false ) ;
        } else {
            resolve ( [null as Error, { } as T] ) ;
        }
    } ) ;
} ;
/** 设置cookie(跨域) */
export const cookieSet = async ( key:string, val:string, options?:Cookies.CookieAttributes, domain:string = getDomain (), force:boolean = false ):Promise<[Error, boolean]> => {
    try {
        const oldOpts = { ...options } ;
        delete oldOpts.secure ;
        delete oldOpts.sameSite ;
        const isBigbigwork = window.document.domain.match ( `bigbigwork.com` ) ;
        if ( isBigbigwork && !force ) {
            Cookies.set ( key, val, { ...{ domain: `${domain}` }, ...oldOpts } ) ;
            Cookies.set ( key, val, { ...{ domain: `${domain}` }, ...options } ) ;
            return [null, true] ;
        }
        const isIncognito = await checkIsIncognito () ;
        if ( isIncognito ) {
            Cookies.set ( key, val, { ...{ domain: `${domain}` }, ...oldOpts } ) ;
            Cookies.set ( key, val, { ...{ domain: `${domain}` }, ...options } ) ;
            return [null, true] ;
        } else {
            const data = { key, val, options: { ...{ domain: `.bigbigwork.com`, sameSite: `none`, secure: true }, ...options } } ;
            const [err, result] = await crossDomainMessage<boolean> ( `SetCookie`, data, `ReturnSetCookie_${key}`, 300 ) ;
            if ( err && !force ) {
                Cookies.set ( key, val, { ...{ domain: `${domain}` }, ...oldOpts } ) ;
                Cookies.set ( key, val, { ...{ domain: `${domain}` }, ...options } ) ;
            }
            return [null, true] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/** 删除cookie(跨域） */
export const cookieRemove = async ( key:string, domain:string = getDomain (), options?:Cookies.CookieAttributes, force:boolean = false ) => {
    const isBigbigwork = window.document.domain.match ( `bigbigwork.com` ) ;
    if ( isBigbigwork && !force ) {
        Cookies.remove ( key, { domain: `${domain}` } ) ;
        return [null, true] ;
    }
    const isIncognito = await checkIsIncognito () ;
    if ( isIncognito ) {
        Cookies.remove ( key, { domain: `${domain}`, path: `/` } ) ;
        return [null, true] ;
    } else {
        const data = { key } ;
        const [err, result] = await crossDomainMessage<void> ( `RemoveCookie`, data, null, 300 ) ;
        if ( err && !force ) {
            Cookies.remove ( key, { domain: `${domain}`, path: `/` } ) ;
        }
        return [null, result] ;
    }
} ;
/** 获取cookie(跨域) */
export const cookieGet = async ( key:string, domain:string = getDomain (), force:boolean = false ):Promise<[Error, string]> => {
    try {
        // // 如果距离上次获取cookie超过5分钟，则重新获取跨域页面
        // if ( checkCrossDomain () ) {
        //     await CreateCrossDomain ( new Date ().getTime () ) ;
        // }
        const isBigbigwork = window.document.domain.match ( `bigbigwork.com` ) ;
        if ( isBigbigwork && !force ) {
            return [null, Cookies.get ( key )] ;
        }
        const isIncognito = await checkIsIncognito () ;
        if ( isIncognito ) {
            return [null, Cookies.get ( key )] ;
        } else {
            const [err, result] = await crossDomainMessage<string> ( `GetCookie`, key, `ReturnGetCookie_${key}`, 300 ) ;
            if ( err && !force ) {
                return [null, Cookies.get ( key )] ;
            }
            return [null, result] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;

export const checkCrossDomain = () => {
    // @ts-ignore
    return new Date ().getTime () - window.crossDomain > 1000 * 60 * 5 ;
} ;

/** 创建跨域Iframe窗口 */
export const CreateCrossDomain = ( time:number = new Date ().getTime () ):Promise<void> => {
    try {
        console.log ( `开始跨域` ) ;
        // @ts-ignore
        window.crossDomain = time ;
        return new Promise ( ( resolve, reject ) => {
            let iframe:HTMLIFrameElement = document.getElementById ( `crossDomain` ) as HTMLIFrameElement ;
            const src = `${PageCrossDomain}` ;
            const handleSuccess = () => {
                const timer = window.setTimeout ( () => {
                    console.log ( `跨域iframe加载成功` ) ;
                    BigBigWork.emit ( `CROSS_DOMAIN_READY` ) ;
                    resolve ( ) ;
                }, 200 ) ;
            } ;
            const handleFail = ( ev:ErrorEvent | Error ):any => {
                console.log ( `跨域iframe加载失败` ) ;
                BigBigWork.emit ( `CROSS_DOMAIN_FAIL` ) ;
                reject ( ev ) ;
            } ;
            // @ts-ignore
            if ( iframe ) {
                iframe.src = src ;
                iframe.removeEventListener ( `load`, handleSuccess ) ;
                iframe.removeEventListener ( `error`, handleFail ) ;
                iframe.addEventListener ( `load`, handleSuccess ) ;
                iframe.addEventListener ( `error`, handleFail ) ;
            } else {
                const cdIframe = document.createElement ( `iframe` ) ;
                cdIframe.height = `0` ;
                cdIframe.width = `0` ;
                cdIframe.id = `crossDomain` ;
                cdIframe.src = src ;
                cdIframe.style.display = `none` ;
                iframe = document.body.appendChild ( cdIframe ) ;
                cdIframe.addEventListener ( `load`, handleSuccess ) ;
                cdIframe.addEventListener ( `error`, handleFail ) ;
            }
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;
export const handleCrossIframeReady = async () => {
    // @ts-ignore
    if ( window.crossDomainReady ) {
        console.log ( `跨域iframe加载成功` ) ;
        return new Promise ( ( resolve ) => {
            window.setTimeout ( async () => {
                const [err, isIncognito] = await crossDomainMessage<string> ( `GetCookie`, `crossdomain`, `ReturnGetCookie_crossdomain`, 300 ) ;
                console.log ( `isIncognitoisIncognitoisIncognitoisIncognito`, isIncognito ) ;
                CrossDomainData.ready = true ;
                if ( !err && isIncognito ) {
                    CrossDomainData.isIncognito = false ;
                } else {
                    CrossDomainData.isIncognito = true ;
                }
                BigBigWork.emit ( `CROSS_DOMAIN_READY` ) ;
                resolve ( true );
            }, 200 ) ;
        })
    } else {
        console.log ( `跨域iframe加载失败` ) ;
        CrossDomainData.ready = true ;
        CrossDomainData.isIncognito = false ;
        BigBigWork.emit ( `CROSS_DOMAIN_FAIL` ) ;
        return true;
    }
} ;
/** 初始化跨域(不用加载iframe框) */
export const initCrossDomain = async () => {
    // @ts-ignore
    if ( window.crossDomainReady !== undefined ) {
        await handleCrossIframeReady () ;
    } else {
        return new Promise ( ( resolve, reject ) => {
            const iframe = document.getElementById ( `crossDomain` ) ;
            iframe.addEventListener ( `load`, async ( ) => {
                // @ts-ignore
                window.crossDomainReady = true ;
                await handleCrossIframeReady () ;
                resolve ( true );
            } ) ;
            iframe.addEventListener ( `error`, async ( ) => {
                // @ts-ignore
                window.crossDomainReady = false ;
                await handleCrossIframeReady () ;
                resolve ( true );
            } ) ;
        })
    }
} ;
