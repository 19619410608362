/* eslint-disable no-undef */
import { BigBigWork } from "@scripts/BigBigWork" ;
import { getToken } from "@scripts/tools" ;
import { isDev } from "@scripts/utils";
import {
    AnalysisBoardAssociateWord,
    AnalysisBoardDisplay,
    AnalysisBoardClickVolume,
    AnalysisChangeLikefields,
    AnalysisClickStats,
    AnalysisCollToolIndex,
    AnalysisCorrelationalStatistic,
    AnalysisDownloadStatistics,
    AnalysisHomePageStatistic,
    AnalysisInit360,
    AnalysisInitBaidu,
    AnalysisInitGoogle,
    AnalysisOpenDzSeoImg,
    AnalysisOpenPersonPage,
    AnalysisIsInWhite,
    AnalysisRelatedWordDisplay,
    AnalysisSendAnalysis,
    AnalysisSimilarImgButton,
    AnalysisShowStats,
    AnalysisUserOpenBigImg,
    AnalysisUserOpenBoard,
    AnalysisInitYouMeng,
    searchMyPinStat, AnalysisTUYiDUanJuStatistic
} from "./Analysis" ;

/** 大作百度统计的 id */
const AnalysisBaiduBbwId = `d24dcf008a97469875a4da33090711f9` ;
/** 初始化bbw统计 */
export const AnalysisBBWInit = () => {
    console.log(window.location.hostname)
    if ( !AnalysisIsInWhite ( window.location.hostname ) ) {
        return ;
    }
    // 初始化百度统计
    AnalysisInitBaidu ( AnalysisBaiduBbwId ) ;
    // 初始化友盟统计
    AnalysisInitYouMeng()
    // 初始化谷歌统计(停用)
    // AnalysisInitGoogle () ;
    // 初始化360统计
    // AnalysisInit360 () ;
    // 初始化统计消息循环
    initMassageMap () ;
    // 初始化Kibana统计
    initKibana () ;
    setTimeout ( function () {
        // 统计代码部署完成
        BigBigWork.emit ( `AnalysisDeployComplete` ) ;
    }, 300 ) ;
} ;
/** 开始kibana 统计 */
const initKibana = () => {
    // 下载时触发统计
    // 搜索选项
    let SearchOptions:any = {} ;
    // 行业管理器
    let IndustryManager:any = {
        current: {}
    } ;
    // 网站管理器
    let SitesManager:any = {
        current: {}
    } ;
    // 浏览模式
    let viewMode = 1 ;
    // 统计页面来源
    // var resource = "其他";
    let resource = `大作搜索列表页` ;
    BigBigWork.addListener ( `_SEAECHOPTIONS_onOptionsChange`, function ( options ) {
        SearchOptions = options ;
    } ) ;
    BigBigWork.addListener ( `TagOneChange`, function ( TagOneManager ) {
        IndustryManager = TagOneManager ;
    } ) ;
    BigBigWork.addListener ( `IndustryChange`, function ( IndustryMng ) {
        IndustryManager = IndustryMng ;
    } ) ;
    // 监听路由变化
    BigBigWork.addListener ( `ResourceChange`, function ( name ) {
        resource = name ;
    } ) ;
    // 监听浏览模式变化
    BigBigWork.addListener ( `ViewModeChange`, function ( mode ) {
        viewMode = mode ;
    } ) ;
    // 监听站点变化
    BigBigWork.addListener ( `SitesChange`, function ( sitesManager ) {
        SitesManager = sitesManager ;
    } ) ;
    // 下载时触发
    BigBigWork.addListener ( `downloadImage`, async ( data ) => {
        if ( !data.item || !data.userInfo ) {
            console.log ( `downloadImage统计格式为data:{item,userInfo}` ) ;
            return ;
        }
        const { item, userInfo } = data ;
        const token = await getToken () ;
        // 下载统计
        BigBigWork.emit ( `Analysis`, {
            type: `AnalysisDownloadImage`,
            data: {
                search_word: SearchOptions.cnKW || ``, // 搜索词
                search_word_en: SearchOptions.enKW || ``, // 搜索词英文
                img_class: IndustryManager.current.id || -1, // 图片分类
                img_class_string: IndustryManager.current.name || ``, // 图片分类名称
                img_source_website: item.domain, // 图片网站
                img_actual_website: item.pageUrl, // 图片所在网页链接
                img_download_link: item.downloadUrl, // 图片下载链接
                img_name: item.title, // 图片名称
                img_describe: ``, // 图片描述（暂不统计）
                img_designer_name: item.author, // 图片设计师
                img_size: item.w, // 图片大小  500：500k
                img_width_height: item.w + `*` + item.h, // 图片宽高  500*600
                userid: userInfo.id, // 用户id
                username: userInfo.name, // 用户昵称
                is_speed: userInfo.vipDays > 0 ? 1 : 0, // 是否加速  0否  1是
                timestamp: new Date ().getTime (), // 当前时间戳毫秒级别
                accessToken: token,
                flag_dz: item.type === `meta` ? 0 : 1,
                img_id: item.type === `meta` ? 0 : item.id
            }
        } ) ;
    } ) ;

    // 收藏时触发
    BigBigWork.addListener ( `collectImage`, async ( data ) => {
        if ( !data.item || !data.userInfo ) {
            console.log ( `collectImage统计格式为data:{item,userInfo}` ) ;
            return ;
        }
        const token = await getToken();
        const { item, userInfo } = data ;
        // 收藏统计
        BigBigWork.emit ( `Analysis`, {
            type: `AnalysisCollectImage`,
            data: {
                search_word: SearchOptions.cnKW || ``, // 搜索词
                search_word_en: SearchOptions.enKW || ``, // 搜索词英文
                img_class: IndustryManager.current.id || -1, // 图片分类
                img_class_string: IndustryManager.current.name || ``, // 图片分类名称
                img_source_website: item.domain, // 图片网站
                img_actual_website: item.pageUrl || item.domain, // 图片所在网页链接
                img_name: item.title, // 图片名称
                img_describe: ``, // 图片描述（暂不统计）
                img_designer_name: item.author, // 图片设计师
                userid: userInfo.id, // 用户id
                username: userInfo.name, // 用户昵称
                board_type: 1, // 1个人  2 协作  （具体值看后期）
                timestamp: new Date ().getTime (), // 当前时间戳毫秒级别,
                resource: resource,
                accessToken: token,
                flag_dz: item.type === `meta` ? 0 : 1,
                img_id: item.type === `meta` ? 0 : item.id,
                board_id: item.board_id || ``,
                source_board_id: item.source_board_id || 0
            }
        } ) ;
    } ) ;
    // 搜索时触发
    BigBigWork.addListener ( `searchImage`, function ( data ) {
        if ( data.userInfo ) {
            console.log ( `searchImage统计格式为data:{userInfo}` ) ;
            return ;
        }
        const { userInfo } = data ;
        let webNum = 0 ;
        let webName = `` ;
        // 搜索统计
        if ( !SitesManager || !SitesManager.showSite || !SitesManager.current ) {
            webNum = 0 ;
            webName = `` ;
        } else if ( SitesManager.current && SitesManager.current.id ) {
            webNum = 1 ;
            webName = SitesManager.current.name + `,` ;
        } else if ( SitesManager && SitesManager.showSite ) {
            webNum = SitesManager.showSite.length > 6 ? 6 : SitesManager.showSite.length ;
            for ( var i = 0 ; i < webNum ; i++ ) {
                webName += SitesManager.showSite[i].name + `,` ;
            }
        }
        BigBigWork.emit ( `Analysis`, {
            type: `AnalysisSearch`,
            data: {
                search_word: SearchOptions.cnKW || ``, // 搜索词
                search_word_en: SearchOptions.enKW || ``, // 搜索词英文
                search_type: 1, // 1 该分类全部网站  2  改分类部分网站  3 单一网站
                web_num: webNum, // 选择网站的数量
                web_name: webName, // 选择网站的网站名，多网站，用英文逗号分隔
                view_type: viewMode + 1, // 1 快速浏览  2完全浏览
                img_type: IndustryManager.current.id || -1, // 图片分类
                img_type_string: IndustryManager.current.name || ``, // 图片分类名称
                userid: userInfo.id, // 用户userid
                username: userInfo.name, // 用户昵称
                first_open: 3000, // 打开第一张图片的时长 秒
                // vip: BigBigWork.User.isVip()?(BigBigWork.User.isUserPay.is_user_pay?1:2):0, //是否vip  0：非VIP  1：付费VIP 2：非付费VIP
                // timestamp: new Date().getTime(), //当前时间戳毫秒级别
                resource: resource,
                accessToken: userInfo.dashisousuo ()
            }
        } ) ;
    } ) ;
} ;
/** 初始化消息循环 */
const initMassageMap = () => {
    BigBigWork.addListener ( `Analysis`, function ( msg ) {
        const { type, data } = msg ;
        if ( !type ) {
            isDev() && console.log ( `发送分析错误` ) ;
        } else {
            messageMap ( type, data ) ;
        }
    } ) ;
} ;
/** 统计消息循环 */
const messageMap = ( type:string, data:any ) => {
    // @ts-ignore
    if ( !window._hmt) return ;
    switch ( type ) {
    case `openPay`:
        AnalysisSendAnalysis ( type, data ) ;
        break ;
        // 弹出二维码浮层显示时(data：统计打开的来源)
    case `VipShow`:
        // eslint-disable-next-line no-undef
        _hmt.push ( [`_trackEvent`, `付费`, `打开付费弹框`, data || `其他`, window.location.href] ) ;
        // eslint-disable-next-line no-undef
        // ga ( `send`, `event`, `付费`, data || `其他`, window.location.href ) ;
        break ;
    case `VipShowAnalysis`:
        _hmt.push ( [`_trackEvent`, `付费`, `打开付费弹框用户统计`, `用户信息`, JSON.stringify ( data.userInfo )] ) ;
        // ga ( `send`, `event`, `打开付费弹框用户统计`, `用户信息`, JSON.stringify ( data.userInfo ) ) ;
        break ;
        // 支付成功后显示
    case `VipPaySuccess`:
        _hmt.push ( [`_trackEvent`, `付费`, `支付成功`, data.source] ) ;
        // ga ( `send`, `event`, `付费成功`, data.source, window.location.href ) ;
        break ;
    case `VipPaySuccessSource`:
        _hmt.push ( [`_trackEvent`, `付费点`, `支付成功`, data.source, data.userid] ) ;
        // ga ( `send`, `event`, `付费成功点`, `付费来源`, data.source ) ;
        break ;
    case `AnalysisDownloadImage`:
        AnalysisSendAnalysis ( `DownloadImage`, data ) ;
        break ;
    case `AnalysisSearch`:
        AnalysisSendAnalysis ( `Search`, data ) ;
        break ;
    case `AnalysisCollectImage`:
        AnalysisSendAnalysis ( `CollectImage`, data ) ;
        break ;
    case `www-test`:
        _hmt.push ( [`_trackEvent`, `测试`, `统计测试`, window.location.href] ) ;
        // ga ( `send`, `event`, `测试`, `统计测试`, window.location.href ) ;
        break ;
    case `WallpaperAnalysis`:
        _hmt.push ( [`_trackEvent`, `壁纸大赛`, `打开壁纸大赛统计`, `用户信息`, JSON.stringify ( data.userInfo )] ) ;
        ga ( `send`, `event`, `打开壁纸大赛统计`, `用户信息`, JSON.stringify ( data.userInfo ) ) ;
        break ;
    case `RabbitHomeAnalysis`:
        _hmt.push ( [`_trackEvent`, `大兔首页`, `打开大兔首页统计`, `用户信息`, JSON.stringify ( data.userInfo )] ) ;
        // ga ( `send`, `event`, `打开大兔首页统计`, `用户信息`, JSON.stringify ( data.userInfo ) ) ;
        break ;
    case `RabbitSearchAnalysis`:
        _hmt.push ( [`_trackEvent`, `大兔搜索`, `打开大兔搜索页统计`, `用户信息`, JSON.stringify ( data.userInfo )] ) ;
        // ga ( `send`, `event`, `打开大兔搜索页统计`, `用户信息`, JSON.stringify ( data.userInfo ) ) ;
        break ;
    case `NewPageVersiondif`:
        _hmt.push ( [`_trackEvent`, `特权对比页`, `打开特权对比页`] ) ;
        // ga ( `send`, `event`, `特权对比页`, `用户信息` ) ;
        break ;
    case `AnalysisReletivePicture`:
        AnalysisCorrelationalStatistic ( data ) ;
        break ;
    case `showStats`:
        AnalysisShowStats ( data ) ;
        break ;
    case `clickStats`:
        AnalysisClickStats ( data ) ;
        break ;
    case `OpenPersonPage`:
        AnalysisOpenPersonPage ( data ) ;
        break ;
    case `userOpenBigImg`:
        AnalysisUserOpenBigImg ( data ) ;
        break ;
    case `OpenDzSeoImg`:
        AnalysisOpenDzSeoImg ( data ) ;
        break ;
    case `homePageStatistic`:
        AnalysisHomePageStatistic ( data ) ;
        break ;
    case `tuYiDuanJuStatistic`:
        AnalysisTUYiDUanJuStatistic ( data ) ;
        break ;
    case `userOpenBoard`:
        AnalysisUserOpenBoard ( data ) ;
        break ;
    case `boardDisplay`:
        AnalysisBoardDisplay ( data ) ;
        break ;
    case `boardClickVolume`:
        AnalysisBoardClickVolume ( data ) ;
        break ;
    case `changeLikefields`:
        AnalysisChangeLikefields ( data ) ;
        break ;
    case `similarImgButton`:
        AnalysisSimilarImgButton ( data ) ;
        break ;
    case `downloadStatistics`:
        AnalysisDownloadStatistics ( data ) ;
        break ;
    case `boardAssociateWord`:
        AnalysisBoardAssociateWord ( data ) ;
        break ;
    case `relatedWordDisplay`:
        AnalysisRelatedWordDisplay ( data ) ;
        break ;
    case `collToolIndex`:
        AnalysisCollToolIndex ( data ) ;
        break ;
    }
} ;
