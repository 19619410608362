/* eslint-disable  camelcase */
import { Module, Store } from 'vuex' ;
import { $axios } from "@scripts/Axios" ;
import { ServerDesigner } from "@constants/servers" ;
import { getToken } from "@scripts/tools" ;

interface DesignerState {
    loading: boolean,
    ready: boolean,
    address: string,
    againApplicationDate: number,
    engagedField: number,
    id: number,
    introduction: string,
    name: string,
    organLicenseUrl: string,
    organName: string,
    phone: number,
    status: number,
    statusStr: string,
    type: number,
    uid: number,
    userToken: string,
    website: string,
    isReqSiteitem: boolean,
    todoSiteListFn: any,
    designer: any
}
export const Designer:Module<DesignerState, any> = {
    namespaced: true,
    state: {
        loading: false,
        ready: false,
        address: null,
        againApplicationDate: null,
        engagedField: null,
        id: null,
        introduction: null,
        name: null,
        organLicenseUrl: null,
        organName: null,
        phone: null,
        status: -1,
        statusStr: `申请`,
        type: null,
        uid: null,
        userToken: null,
        website: null,
        isReqSiteitem: false,
        todoSiteListFn: [],
        designer: {}
    },
    mutations: {
        setInfo: ( state:DesignerState, val ) => {
            state.status = val.status ;
            state.againApplicationDate = val.again_application_date ;
            state.designer = val ;
        },
        setLoading: ( state, val:boolean ) => {
            state.loading = val ;
        },
        ready: ( state ) => {
            state.ready = true ;
        },
        updateIsReqSiteitem: ( state, val: any ) => {
            state.isReqSiteitem = val ;
        },
        updateTodoSiteListFn: ( state, val: any ) => {
            state.todoSiteListFn.push ( val ) ;
        },
        clearTodoFnList: ( state ) => {
            state.todoSiteListFn = [] ;
        }
    },
    actions: {
        FETCH_DESIGNER_INFO: async ( context ) => {
            if ( context.state.isReqSiteitem && !context.state.ready ) {
                // 已发出请求 && 请求未结束 需等待
                return new Promise ( function ( resolve ) {
                    context.commit ( `updateTodoSiteListFn`, resolve ) ;
                } ) ;
            }
            context.commit ( `updateIsReqSiteitem`, true ) ;
            if ( context.state.ready ) return Promise.resolve ( true ) ;
            const token = await getToken () ;
            if ( !token ) {
                return Promise.resolve ( true ) ;
            }
            const params = {
                uid: context.rootState.UserInfo.id,
                user_token: token
            } ;
            const url = ServerDesigner + `/designer/getDesignerInfo` ;
            // const url = `//designer.bigbigwork.com/designer/getDesignerInfo` ;
            return $axios ( {
                method: `GET`,
                url,
                params
            } ).then ( res => {
                if ( res.status === 200 && res.data.status === 200 ) {
                    console.log(77777777777777777777777777)
                    context.commit ( `updateIsReqSiteitem`, false ) ;
                    context.commit ( `ready`, true ) ;
                    context.commit ( `setInfo`, res.data.data ) ;
                    context.state.todoSiteListFn.forEach ( item => {
                        item ( true ) ;
                    } ) ;
                    context.commit ( `clearTodoFnList` ) ;
                } else {
                    throw new Error ( `出错：${url}` ) ;
                }
            } ).finally ( () => {
                context.commit ( `setLoading`, false ) ;
            } ) ;
        }
    }
} ;

export const CreateDesignerStore = ( store:Store<unknown> ) => {
    if ( store.hasModule ( `Designer` ) ) return ;

    /** 在store上注册模块 */
    store.registerModule ( `Designer`, Designer ) ;
} ;
