import {ResultItem} from "../scripts/resultItem";

/** 是否是开发模式 */
export const isDev = () => {
    // @ts-ignore
    return __DEV__ || process?.env?.NODE_ENV === `development`;
};
/** 是否是热更新模式 */
export const isHot = () => {
    return __HOT__ || process?.env?.WATCH === `WATCH`;
};

export const getContext = ( name: string ) => {
    if ( typeof window !== `undefined` ) {
        // @ts-ignore
        return window.__EX_CONTEXT__ ? window.__EX_CONTEXT__[name] : null;
    } else if ( typeof global !== `undefined` ) {
        // @ts-ignore
        // console.log (`global.__VUE_SSR_CONTEXT__?.exContext`, global.__VUE_SSR_CONTEXT__?.exContext ) ;
        // @ts-ignore
        return global?.__VUE_SSR_CONTEXT__?.exContext && global?.__VUE_SSR_CONTEXT__?.exContext[name];
    }
}