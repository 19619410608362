import {
    ServerBoardServer,
    ServerMainNode,
    ServerMetaSearch,
    ServerMobileServer,
    ServerNoticeServer,
    ServerWww,
    ServerIconNode,
    ServerPayServer,
    ServerBoardServerI,
    ServerDesigner,
    ServerTeam,
    ServerPService,
    ServerMobileServer1,
    ServerPinliteLogin,
    SeverCaseServer,
    ServerMessage,
    ServerAiPaintingApi, modelServer
} from "./servers" ;
import { PageAliPayUrl } from './pages' ;

export const AliPayUrl = PageAliPayUrl ;
export const teamAliPayUrl = ServerMobileServer1 + `/third-party/payteam.html`;
export const dakuAliPayUrl = ServerMobileServer1 + `/third-party/paydaku.html`;
/**
 * @description 获取是否翻转大作logo
 */
export const getHomeLogo = ServerMetaSearch + `/logoFlip/getFlipLogo.htm` ;
/**
 * @description 鉴别图片
 */
export const identifyPornAndTerror = ServerBoardServer + `/imageReview/identifyPornAndTerror` ;
/**
 * @description 用户上传审核
 */
export const identifyPornAndTerror2 = ServerBoardServer + `/imageReview/identifyPornAndTerror2` ;
/**
 * @description 用户领域列表
 */
export const getUserField = ServerMetaSearch + `/user/likeField` ;
/**
 * @description 获取行业分类
 */
export const getFieldList = ServerMetaSearch + `/userField/get.htm` ;
/**
 * @description 保存用户领域
 */
export const saveField = ServerMetaSearch + `/userField/save.htm` ;
/**
 * @description 更新用户领域
 */
export const updateUserField = ServerMetaSearch + `/userField/update.htm` ;
/**
 * @description 新新版 获取我的所有图片集(加上协作图片集)
 */
export const getAllMyBoardsMini = ServerBoardServer + `/userFollowBoard/listBoardNewMini` ;
/**
 * @description v2 获取我的图片集
 */
export const getAllMyBoardsMiniV2 = ServerBoardServer + `/userFollowBoard/listBoardNewMiniV2` ;
/**
 * @description 新版 获取我的所有图片集
 */
export const getAllMyBoards = ServerBoardServer + `/userFollowBoard/listBoardNew` ;
/**
 * @description 根据用户领域获取首页推荐图片集
 */
export const newGetRecommendBoards = ServerWww + `/board/r` ;
/**
 * @description 算法推荐图片集
 */
export const aiRecommendBoards = ServerWww + `/board/recommend/r3` ;
/**
 * @description 算法推荐图片集R4
 */
export const aiRecommendBoardsR4 = ServerWww + `/board/recommend/r4` ;
/**
 * @description 行业列表
 */
export const industryListAll = ServerMetaSearch + `/industry/all.htm` ;
/**
 * @description 旧版 获取创建图片集分类列表
 */
export const getBoardsClassify = ServerBoardServer + `/board/getIndustryBySort` ;
/**
 * @description 新版 创建图片集
 */
export const NewCreateBoards = ServerBoardServer + `/board/createBoardNew` ;
/**
 * @description 新版 编辑图片集
 */
export const newEditMyBoard = ServerBoardServer + `/board/remarkBoardNew` ;
/**
 * @description 分享统计
 */
export const shareRecord = ServerBoardServer + `/share/shareRecord` ;
/**
 * @description 取得图片的分享用图片地址
 */
export const shareImageUpload = ServerMetaSearch + `/share-image-upload.htm` ;
/**
 * @description 取得小程序分享二维码接口
 */
export const getMiniQR = ServerMobileServer + `/wechat/mp/getMiniQRUnlimitNew` ;
/**
 * @description 关注用户
 */
export const followUser = ServerBoardServer + `/userFollowUser/followUser` ;
/**
 * @description 取消关注用户
 */
export const cancelFollowUser = ServerBoardServer + `/userFollowUser/cancelFollowUser` ;
/**
 * @description 关注图片集
 */
export const followBoard = ServerBoardServer + `/userFollowBoard/followBoard` ;
/**
 * @description 取消关注图片集
 */
export const cancelFollowBoard = ServerBoardServer + `/userFollowBoard/cancelFollowBoard` ;
/**
 * @description 得到粉丝数
 */
export const getFansCount = ServerBoardServer + `/userFollowUser/getFansCount` ;
/**
 * @description 得到关注数
 */
export const getFollowUserCount = ServerBoardServer + `/userFollowUser/getFollowUserCount` ;
/**
 * @description 获得关注的用户列表
 */
export const getMyFollowUserList = ServerBoardServer + `/userFollowUser/getMyFollowUserList` ;
/**
 * @description 获得粉丝列表
 */
export const getFansList = ServerBoardServer + `/userFollowUser/getFansList` ;
/**
 * @description 获得用户关注的图片集,包括获得自己关注的图片集和别人关注的图片集
 */
export const getFollowBoards = ServerBoardServer + `/userFollowBoard/getFollowBoards` ;
/**
 * @description 禁词过滤
 */
export const sensitiveWordsFilter = ServerBoardServer + `/sensitiveWordsFilter` ;
/**
 * @description 获取行业分类列表
 */
export const getIndustryList = ServerMetaSearch + `/industry/all.htm` ;
/**
 * @description 获取伪静态链接
 */
export const getRewriteUrlInfo = ServerWww + `/qurl` ;
/**
 * @description 获取热门搜索词
 */
export const getHotWordList = ServerWww + `/getHotWords` ;
/**
 * @description 获取行业热门搜索词
 */
export const getIndustryHotWordList = ServerWww + `/industryHotWords/getWords` ;
/**
 * @description 获取热门颜色搜索词
 */
export const getHotColorList = ServerWww + `/hot_color/list` ;
/**
 * @description 推荐工作流
 */
export const getHotWorkflowList = ServerAiPaintingApi + `/ai/recommend/list` ;
/**
 * @description 获取站点列表
 */
export const getSiteList = ServerMainNode + `/siteName.htm` ;
/**
 * @description 老客户反馈建议
 */
export const oldUserFeedback = ServerMetaSearch + `/feedback` ;
/**
 * @description 是否登录过客户端
 */
export const getLoginClientState = ServerMetaSearch + `/pcclient/isLoginDesktop` ;
/**
 * @description 提交反馈建议
 */
export const feedBack = ServerMetaSearch + `/feedback` ;
/**
 * @description 获取未读消息
 */
export const getUnreadCount = ServerNoticeServer + `/message/unReadCount` ;
/**
 *@description 浏览器消息
 */
export const browserNotification = ServerNoticeServer + `/notice/recommend` ;
/**
 * @description 新手引导
 */
export const getGuide = ServerMetaSearch + `/user/getNoviceGuideStatus` ;
/**
 * @description 完成引导
 */
export const finishGuide = ServerMetaSearch + `/user/updateNoviceGuideStatus` ;
/**
 * @description 谷歌以图搜图
 */
export const photoToPhoto = `/bingsameimg/bingsameimg` ;
/**
 * @description 删除图片
 */
export const delImage = ServerBoardServer + `/img/batchDeleteImg` ;
/**
 * @description 编辑图片信息
 */
export const editImgInfo = ServerBoardServer + `/img/editImg` ;
/**
 * @description 存储标签
 */
export const saveRemark = ServerMetaSearch + `/look/save-remarks.htm` ;
/**
 * @description 根据id数组一次请求多个网站
 */
export const multiSites = ServerMainNode + `/all-sites-base.htm` ;
/**
 * @description 网站子分类
 */
export const siteSubType = ServerMainNode + `/single-sites-type-value.htm` ;
/**
 * @description 获取大作图片详情
 */
export const getImgDetail = ServerBoardServer + `/share/getImgInfo` ;
/**
 * @description 获取大作图片详情v2
 * @see http://doc.bigbigwork.com/docs/ai_hua/4398
 */
export const getImgDetail2 = ServerBoardServer + `/share/getImgInfo2` ;
/**
 * @description 删除图片
 */
export const deleteImg = ServerBoardServer + `/img/batchDeleteImg` ;
/**
 * @description 保存搜索词
 */
export const recordSearchWord = ServerMetaSearch + `/save-search-notes.htm` ;
/**
 * @description 获取board基本信息
 */
export const boardInfo = ServerBoardServer + `/boardNew/info` ;
/**
 * @description 获取section列表
 */
export const sectionList = ServerBoardServer + `/userBoardChild/list` ;
/**
 * @description 获取图集内图片列表
 */
export const boardImgList = ServerBoardServer + `/boardNew/imgList` ;
/**
 * @description 新获取图集内图片列表
 */
export const boardImgList2 = ServerBoardServer + `/boardNew/imgList2` ;
/**
 * @description 获取回收站内图片列表
 */
export const recycleBoardImgList = ServerBoardServer + `/recycleBin/list` ;
/**
 * @description 还原回收站内图片
 */
export const recycleBoardImgRecovery = ServerBoardServer + `/recycleBin/recovery` ;
/**
 * @description 删除回收站内图片
 */
export const recycleBoardImgClear = ServerBoardServer + `/recycleBin/clean` ;

/**
 * @description 切换图片集协作状态
 */
export const switchCooperation = ServerBoardServer + `/collaborateBoard/collaborateSwitch` ;

/**
 * @description 获取board联想词
 */
export const boardAssociateWord = ServerBoardServer + `/boardAssociateWord/` ;
/**
 * @description 获取board操作权限
 */
export const boardAdmainUrl = ServerBoardServer + `/boardNew/isPermission` ;
/**
 * @description 新版协作开关切换(可修改权限)
 */
export const switchCooperationV2 = ServerBoardServer + `/collaborateBoard/collaborateSwitchNew` ;
/**
 * @description 获取协作成员列表
 */
export const cooperationMemberList = ServerBoardServer + `/collaborateBoard/listCollaboratorNew` ;
/**
 * @description 获取团队图片集邀请成员弹窗中 成员列表
 */
export const inviteMemberList = ServerBoardServer + `/enterpriseMember/inviteEnterpriseMenberList` ;
/**
 * @description 获取团队图片集邀请成员
 */
export const inviteMember = ServerBoardServer + `/collaborateBoard/inviteJoin` ;
/**
 * @description 删除协作成员
 */
export const delCooperationMember = ServerBoardServer + `/collaborateBoard/deleteCollaborator` ;
/**
 * @description 移交权限
 */
export const tranformAdmin = ServerBoardServer + `/collaborateBoard/transferAdmin` ;
/**
 * @description 退出协作
 */
export const exitCooperation = ServerBoardServer + `/collaborateBoard/leave` ;
/**
 * @description 字图片集详情
 */
export const sectionDetail = ServerBoardServer + `/userBoardChild/info` ;
/**
 * @description 子图集图片列表
 */
export const sectionDetailList = ServerBoardServer + `/userBoardChild/imgList` ;
/**
 * @description 获取相似图片集
 */
export const relatedBoard = ServerBoardServer + `/board/relatedBoardBottom` ;
/**
 * @description 获取微信登录码
 */
export const loginQrcode = ServerMetaSearch + `/wechat/mp/qrcode` ;
/**
 * @description 获取pinlite微信公众号登录码
 */
export const pinliteloginQrcode = ServerPinliteLogin + `/wechat/mp/qrcode2` ;
/**
 * @description 检查微信码是否登录
 */
export const wechatIsLogin = ServerMetaSearch + `/wechat/mp/{ticket}` ;
/**
 * @description 企业版检查微信码是否登录
 */
export const wechatIsLoginEnterprise = ServerMetaSearch + `/wechat/mp/aiCompany/{ticket}` ;
/**
 * @description 获取所有图片集 收藏使用
 */
export const allBoards = ServerBoardServer + `/boardNew/userAllBoard` ;
/**
 * @description 获取所有图片集 收藏使用
 */
export const getImgDetailBoard = ServerBoardServer + `/boardNew/imgInBoards` ;
/**
 * @description 创建子图片集
 */
export const createSectionBoard = ServerBoardServer + `/userBoardChild/createChildBoard` ;
/**
 * @description 收藏
 */
export const favToBoard = ServerBoardServer + `/img/collectImg` ;
/**
 * @description 收藏到子图片集
 */
export const favToSection = ServerBoardServer + `/userBoardChild/collectToChildBoard` ;
/**
 * @description 收藏到子图片集(聚合收藏)
 */
export const favToSectionMeta = ServerBoardServer + `/userBoardChild/externalImgCollect` ;
/**
 * @description 编辑子图片集
 */
export const editSection = ServerBoardServer + `/userBoardChild/editChildBoard` ;
/**
 * @description 删除子图片集
 */
export const delSection = ServerBoardServer + `/userBoardChild/delChildBoard` ;
/**
 * @description 删除子图片集
 */
export const delChildBoardToRecycle = ServerBoardServer + `/userBoardChild/delChildBoardToRecycle` ;
/**
 * @description 上传图片到子图片集
 */
export const imgToSection = ServerBoardServer + `/userBoardChild/uploadImgToChildBoard` ;
/**
 * @description 付费价格列表
 */
export const priceList = ServerPayServer + `/pricelistnew/getUserPriceListNew.htm` ;/**
 * @description 付费价格列表
 */
export const priceListV1 = ServerPayServer + `/pricelistnew/getUserPriceList_v1` ;
/**
 * @default 微信支付h5订单
 */
export const wechatOrder = ServerPayServer + `/pricelistnew/weixinH5/createOrder` ;
/**
 * @default 微信jsSdk订单
 */
export const wechatSdkOrder = ServerPayServer + `/pricelistnew/createWechatPublicOrder` ;
/**
 * @description 支付宝h5订单
 */
export const alipayOrder = ServerPayServer + `/pay/alipay/creatOrder` ;
/**
 * @description 订单状态查询
 */
export const checkPay = ServerPayServer + `/pricelistnew/is-pay-success.htm` ;
/**
 * @description 图片集复制
 */
export const boardImgCopy = ServerBoardServer + `/boardNew/copy` ;
/**
 * @description 子图片集复制
 */
export const sectionBoardImgCopy = ServerBoardServer + `/userBoardChild/copy` ;
/**
 * @description 图片集移动
 */
export const boardImgMove = ServerBoardServer + `/boardNew/mobile` ;
/**
 * @description 子图片集移动
 */
export const sectionBoardImgMove = ServerBoardServer + `/userBoardChild/mobile` ;
/**
 * @description 获取大作加密下载url
 */
export const getEncryptUrl = ServerBoardServer + `/img/encryptImgUrl` ;
/**
 * @description 获取大兔加密下载url
 */
export const getRabbitEncryptUrl = ServerPService + `/rabbit/copyright/img/urlEncrypt` ;
export const getRabbitEncryptUrl2 = ServerPService + `/rabbit/copyright/img/urlEncrypt2` ;
/**
 * @description 新接口 获取大作加密下载url
 */
export const getNewEncryptUrl = ServerPService + `/img/urlEncrypt` ;
/**
 * @description 生成用户邀请码
 */
export const getInviteCode = ServerMetaSearch + `/inviteNew/inviteCode` ;
/**
 * @description 评论列表
 */
export const commentList = ServerBoardServer + `/collaborateImgComment/getComment` ;
/**
 * @description 评论
 */
export const postComment = ServerBoardServer + `/collaborateImgComment/comment` ;
/**
 * @description 红包弹框数据
 */
export const redEnvelope = ServerMetaSearch + `/msg/tankuang.htm` ;
/**
 * @description 新版获取用户信息
 */
export const getUserInfoV2 = ServerMetaSearch + `/getUserInfoNew` ;
export const desingerInfo = ServerDesigner + `/designer/getDesignerInfo` ;
export const publicPictures = ServerBoardServer + `/img/listPublicImg` ;
export const getCaseList = SeverCaseServer + `/case/collect/list` ;
export const myPins = ServerWww + `/img/searchOwnImg` ;
/**
 * @description 浏览历史-图片集
 */
export const historyBoards = ServerWww + `/history/board` ;
/**
 * @description 浏览历史-图片
 */
export const historyPins = ServerWww + `/history/img` ;
/**
 * @description 团队版 团队列表
 */
export const teamsList = ServerTeam + `/enterprise/getList` ;
/**
 * @description 团队版 团队详情
 */
export const teamsInfo = ServerTeam + `/enterprise/enterpriseInfo` ;
/**
 * @description 团队版 团队详情
 */
export const teamsInviteCode = ServerTeam + `/enterprise/getShareToJoinInLink` ;
/**
 * @description 团队版 团队成员列表
 */
export const teamsMemberList = ServerTeam + `/enterprise/member/memberList` ;
/**
 * @description 团队版 团队成员扩容价格
 */
export const teamsMemberPrice = ServerPayServer + `/enterprise/expansionPrice` ;
/**
 * @description 团队版 团队邀请详情
 */
export const teamInviteDetail = ServerTeam + `/enterprise/getShareInfo` ;
/**
 * @description 团队版 用户根据分享链接中的code加入团队
 */
export const teamInviteJoin = ServerTeam + `/enterprise/joinInEnterprise` ;
/**
 * @description 团队版 编辑团队名称
 */
export const editTeamName = ServerTeam + `/enterprise/editName` ;
/**
 * @description 团队版 修改团队成员角色（团队拥有者和管理员修改）
 */
export const editMemeberRole = ServerTeam + `/enterprise/member/editMemberRole` ;
/**
 * @description 团队版 移交团队
 */
export const removeTeam = ServerTeam + `/enterprise/transferEnterprise` ;
/**
 * @description 团队版 移除成员
 */
export const removeTeamMember = ServerTeam + `/enterprise/member/removeMember` ;
/**
 * @description 找设计接口
 */
export const getFindDesignUrl = ServerWww + `/design/project` ;
/**
 * @description 找项目接口
 */
export const getFindProjectUrl = ServerWww + `/design/cooperation` ;
/**
 * @description 找设计页面
 */
export const findDesignUrl = ServerWww + `/find/design.html` ;
/**
 * @description 找项目页面
 */
export const findProjectUrl = ServerWww + `/find/project.html` ;
/**
 * @description 用户登录-跨站
 */
export const CrossdoLogin = ServerPService + `/www-login.htm` ;
/**
 * @description 用户登录-忘记密码
 */
export const imgCodeSrc = ServerMetaSearch + `/getCode.htm` ;
/**
 * @description 重置密码
 */
export const resetPassword = ServerPService + `/reset-password.htm` ;
/**
 * @description 发送短信验证码
 */
export const sendCode = ServerPService + `/send-code.htm` ;
/**
 * @description 检查验证码正确性
 */
export const registerValidateCode = ServerPService + "/register-validate-code.htm"
/**
 * @description 检查token有效性
 */
export const checkTokenValid = ServerPService + "/check/tokenIsEffective"
/**
 * @description 绑定微信获取二维码
 */
export const bindWechatData = ServerPService + `/wechat/mp/#{phone}/qrcode` ;
/**
 * @description 绑定微信轮询
 */
export const checkBindWechat = ServerPService + `/wechat/mp/#{phone}/#{ticket}` ;
/**
 * @description 飞书消息推送
 */
export const sendFeishuMsg = ServerMessage + `/sendMsgFeishu/custom` ;
/* 模型预览文件 */
export const modelPreviewFile = `${modelServer}/model/getPreviewFile` ;
/* 根据图片获取模型 */
export const modelByImg = `${ServerAiPaintingApi}/imageTo3DTransform/transform` ;
