/* eslint-disable camelcase */
import { ResultItem } from "./resultItem" ;

export class SearchItem extends ResultItem {
    type: string
    public pin_industry: string;
    public isChoose:boolean;
    public isFreeDownload: boolean;
    public child_board_uuid : string;
    constructor ( options ) {
        super ( options ) ;
        this.type = `search` ;
        this.pin_industry = options.pin_industry ;
        this.isChoose = false ;
        this.child_board_uuid = options.child_board_uuid || '' ;
        /* 福利图片集免费无限下载 条件: 图片集属性 is_public === 2 */
        this.isFreeDownload = ( options?.owner?.is_public || -1 ) === 2 || false ;
    }
}
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
