var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style["browser-tips"], on: { click: _vm.downloadGoogle } },
    [_c("div", { class: _vm.$style.browserBackground })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }